import React, { useState, useEffect } from "react";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { Box, Checkbox, Switch } from "@mui/material";
import { useStateContext } from "../../context/ContextProvider";
import axios from "../../axoisConfig";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineDelete } from "react-icons/md";
// import DeleteConfirm from "./DeleteConfirmation";
import { toast } from "react-toastify";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiAlertFill } from "react-icons/ri";
import moment from "moment";
const token = localStorage?.getItem("auth-token");

const deliveryStatus = [
  {
    code: "INVALID_NOT_EFFECTIVE_ACTIVE",
    label: "Campaign is inactive",
  },
  {
    code: "INVALID_NOT_ACTIVE",
    label: "Not Devlivering",
  },
];

const reviewStatuses = {
  APPROVED: {
    icon: <IoIosCheckmarkCircle color="#2EAD78" size={16} />,
    label: "Approved",
  },
  DISAPPROVED: {
    icon: <RiAlertFill color="#DC2E4B" size={16} />,
    label: "Rejected",
  },
  PENDING_REVIEW: {
    icon: <RiAlertFill color="#F7F700" size={16} />,
    label: "Pending",
  },
};

// Columns definition

const CreativeTable = ({
  creatives,
  isSingleCreative,
  setIsSingleCreative,
  creativeDetails,
  setCreativeDetails,
}) => {
  // const [compaigns, setComapaigns] = useState([]);
  const {
    currentMode,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    DataGridStyles,
  } = useStateContext();
  const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false);

  //   async function deleteCampaign(id) {
  //     try {
  //       const res = await axios.post(
  //         `${BACKEND_URL}/snapchat/delete/campaign`,
  //         {
  //           access_token: snapchatCredentials?.access_token,
  //           campaign_id: id,
  //         },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: "Bearer " + token,
  //           },
  //         }
  //       );
  //       getAllCompaigns();
  //       toast.success("Campaign is successfully deleted", {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //       });
  //     } catch (error) {
  //       console.log(error, "error");
  //       toast.error("Campaign can't be deleted", {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //       });
  //     }
  //   }

  console.log(creatives, "creatives");

  const columns = [
    {
      field: "name",
      headerName: "Creative Name",
      headerAlign: "left",
      headerClassName: "pl-5",
      renderHeader: (params) => {
        return (
          <div className="pl-12 font-semibold">{params.colDef.headerName}</div>
        ); // Apply padding manually
      },
      renderCell: (params) => (
        <Name
          value={params?.value}
          onEdit={() => {
            setCreativeDetails(params?.row);
            setIsSingleCreative(true);
          }}
          link={params?.row?.thumbnail}
          mediaType={params?.row?.mediaType}
          onDelete={() => {
            // deleteCampaign(params?.row?.id,params?.value);
            // setOpenDeleteConfirm({ id: params?.row?.id, name: params?.value });
          }}
        />
      ),
      minWidth: 250,
    },
    {
      field: "type",
      headerName: "Attachment",
      headerAlign: "left",
      headerClassName: "pl-5",
      renderCell: (params) => (
        <div className="w-full text-left">
          {params?.value?.replace("_", " ")}
        </div>
      ),
      minWidth: 250,
    },
    {
      field: "review_status",
      headerName: "Status",
      headerAlign: "left",
      minWidth: 150,
      renderCell: (params) => (
        <div className="flex items-center gap-3 w-full text-left">
          <span>{reviewStatuses[params?.value]?.icon}</span>
          <span>{reviewStatuses[params?.value]?.label}</span>
        </div>
      ),
    },
    {
      field: "created_at",
      headerName: "Date Created",
      headerAlign: "center",
      minWidth: 200,
      renderCell: (params) => {
        return <div>{moment(params?.value).format("M/D/YYYY")}</div>;
      },
    },
  ];

  return (
    <>
      <div>
        <Box
          className={`closed-datatable ${currentMode}-mode-datatable`}
          sx={{
            ...DataGridStyles,
            position: "relative",
            marginBottom: "50px",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <DataGrid
            rows={creatives}
            columns={columns}
            pageSize={5}
            checkboxSelection
            disableSelectionOnClick
            autoHeight
            rowHeight={75} // Set the height here
            //   selectionModel={selectedRows?.campaigns}
            //   onSelectionModelChange={(rows) => {
            //     setSelectedRows((pre) => ({ ...pre, campaigns: rows }));
            //     console.log(rows, "rows");
            //   }}
          />
        </Box>
      </div>
      {/* <DeleteConfirm
        open={openDeleteConfirm}
        setOpen={setOpenDeleteConfirm}
        message={` Are you sure you want to delete campaign ${openDeleteConfirm?.name}? `}
        // onDelete={deleteCampaign}
        title="Confirm Delete Campaign"
      /> */}
    </>
  );
};

export default CreativeTable;

const Name = ({ value, onEdit, onDelete, link, mediaType }) => {
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const { currentMode } = useStateContext();

  return (
    <>
      <div
        className="pl-11 w-full text-left flex gap-3 items-center"
        onMouseEnter={() => setIsMouseEnter(true)}
        onMouseLeave={() => setIsMouseEnter(false)}
      >
        <div>
          {mediaType === "IMAGE" ? (
            <img src={link} alt="" className="w-[30px] object-cover" />
          ) : (
            <video src={link} className="w-[30px] object-cover"></video>
          )}
        </div>
        <div>
          <div className="flex items-center ">{value}</div>
          {isMouseEnter && (
            <div className="flex gap-3">
              <button
                // className="mt-2 text-gray-600 hover:text-gray-900"
                className={`mt-2 ${
                  currentMode === "dark"
                    ? "text-white hover:text-gray-600"
                    : "text-gray-600 hover:text-gray-900"
                }  flex gap-2 items-center`}
                onClick={onEdit}
              >
                Edit
              </button>
              <button
                // className="mt-2 text-gray-600 hover:text-gray-900 flex gap-2 items-center"
                className={`mt-2 ${
                  currentMode === "dark"
                    ? "text-white hover:text-gray-600"
                    : "text-gray-600 hover:text-gray-900"
                }  flex gap-2 items-center`}
                onClick={onDelete}
              >
                Delete <MdOutlineDelete size={16} />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

import React, { useEffect, useState } from "react";
import BookedDeals from "../../Components/Leads/BookedDeals";
import Loader from "../../Components/Loader";
import { useStateContext } from "../../context/ContextProvider";
import HeadingTitle from "../../Components/_elements/HeadingTitle";
import CancelledDeals from "../../Components/Leads/CancelledDeals";

const Cancelled = () => {
  const [loading, setloading] = useState(true);
  const {
    currentMode,
    setopenBackDrop,
    BACKEND_URL,
    pageState,
    t,
    themeBgImg,
  } = useStateContext();

  console.log("Cancelled State: ", pageState);

  useEffect(() => {
    setopenBackDrop(false);
    setloading(false);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="flex min-h-screen">
        {loading ? (
          <Loader />
        ) : (
          <div
            className={`w-full p-5 mt-2 ${
              !themeBgImg && (currentMode === "dark" ? "bg-dark" : "bg-light")
            }`}
          >
            <HeadingTitle
              title={`${t("feedback_cancelled")} ${t("deals")}`}
              counter={pageState?.total}
            />

            <CancelledDeals BACKEND_URL={BACKEND_URL} lead_type={"cancelled"} />
          </div>
        )}
      </div>
    </>
  );
};

export default Cancelled;

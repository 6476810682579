import React, { useState } from "react";
import { PiCopyLight } from "react-icons/pi";
import { toast } from "react-toastify";
import { selectStyles } from "../_elements/SelectStyles";
import Select from "react-select";
import { useStateContext } from "../../context/ContextProvider";
import axios from "../../axoisConfig";
import { useEffect } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
const SingleAdAccount = ({ adAccountId, setAdAccountId }) => {
  const {
    currentMode,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    themeBgImg,
  } = useStateContext();
  const [adAccountDetails, setAdAccountDetails] = useState({});

  async function fetchSingleAdAccount() {
    const token = localStorage?.getItem("auth-token");
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/adaccounts`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_account_id: adAccountId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      console.log(res?.data?.data?.adaccounts[0]?.adaccount, "it is something");
      // alert("this is something");
      setAdAccountDetails(res?.data?.data?.adaccounts[0]?.adaccount);
    } catch (error) {
      console.log(error, "this is an error");
      // alert("it is errro");
    }
  }
  useEffect(() => {
    fetchSingleAdAccount();
  }, [adAccountId]);
  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white border-[#F9F9FA]"
        : "bg-dark text-white border-[#F9F9FA]"
      : themeBgImg
      ? "blur-bg-light text-gray-800 border-[#F9F9FA]"
      : "bg-white text-gray-800 border-gray-800";
  };
  return (
    <>
      <div className={`px-[80px] py-10 ${getSummaryBgClass()}`}>
        <div
          onClick={() => setAdAccountId(null)}
          className="flex gap-2 items-center"
        >
          <div
            className={`p-2 ${getSummaryBgClass()} cursor-pointer rounded-full w-fit  border hover:border-[#F7D901] box-border`}
          >
            <MdKeyboardArrowLeft size={16} />
          </div>
          Back to Accounts List
        </div>

        <h2
          className={`${
            currentMode === "dark" ? "text-white" : "text-[rgb(68,69,70)]"
          } font-bold text-[24px] font-sans`}
        >
          Ad Account Details
        </h2>
        <p className=" flex items-center gap-2 text-[14px]">
          ID: 2d35d0a9-4c2b-485d-a18e-04673fa947a1
          <button
            onClick={() => {
              toast.success("Id is successfully copied!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }}
          >
            <PiCopyLight size={16} />
          </button>
        </p>

        <div className={`rounded-2xl  p-6 ${getSummaryBgClass()}`}>
          <div className="flex gap-3">
            <div className="flex-1 border border-gray-300 py-4 flex flex-col items-center gap-1 rounded-xl ">
              <span className="text-[11px] text-gray-500">Account Type</span>
              <p className="text-[14px]">{adAccountDetails?.type}</p>
            </div>
            <div className="flex-1 border border-gray-300 py-4 flex flex-col items-center gap-1 rounded-xl ">
              <span className="text-[11px] text-gray-500">Billing Type</span>
              <p className="text-[14px]">{adAccountDetails?.billing_type}</p>
            </div>
            <div className="flex-1 border border-gray-300 py-4 flex flex-col items-center gap-1 rounded-xl ">
              <span className="text-[11px] text-gray-500">Time zone</span>
              <p className="text-[14px]">{adAccountDetails?.timezone}</p>
            </div>
            <div className="flex-1 border border-gray-300 py-4 flex flex-col items-center gap-1 rounded-xl ">
              <span className="text-[11px] text-gray-500">Currency</span>
              <p className="text-[14px]">{adAccountDetails?.currency}</p>
            </div>
          </div>

          <div className="grid grid-cols-2 pt-7 gap-3">
            <div className="flex flex-col">
              <label htmlFor="">Ad Account Name</label>
              <input
                type="text"
                className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
                value={adAccountDetails?.name}
              />
            </div>
            {/* <div>
              <label htmlFor="">Billing Center</label>
              <Select
                id="Billing Center"
                options={[
                  {
                    label: adAccountDetails?.billing_center_id,
                    value: adAccountDetails?.billing_center_id,
                  },
                ]}
                value={{
                  label: adAccountDetails?.billing_center_id,
                  value: adAccountDetails?.billing_center_id,
                }}
                // onChange={(e) => {
                //   setFiltersData({
                //     ...filtersData,
                //     leadSource: {
                //       label: e?.label,
                //       value: e?.value?.toLowerCase(),
                //     },
                //   });
                // }}
                placeholder={t("Billing Type")}
                // className={`mb-5`}
                menuPortalTarget={document.body}
                styles={selectStyles(currentMode, primaryColor)}
              />
            </div> */}
            <div className="flex flex-col">
              <label htmlFor="">Spend Cap</label>
              <input
                type="text"
                className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border  hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA]`}
              />
            </div>
            {/* <div>
              <label htmlFor="">Advertising Organization</label>
              <Select
                id="Lead Source"
                // options={source_options(t)}
                // value={filtersData?.leadSource}
                // onChange={(e) => {
                //   setFiltersData({
                //     ...filtersData,
                //     leadSource: {
                //       label: e?.label,
                //       value: e?.value?.toLowerCase(),
                //     },
                //   });
                // }}
                placeholder={t("lead_source")}
                // className={`mb-5`}
                menuPortalTarget={document.body}
                styles={selectStyles(currentMode, primaryColor)}
              />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleAdAccount;

import React, { useEffect, useRef, useState } from "react";
import "../../styles/chatBot.css";
import axios from "../../axoisConfig";
import { useStateContext } from "../../context/ContextProvider";
import { toast } from "react-toastify";
import { AiOutlineSend } from "react-icons/ai";

const ChatbotModal = ({ isChatboxActive, setIsChatboxActive }) => {
  const { t, BACKEND_URL, User, PYTHON_SOCKET_URL } = useStateContext();
  const [message, setMessage] = useState("");
  const [displayMessages, setDispalyMessages] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [typingLoading, setTypingLoading] = useState(false);
  const [isMentioning, setIsMentioning] = useState(false); // To track if '@' mention is active
  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const [singleUser, setSingleUser] = useState(null);
  const [userLoading, setUserLoading] = useState(false);
  const ws = useRef(null);

  console.log("display mesg : ", displayMessages);

  console.log("python socket: ", PYTHON_SOCKET_URL);

  const handleChange = (e) => {
    const inputText = e.target.value;
    setMessage(inputText);

    // detect @
    const mentionMatch = inputText.match(/@(\w+)$/);

    console.log("mention @: ", mentionMatch);

    if (mentionMatch) {
      setSearchQuery(mentionMatch[1]);
      setIsMentioning(true);
    } else {
      setSearchQuery("");
      setIsMentioning(false);
    }
  };

  // Handle selecting a user from the suggestions
  const handleUserSelect = (user) => {
    // Replace the @query with the selected username in the message
    const newMessage = message.replace(/@\w+$/, `@${user?.userName} `);
    setMessage(newMessage);

    setIsMentioning(false);
    setSingleUser(user);
    setSuggestedUsers([]);
  };

  const fetchUsers = async (keyword) => {
    setUserLoading(true);
    try {
      let url = "";
      if (keyword) {
        url = `${BACKEND_URL}/users?userName=${keyword}`;
      }
      const token = localStorage.getItem("auth-token");
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      console.log("searched users: ", response);

      let rowsDataArray = "";
      if (response?.data?.users?.current_page > 1) {
        const theme_values = Object.values(response?.data?.users?.data);
        rowsDataArray = theme_values;
      } else {
        rowsDataArray = response?.data?.users?.data;
      }

      let rowsdata = rowsDataArray?.map((row, index) => ({
        id: row?.id,
        userName: row?.userName || "No Name",
        position: row?.position || "No Position",
        userContact: row?.userContact || "No Contact",
        userEmail: row?.userEmail || "No Email",
        status: row?.status,
        is_trainer: row?.is_trainer,
        loginId: row?.loginId,
        role: row?.role,
        salary: row?.salary,
        currency: row?.currency,
        profile_picture: row?.profile_picture,
      }));

      console.log("Rows Data: ", rowsdata);

      setSuggestedUsers(rowsdata);
      setUserLoading(false);
    } catch (error) {
      setUserLoading(false);
      console.log(error);
      toast.error("Unable to fetch users.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // Format the message before sending: replace @user with @user||user?.id
  const formatMessageWithMentions = (messageText) => {
    let appendedUserIds = ""; // Will store all the userId appended at the end

    // Match all @mentions in the message and find the corresponding userId
    messageText.replace(/@([a-zA-Z0-9_]+)/g, (match, userName) => {
      console.log("Username to be found: ", userName);

      const mentionedUser = singleUser; // Assuming you already have the mentioned user object

      console.log("Mentioned user: ", mentionedUser);

      // If mentionedUser is found, append `||userId` to the string
      if (mentionedUser) {
        appendedUserIds += `||${mentionedUser.id}`; // Collect all user IDs
      }

      return match; // Don't replace the username in the original message
    });

    // Return the original message with all userIds appended at the end
    if (!appendedUserIds || appendedUserIds == "") return messageText;
    return `${messageText} ${appendedUserIds}`;
  };

  // const formatMessageWithMentions = (messageText) => {
  //   return messageText.replace(/@(\w+)/g, (match, userName) => {
  //     console.log("username to be find: ", userName);
  //     console.log("selected user id : ", singleUser);
  //     const mentionedUser = singleUser;
  //     console.log("mentioned user: ", mentionedUser);
  //     if (mentionedUser) {
  //       const embedId = `@${mentionedUser.userName}||${mentionedUser.id}`;
  //       return embedId;
  //     }
  //     return match; // If no user is found, leave the mention as is
  //   });
  // };

  // Send the message via WebSocket
  const sendMessage = () => {
    if (!message || message == "") return;
    setDispalyMessages([
      ...displayMessages,
      {
        type: "user",
        message: message,
      },
    ]);

    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      const formattedMessage = formatMessageWithMentions(message);
      console.log("formated message : ", formattedMessage);

      // ws.current.send(JSON.stringify({ message: formattedMessage }));
      ws.current.send(formattedMessage);
      console.log("formated message : ", formattedMessage);
      setMessage(""); // Clear input after sending
    } else {
      console.error("WebSocket is not connected");
      toast.error("Unable to connect.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // Initialize WebSocket when component mounts
  useEffect(() => {
    const socketUrlWithUserId = `${PYTHON_SOCKET_URL}/${User?.id}`;
    ws.current = new WebSocket(socketUrlWithUserId);

    console.log("socketurl with id: ", socketUrlWithUserId);

    // Handle WebSocket connection and events
    ws.current.onopen = () => {
      console.log("WebSocket connection opened");
    };

    ws.current.onclose = () => {
      console.log("WebSocket connection closed");
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    // Log the response from the server
    ws.current.onmessage = (event) => {
      const data = event.data;
      console.log("WebSocket Response: ", data);

      // Add WebSocket message to the displayMessages state as an "operator" message
      setDispalyMessages((prevMessages) => [
        ...prevMessages,
        {
          type: "operator", // Message from the operator/server
          message: data, // Display the message content
        },
      ]);
    };

    // Clean up WebSocket when component unmounts
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [PYTHON_SOCKET_URL]);

  useEffect(() => {
    if (searchQuery.length >= 2) {
      fetchUsers(searchQuery);
    } else {
      setSuggestedUsers([]); // Clear suggestions if query is too short
    }
  }, [searchQuery]);

  return (
    <>
      <div className="chatbox__header bg-primary flex items-center justify-center">
        <div className="chatbox__image--header w-[50px] h-[50px] ">
          <img
            src="assets/short-logo.jpg"
            alt="Chat logo"
            className=" rounded-full w-full h-full  "
          />
        </div>
        <div className="chatbox__content--header">
          <h4 className="chatbox__heading--header">Hikal Chat support</h4>
        </div>
      </div>
      <div className="chatbox__messages h-[150px]">
        {displayMessages?.length > 0 ? (
          displayMessages
            ?.slice()
            .reverse()
            ?.map((msg) => (
              <div>
                {msg?.type == "user" ? (
                  <div className="messages__item messages__item--operator bg-orange-600">
                    {msg?.message}
                  </div>
                ) : (
                  <div className="messages__item messages__item--visitor text-black  bg-[#E0E0E0]">
                    {msg?.message}
                  </div>
                )}

                {typingLoading ? (
                  <div className="messages__item messages__item--typing">
                    <span className="messages__dot"></span>
                    <span className="messages__dot"></span>
                    <span className="messages__dot"></span>
                  </div>
                ) : null}
              </div>
            ))
        ) : (
          <div className="messages__item messages__item--visitor text-white  bg-primary">
            Hi, Welcome to Smart do Deals ChatBot.
          </div>
        )}
      </div>
      <div className="chatbox__footer fixed bottom-0">
        <input
          type="text"
          placeholder={t("bot_chat")}
          value={message}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendMessage();
            }
          }}
          className="w-full"
        />
        <p
          className="chatbox__send--footer ml-2 text-primary cursor-pointer"
          onClick={sendMessage}
        >
          <AiOutlineSend size={20} />
          {/* {t("send")} */}
        </p>

        {/* Display the suggestions dropdown when mentioning */}
        {isMentioning && suggestedUsers.length > 0 && (
          <ul
            className={`suggestions-list absolute bg-[#E0E0E0]  w-40 ${
              suggestedUsers.length > 3 && "h-[200px]"
            } overflow-y-auto z-[1000px] py-3 px-2 bottom-16`}
          >
            {suggestedUsers?.map((user) => (
              <>
                <li
                  key={user?.id}
                  onClick={() => handleUserSelect(user)}
                  className="suggestion-item cursor-pointer mb-2"
                >
                  {user?.userName}
                </li>
                <hr />
              </>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default ChatbotModal;

import React, { useEffect, useState } from "react";
import Loader from "../../Components/Loader";
import { useStateContext } from "../../context/ContextProvider";
import HeadingTitle from "../../Components/_elements/HeadingTitle";
import AdsManager from "../../Components/snapchatMarketing/AdsManager";
import Header from "../../Components/snapchatMarketing/Header";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../../axoisConfig";
import { PiCopyLight } from "react-icons/pi";
import { toast } from "react-toastify";
import SingleAdAccount from "../../Components/snapchatMarketing/singleAdAccount";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoSearch } from "react-icons/io5";
import axiosInstance from "../../axoisConfig";
import SingleMember from "../../Components/snapchatMarketing/singleMember";
import { FaPlus } from "react-icons/fa";
import InviteMember from "../../Components/snapchatMarketing/InviteMember";
const SnapchatMembers = () => {
  const [loading, setloading] = useState(false);
  const [members, setMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [member, setMember] = useState(null);
  const [inviteMember, setInviteMember] = useState(false);
  const {
    currentMode,
    setopenBackDrop,
    BACKEND_URL,
    pageState,
    t,
    themeBgImg,
    snapchatCredentials,
    DataGridStyles,
    primaryColor,
  } = useStateContext();

  async function fetchRolesInOrganization() {
    let token = localStorage?.getItem("auth-token");
    console.log(snapchatCredentials?.organization?.id, "org id");
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/get-all-roles-in-organization`,
        {
          access_token: snapchatCredentials?.access_token,
          organization_id: snapchatCredentials?.organizations[0]?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      return res?.data?.data?.roles.map((obj) => obj?.role);

      // console.log(res?.data, "response of snap members");
      // const members = res?.data?.data?.members.map((member) => member?.member);
      // setMembers(members);
    } catch (error) {
      console.log("an error occured in members api of snapchat", error);
    }
  }

  async function fetchSnapchatMembers() {
    let token = localStorage?.getItem("auth-token");
    console.log(snapchatCredentials?.organization?.id, "org id");
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/all-organization-members`,
        {
          access_token: snapchatCredentials?.access_token,
          organization_id: snapchatCredentials?.organizations[0]?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      // console.log(res?.data, "response of snap members");
      const roles = await fetchRolesInOrganization();

      setMembers(
        res?.data?.data?.members.map((member) => ({
          ...member?.member,
          roles: roles?.filter((role) => role?.member_id == member?.member?.id),
        }))
      );
    } catch (error) {
      console.log("an error occured in members api of snapchat", error);
    }
  }

  useEffect(() => {
    fetchSnapchatMembers();
  }, [snapchatCredentials]);
  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark-neu text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  return (
    <>
      <div className="flex min-h-screen ">
        {loading ? (
          <Loader />
        ) : (
          <div
            className={`w-full mt-2 ${
              !themeBgImg &&
              (currentMode === "dark" ? "bg-dark" : "bg-[#F9F9FA]")
            }`}
          >
            {/* <Header /> */}
            {!member ? (
              <div className="px-[80px] py-10">
                <div className="flex justify-between items-center">
                  <h2 className="text-[rgb(68,69,70)] font-bold text-[24px] font-sans mb-3">
                    Members
                  </h2>
                  <button
                    onClick={() => setInviteMember(true)}
                    className="flex items-center gap-2 p-2 px-5 rounded-[16px] bg-black text-white"
                  >
                    <FaPlus />
                    <span className="text-[14px] font-medium">
                      Invite Members
                    </span>
                  </button>
                </div>

                <div>
                  {snapchatCredentials?.organizations?.length > 0 && (
                    <div className={`rounded-[15px] ${getSummaryBgClass()}`}>
                      <div className="flex items-center gap-4 border-b py-5 px-7">
                        <IoSearch size={16} />
                        <input
                          type="text"
                          className={`focus:outline-none border-none flex-1 ${
                            currentMode === "dark" ? "bg-dark" : "bg-white"
                          }`}
                          onChange={(e) => setSearchTerm(e?.target?.value)}
                          value={searchTerm}
                        />
                      </div>
                      <div>
                        {members?.map((member) => {
                          const isSuit =
                            member?.display_name
                              ?.toLowerCase()
                              ?.includes(searchTerm?.toLocaleLowerCase()) ||
                            member?.email
                              ?.toLowerCase()
                              ?.includes(searchTerm?.toLocaleLowerCase()) ||
                            member?.member_status
                              ?.toLowerCase()
                              ?.includes(searchTerm?.toLocaleLowerCase());
                          return !isSuit ? null : (
                            <div
                              onClick={() => setMember(member)}
                              className={`flex items-center justify-between border-b py-5 group ${
                                currentMode === "dark"
                                  ? ""
                                  : "hover:bg-[#F9F9FA]"
                              } select-none cursor-pointer`}
                            >
                              <div className="flex flex-col pl-7">
                                <span>{member?.display_name}</span>
                                <span>
                                  {member?.email} |{" "}
                                  {member?.roles?.length == 0
                                    ? member?.member_status
                                    : member?.roles
                                        .map((role) =>
                                          role?.type
                                            ?.replace("_", " ")
                                            ?.replace(/^\w/, (c) =>
                                              c.toUpperCase()
                                            )
                                        )
                                        .filter(Boolean) // in case any roles are null or undefined
                                        .join(" , ")}
                                </span>
                              </div>
                              <div className="pr-7 flex gap-4 items-center">
                                {member?.member_status == "INVITED" && (
                                  <span className="bg-[#EF5001] py-1 px-4 rounded-[14px] text-white ">
                                    Invite Sent
                                  </span>
                                )}
                                <button
                                  className={`p-3 ${
                                    currentMode === "dark"
                                      ? "bg-black"
                                      : "bg-white"
                                  } rounded-full border-[2px]  group-hover:border-[#F7D901]`}
                                >
                                  <MdKeyboardArrowRight size={16} />
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <SingleMember
                member={member}
                setMember={setMember}
                fetchSnapchatMembers={fetchSnapchatMembers}
              />
            )}
          </div>
        )}
      </div>
      <InviteMember
        inviteMemberModal={inviteMember}
        setInviteMemberModal={setInviteMember}
        fetchSnapchatMembers={fetchSnapchatMembers}
      />
    </>
  );
};

export default SnapchatMembers;

import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useStateContext } from "../../../context/ContextProvider";

const LocationCheckBox = ({
  location,
  selectedLocations,
  setSelectedLocations,
  allChecked,
  listingData,
  setListingData,
}) => {
  const [checked, setChecked] = useState(false);
  const { currentMode } = useStateContext();

  useEffect(() => {
    if (listingData?.nearby != "" && listingData?.nearby != null) {
      let splitIds = [];

      // Check if nearby is a string and split it, otherwise handle as an array
      if (typeof listingData?.nearby === "string") {
        splitIds = listingData?.nearby?.split(",");
      } else if (Array.isArray(listingData?.nearby)) {
        splitIds = listingData?.nearby;
      }
      console.log("split id: ", splitIds);
      splitIds?.map((item) => {
        console.log("item: ", item);

        let id = listingData?.nearby === "string" ? item : item?.id;

        console.log("id:::: ", id);
        if (Number(id) == Number(location?.id)) {
          setChecked(true);
          setSelectedLocations([...selectedLocations, id]);
        }
      });
    }
  }, []);
  //   useEffect(() => {
  //     if (allChecked) {
  //       console.log("checked all: ", allChecked);
  //       setSelectedLocations((prevSelected) => [...prevSelected, location.id]);
  //     } else {
  //       setSelectedLocations([]);
  //     }
  //     setChecked(allChecked);
  //   }, [allChecked]);

  const handleClick = (e) => {
    const locationId = e.target.value;
    const checked = e.target.checked;
    console.log("checked:   ", checked);
    setChecked(checked);

    // setSelectedLocations((prevLocations) => {
    //   if (!checked) {
    //     // If checked, add the permissionId to the array

    //     return prevLocations.filter((id) => Number(id) !== Number(locationId));
    //   } else {
    //     // If unchecked, remove the locationId from the array
    //     return [...prevLocations, locationId];
    //   }
    // });

    setSelectedLocations((prevLocations) => {
      let updatedLocations;

      if (!checked) {
        // If unchecked, remove the locationId from the array
        updatedLocations = prevLocations?.filter(
          (id) => Number(id) !== Number(locationId)
        );
      } else {
        // If checked, add the locationId to the array
        updatedLocations = [...prevLocations, locationId];
      }

      // Update the comma-separated listing
      setListingData({
        ...listingData,
        nearby: updatedLocations.join(","),
      });

      return updatedLocations;
    });
  };

  console.log("updated location: ", selectedLocations);

  return (
    <div className="mb-3">
      <FormControlLabel
        control={
          <Checkbox
            value={location?.id}
            onClick={handleClick}
            checked={checked}
            name="permissionCheckbox"
            fullWidth
            inputProps={{ "aria-label": "controlled" }}
            style={{
              color: currentMode === "dark" ? "#fff" : "#000",
            }}
          />
        }
        label={location?.name}
      />
    </div>
  );
};

export default LocationCheckBox;

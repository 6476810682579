import React, { useState } from "react";
import { FaSnapchat } from "react-icons/fa6";
import { IoIosArrowDown, IoMdNotificationsOutline } from "react-icons/io";
import HeaderMenu from "./HeaderMenu";
import AccountMenu from "./HeaderAccountMenu";
import { useStateContext } from "../../context/ContextProvider";
import { ImStatsBars } from "react-icons/im";

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    currentMode,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    themeBgImg,
  } = useStateContext();
  const [currentModule, setCurrentModule] = useState({
    label: "Manage Ads",
    icon: <ImStatsBars size={16} />,
    identifier: "manageAds",
  });

  const [anchorElAccount, setAnchorElAccount] = React.useState(null);
  const isAdsAccounts =
    window.location.pathname.includes("manageAds") ||
    window.location.pathname.includes("createAd");
  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };
  return (
    <>
      <header
        className={`h-[55px] px-6 w-full flex justify-between items-center !rounded-none  border-t-[#F7D901] border-t-[2px] relative shadow-md ${getSummaryBgClass()}`}
      >
        <button
          className="flex items-center justify-center gap-2 font-semibold"
          onClick={(e) => setAnchorEl(e?.currentTarget)}
        >
          {currentModule?.label} <IoIosArrowDown />
        </button>
        <div className="absolute left-1/2 -translate-x-1/2">
          <FaSnapchat size={30} />
        </div>
        <div className="flex items-center gap-8">
          <div
            className="flex items-start gap-3 "
            onClick={(event) => setAnchorElAccount(event.currentTarget)}
          >
            <div>
              <button className="w-full text-right font-medium">
                Hikal Agency
              </button>
              <p className="text-gray-400">
                Hikal Real Estate LLC Organization admin
              </p>
            </div>
            <div className="mt-2">
              <IoIosArrowDown />
            </div>
          </div>
          <div
            className={`p-2 rounded-full ${
              currentMode === "dark" ? "bg-black" : "bg-gray-100"
            } `}
          >
            <IoMdNotificationsOutline size={18} />
          </div>
          <div>
            <img
              src="/person.png"
              className="w-[20px] h-[20px] rounded-full"
              alt="avator "
            />
          </div>
        </div>
      </header>
      <HeaderMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        currentModule={currentModule}
        setCurrentModule={setCurrentModule}
      />
      <AccountMenu
        anchorEl={anchorElAccount}
        setAnchorEl={setAnchorElAccount}
        accounts={
          isAdsAccounts
            ? snapchatCredentials?.organizations &&
              snapchatCredentials?.organizations[0]?.ad_accounts
            : snapchatCredentials?.organizations
        }
        name={isAdsAccounts ? "Ad Accounts" : "Organizations"}
        isAdsAccounts={isAdsAccounts}
      />
    </>
  );
};

export default Header;

import React, { useState, useEffect } from "react";
import { button, Button } from "@material-tailwind/react";
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
  MenuItem,
  // Select,
} from "@mui/material";
import Select from "react-select";

import { IoMdClose } from "react-icons/io";
import { GoDuplicate } from "react-icons/go";
import GeneralSvg, { AwarenessSvg } from "./generalSvg.js";
import { useStateContext } from "../../context/ContextProvider";
import { MdOutlineCampaign } from "react-icons/md";
import { BsCart4 } from "react-icons/bs";
import { PiCursorFill } from "react-icons/pi";
import { IoFilterOutline } from "react-icons/io5";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { selectStyles } from "../_elements/SelectStyles.jsx";
import SalesSvg from "./salesSvg.js";
import axios from "../../axoisConfig.js";
const style = {
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};
const token = localStorage?.getItem("auth-token");

const CreateAdSetModal = ({
  setCreateAdSetModal,
  createAdSetModal,
  setIsSingleAdSet,
  setAdSetDetails,
}) => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    t,
    primaryColor,
    snapchatCredentials,
  } = useStateContext();
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [loading, setLoading] = useState(true);

  async function getAllCompaigns() {
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/all/campaigns`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      setCampaigns(res?.data?.data?.campaigns?.map((item) => item?.campaign));
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (createAdSetModal) getAllCompaigns();
  }, [createAdSetModal]);

  return (
    <Modal
      //   keepMounted
      open={createAdSetModal}
      onClose={() => setCreateAdSetModal(false)}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        zIndex: 1400,
      }}
    >
      <div
        style={style}
        className={`w-[calc(100%-20px)] md:w-[30%]  ${
          // currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
          currentMode === "dark"
            ? "bg-dark-neu text-white"
            : "bg-white text-black"
        } absolute top-1/2 left-1/2 p-5 rounded-md flex flex-col items-start`}
      >
        <h3 className="text-[20px] font-semibold mb-3">Select Campaign</h3>
        <div className="w-full ">
          <Select
            isLoading={loading}
            id="adAccounts"
            options={
              campaigns
                ? campaigns?.map((campaign) => ({
                    label: campaign?.name,
                    value: campaign?.id,
                  }))
                : []
            }
            value={selectedCampaign}
            onChange={(e) => {
              setSelectedCampaign(e);
            }}
            placeholder={"Campaign"}
            // className={`mb-5`}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          />
        </div>
        <div className="flex items-center justify-end gap-3 w-full">
          <button
            onClick={() => setCreateAdSetModal(false)}
            // className="p-3 px-5 hover:border-[#F7D901] rounded-[20px] border font-semibold bg-white"
            className={`p-3 px-5 hover:border-[#F7D901] rounded-[20px] border font-semibold ${
              currentMode == "dark" ? "bg-black text-white" : "bg-white"
            } `}
          >
            Close
          </button>
          <button
            onClick={() => {
              setCreateAdSetModal(false);
              setIsSingleAdSet(selectedCampaign?.value);
              setAdSetDetails(null);
            }}
            // className="px-6 rounded-2xl bg-black text-white p-3 border hover:border-[#F7D901]"
            className="p-3 px-7 rounded-[20px] bg-black text-white font-semibold  border hover:border-[#F7D901]"
            disabled={!selectedCampaign}
          >
            Next
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateAdSetModal;

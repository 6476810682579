import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Backdrop,
  CircularProgress,
  Modal,
  TextField,
  Button,
  Tooltip,
  Drawer,
  MenuItem,
  Box,
  Typography,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Checkbox,
  FormGroup,
} from "@mui/material";
import Select from "react-select";
import { useStateContext } from "../../../context/ContextProvider";
import usePermission from "../../../utils/usePermission";
import axios from "../../../axoisConfig";

import { MdClose } from "react-icons/md";

import {
  enquiry_options,
  project_status_options,
} from "../../../Components/_elements/SelectOptions";
import HeadingTitle from "../../_elements/HeadingTitle";
import ListingLocation from "../../Leads/listings/ListingLocation";

const style = {
  transform: "translate(0%, 0%)",
  boxShadow: 24,
};

const AddNearByLocaModal = ({
  LeadData,
  nearByModal,
  handleCloseNearByModal,
  fetchLocations,
}) => {
  console.log("set listing modal open: ", nearByModal);
  const {
    currentMode,
    darkModeColors,
    User,
    BACKEND_URL,
    t,
    isLangRTL,
    i18n,
    primaryColor,
    fontFam,
  } = useStateContext();

  const [isClosing, setIsClosing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nearByData, setNearByData] = useState({
    name: "",
    //name_ar:برج خليفة
    area: "",
    city: "",
    country: "",
    latlong: "",
    notes: "",
  });

  console.log("near by state: ", nearByData);

  const [listingLocation, setListingLocation] = useState({
    lat: 0,
    lng: 0,
    addressText: "",
    city: "",
    country: "",
  });

  console.log("listing location state: ", listingLocation);

  const handleChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setNearByData({
      ...nearByData,
      [id]: value,
    });
  };

  const token = localStorage.getItem("auth-token");

  const AddLocations = () => {
    setLoading(true);

    axios
      .post(`${BACKEND_URL}/nearby`, nearByData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        console.log("near by location added : ", result);
        toast.success(`Location Added Successfully`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        handleCloseNearByModal();
        fetchLocations();

        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        console.log(err);
        const errors = err.response?.data?.errors;

        if (errors) {
          const errorMessages = Object.values(errors).flat().join(" ");
          toast.error(`Errors: ${errorMessages}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Unable to add location", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  const closingTimeoutId = useRef(null);
  useEffect(() => {
    return () => {
      clearTimeout(closingTimeoutId.current);
    };
  }, []);

  useEffect(() => {
    setNearByData({
      ...nearByData,
      latlong: `${listingLocation?.lat},${listingLocation?.lng}`,
      city: listingLocation?.city,
      country: listingLocation?.country,
    });
  }, [listingLocation]);

  const handleClose = () => {
    setIsClosing(true);
    closingTimeoutId.current = setTimeout(() => {
      setIsClosing(false);
      handleCloseNearByModal();
    }, 1000);
  };

  return (
    <>
      <Modal
        keepMounted
        open={nearByModal}
        onClose={() => handleCloseNearByModal()}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          className={`${
            isLangRTL(i18n.language) ? "modal-open-left" : "modal-open-right"
          } ${
            isClosing
              ? isLangRTL(i18n.language)
                ? "modal-close-left"
                : "modal-close-right"
              : ""
          }
        w-[100vw] h-[100vh] flex items-start justify-end`}
        >
          <button
            // onClick={handleLeadModelClose}
            onClick={handleClose}
            className={`${
              isLangRTL(i18n.language) ? "rounded-r-full" : "rounded-l-full"
            }
            bg-primary w-fit h-fit p-3 my-4 z-10`}
          >
            <MdClose
              size={18}
              color={"white"}
              className="hover:border hover:border-white hover:rounded-full"
            />
          </button>
          <div
            style={style}
            className={` ${
              currentMode === "dark"
                ? "bg-dark text-white"
                : "bg-light text-black"
            } ${
              isLangRTL(i18n.language)
                ? currentMode === "dark" && "border-r-2 border-primary"
                : currentMode === "dark" && "border-l-2 border-primary"
            }
             p-5 h-[100vh] w-[85vw] overflow-y-scroll
            `}
          >
            <HeadingTitle title={t("new_near_by_loc_heading")} />

            <div className={`w-full p-4`}>
              <div className="grid sm:grid-cols-1   md:grid-cols-2 lg:grid-cols-2 gap-x-10 mb-5">
                <Box
                  sx={{
                    ...darkModeColors,
                    "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                      {
                        right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                        transformOrigin: isLangRTL(i18n.language)
                          ? "right"
                          : "left",
                      },
                    "& legend": {
                      textAlign: isLangRTL(i18n.language) ? "right" : "left",
                    },
                  }}
                >
                  <TextField
                    id="name"
                    type={"text"}
                    label={t("lable_location_name")}
                    className="w-full"
                    sx={{
                      marginBottom: "20px !important",
                    }}
                    variant="outlined"
                    size="small"
                    value={nearByData?.name}
                    name="projectLocation"
                    onChange={handleChange}
                    required
                  />

                  <TextField
                    id="area"
                    type={"text"}
                    label={t("label_area")}
                    className="w-full"
                    sx={{
                      marginBottom: "20px !important",
                    }}
                    variant="outlined"
                    size="small"
                    value={nearByData?.area}
                    name="projectLocation"
                    onChange={handleChange}
                    required
                  />

                  <TextField
                    id="city"
                    type={"text"}
                    label={t("label_city")}
                    className="w-full"
                    sx={{
                      marginBottom: "20px !important",
                    }}
                    variant="outlined"
                    size="small"
                    value={nearByData?.city}
                    name="projectLocation"
                    onChange={handleChange}
                    required
                  />

                  <TextField
                    id="country"
                    type={"text"}
                    label={t("label_country")}
                    className="w-full"
                    sx={{
                      marginBottom: "20px !important",
                    }}
                    variant="outlined"
                    size="small"
                    value={nearByData?.country}
                    name="projectLocation"
                    onChange={handleChange}
                    required
                  />

                  <TextField
                    id="notes"
                    type={"text"}
                    label={t("notes")}
                    className="w-full"
                    sx={{
                      marginBottom: "20px !important",
                    }}
                    variant="outlined"
                    size="small"
                    value={nearByData?.notes}
                    name="projectLocation"
                    onChange={handleChange}
                  />
                </Box>
                <Box
                  sx={{
                    ...darkModeColors,
                    "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                      {
                        right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                        transformOrigin: isLangRTL(i18n.language)
                          ? "right"
                          : "left",
                      },
                    "& legend": {
                      textAlign: isLangRTL(i18n.language) ? "right" : "left",
                    },
                  }}
                >
                  <ListingLocation
                    listingLocation={listingLocation}
                    currLocByDefault={true}
                    setListingLocation={setListingLocation}
                    // city={city}
                    // setCity={setCity}
                    // country={country}
                    // setCountry={setCountry}
                    required
                  />
                </Box>
              </div>
              <Button
                className={`w-full text-center text-white rounded-md py-3 font-semibold disabled:opacity-50 disabled:cursor-not-allowed hover:shadow-none mt-5`}
                ripple={true}
                style={{
                  fontFamily: fontFam,
                  background: `${primaryColor}`,
                }}
                size="lg"
                type="submit"
                disabled={loading ? true : false}
                onClick={AddLocations}
              >
                {loading ? (
                  <CircularProgress
                    size={20}
                    sx={{ color: "white" }}
                    className="text-white"
                  />
                ) : (
                  <span className="text-white">{t("submit")}</span>
                )}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddNearByLocaModal;

import React, { useState, useEffect } from "react";
import { useStateContext } from "../../context/ContextProvider";
import axios from "../../axoisConfig";
import { toast } from "react-toastify";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import classNames from "classnames";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { MdOutlineDelete } from "react-icons/md";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  MenuItem,
  InputAdornment,
  TextField,
  Button,
  FormControl,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
const SnapChat = () => {
  const {
    t,
    primaryColor,
    darkModeColors,
    isLangRTL,
    i18n,
    currentMode,
    themeBgImg,
    BACKEND_URL,
    DataGridStyles,
    snapchatCredentials,
    setSnapchatCredentials,
    snapcahtRefreshToken,
  } = useStateContext();
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const steps = [1, 2, 3, 4];

  const navigate = useNavigate();
  const [snapchatData, setSnapchatData] = useState({
    client_id: null,
    code: null,
    isRedirected: false,
  });

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const isStepOptional = (step) => {};
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const token = localStorage.getItem("auth-token");

  const fetchUsers = async () => {
    setTableLoading(true);
    try {
      const usersData = await axios.get(`${BACKEND_URL}/snapchat/get-user`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (usersData?.data?.data) {
        const { name, id, client_id, refresh_token, code } =
          usersData?.data?.data;
        setUsers([usersData.data.data]);
        const access_token = await snapcahtRefreshToken(
          client_id,
          refresh_token
        );
        setSnapchatCredentials({
          name,
          id,
          code,
          client_id,
          refresh_token,
          access_token,
        });
      }
    } catch (error) {
      console.log(error, "can't fetch tokens ");
    } finally {
      setTableLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleFormSubmit = async (id) => {
    const token = localStorage?.getItem("auth-token");
    const url = snapchatData?.isRedirected ? "/access-token" : "/get-code";
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat${url}`,
        {
          client_id: snapchatData?.client_id,
          ...(snapchatData?.isRedirected && {
            code: snapchatData?.code,
          }),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      toast.success("User is successfully added.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      fetchUsers();
      if (snapchatData?.isRedirected) {
        setSnapchatData({ isRedirected: false, client_id: null, code: null });
        fetchUsers();
      } else {
        window.open(res?.data?.url, "_blank");
        setSnapchatData((pre) => ({ ...pre, isRedirected: true }));
      }
    } catch (err) {
      console.log(err);
      toast.error("Invalid Client Id", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const deleteUser = async () => {
    setTableLoading(true);
    try {
      const usersData = await axios.delete(
        `${BACKEND_URL}/snapchat/delete-user`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      setUsers([]);
      setSnapchatCredentials({});
    } catch (error) {
      console.log(error, "can't fetch tokens ");
    } finally {
      setTableLoading(false);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: <div className="px-5">Name</div>,
      headerAlign: "left",
      align: "left",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="w-full h-full flex items-center px-9 ">
            {params?.row?.name}
          </div>
        );
      },
    },
    {
      field: "client_id",
      headerName: "Client Id",
      headerAlign: "center",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return <div>{params.row.client_id}</div>;
      },
    },
    {
      field: "code",
      headerName: "Code",
      headerAlign: "center",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return <div>{params.row.code}</div>;
      },
    },

    {
      field: "",
      headerName: "Actions",
      headerAlign: "center",
      minWidth: 70,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <div>
            <p
              style={{ cursor: "pointer" }}
              className={`text-white bg-primary rounded-full shadow-none p-1.5 mr-1 flex items-center justify-center reminderBtn`}
            >
              <Tooltip title="Delete Token" arrow>
                <button
                  aria-label="delete token"
                  onClick={() => deleteUser(cellValues?.row?.id)}
                >
                  <MdOutlineDelete size={16} />
                </button>
              </Tooltip>
            </p>
          </div>
        );
      },
    },
  ];

  return (
    <div className="">
      {users?.length == 0 && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleFormSubmit();
          }}
          disabled={loading ? true : false}
        >
          <div className=" flex items-center pb-3 mt-3 ml-3 rounded-full ">
            <IconButton
              className="rounded-full bg-primary"
              sx={{ borderRadius: "100%", backgroundColor: primaryColor }}
              onClick={() => navigate("/integrations")}
            >
              <FaArrowLeft className={`text-white`} />
            </IconButton>
          </div>

          <div className="grid place-items-center h-auto">
            <div
              className={`${
                themeBgImg &&
                (currentMode === "dark"
                  ? // ? "blur-bg-dark shadow-sm"
                    // : "blur-bg-light shadow-sm")
                    "bg-blue-500 shadow-sm"
                  : "blur-bg-light shadow-sm")
              } p-5 rounded-lg w-4/6  `}
              style={{
                background: currentMode === "dark" ? "#1c1c1c" : "#EEEEEE",
              }}
            >
              <div className=" mt-5">
                <div className="px-4">
                  <Box
                    sx={{
                      ...darkModeColors,
                      "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                        {
                          right: isLangRTL(i18n.language)
                            ? "2.5rem"
                            : "inherit",
                          transformOrigin: isLangRTL(i18n.language)
                            ? "right"
                            : "left",
                        },
                      "& legend": {
                        textAlign: isLangRTL(i18n.language) ? "right" : "left",
                      },
                    }}
                  >
                    <div className="flex justify-between space-x-4">
                      <TextField
                        id="notes"
                        type={"text"}
                        label={t("client_id")}
                        className="w-full"
                        sx={{
                          "&": {
                            marginBottom: "1.25rem !important",
                            zIndex: 1,
                          },
                        }}
                        variant="outlined"
                        size="small"
                        value={snapchatData.client_id}
                        onChange={(e) =>
                          setSnapchatData({
                            ...snapchatData,
                            client_id: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    {snapchatData?.isRedirected && (
                      <div className="flex justify-between space-x-4">
                        <TextField
                          id="notes"
                          type={"text"}
                          label={t("Code")}
                          className="w-full"
                          sx={{
                            "&": {
                              marginBottom: "1.25rem !important",
                              zIndex: 1,
                            },
                          }}
                          variant="outlined"
                          size="small"
                          value={snapchatData.code}
                          onChange={(e) =>
                            setSnapchatData({
                              ...snapchatData,
                              code: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    )}
                  </Box>
                </div>
              </div>
              <p
                className={`px-5 w-[100%] leading-9 text-[11px] ${
                  currentMode === "dark" ? "text-white" : "text-black"
                }`}
              >
                <span className="text-red-600   py-2 font-medium   mb-2">
                  Note!
                </span>{" "}
                After submitting client id you will be redirected to
                hikalproperties.com you have to copy unique code from the url
                like hikalproperties.com/
                <span className="bg-green-600 text-white p-1">code</span> and
                come again to complete the process.
              </p>
              <div className="">
                <Button
                  className={`min-w-fit w-full text-white rounded-md py-3 font-semibold disabled:opacity-50 disabled:cursor-not-allowed hover:shadow-none`}
                  ripple={true}
                  style={{
                    background: `${primaryColor}`,
                  }}
                  size="lg"
                  type="submit"
                  disabled={loading ? true : false}
                >
                  {loading ? (
                    <CircularProgress
                      size={20}
                      sx={{ color: "white" }}
                      className="text-white"
                    />
                  ) : (
                    <span className="text-white">{t("twillio_add")}</span>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
      <Box
        sx={{
          ...DataGridStyles,
          // position: "relative",
          // marginBottom: 50,
          width: "100%",
          // margin: "50px 120px",
          // marginRight: "40px !important",
          padding: "40px",
        }}
      >
        <DataGrid
          disableDensitySelector
          initialState={{
            columns: {
              columnVisibilityModel: {
                creationDate: false,
                otp: false,
                language: false,
              },
            },
          }}
          // ref={dataTableRef}
          loading={tableLoading}
          autoHeight
          disableSelectionOnClick
          // rows={searchRows}
          rows={users}
          rowsPerPageOptions={[30, 50, 75, 100]}
          pagination
          components={{
            Toolbar: GridToolbar,
            // Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: {
              // searchText: searchText,
              // handleSearchChange: handleSearchChange,
            },
          }}
          width="auto"
          paginationMode="server"
          columns={columns}
          // columns={{}}
          sx={{
            boxShadow: 2,
            "& .MuiDataGrid-main": {
              overflowY: "scroll",
              height: "auto",
              borderRadius: "12px",
            },
            "& .MuiDataGrid-cell[data-field='edit'] svg": {
              color:
                currentMode === "dark"
                  ? "white !important"
                  : "black !important",
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
        />
      </Box>
    </div>
  );
};

export default SnapChat;

import React, { useEffect, useState } from "react";
import Loader from "../../Components/Loader";
import { useStateContext } from "../../context/ContextProvider";
import Header from "../../Components/snapchatMarketing/Header";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../../axoisConfig";
import { PiCopyLight } from "react-icons/pi";
import { toast } from "react-toastify";
import CreativeTable from "../../Components/snapchatMarketing/CreativeTable";
import SingleCreative from "../../Components/snapchatMarketing/SingleCreative";
const token = localStorage?.getItem("auth-token");
const CreativeLibrary = () => {
  const {
    currentMode,
    setopenBackDrop,
    BACKEND_URL,
    pageState,
    t,
    themeBgImg,
    snapchatCredentials,
    DataGridStyles,
    primaryColor,
  } = useStateContext();
  const [creatives, setCreatives] = useState([]);
  const [isSingleCreative, setIsSingleCreative] = useState(false);
  const [creativeDetails, setCreativeDetails] = useState(null);
  console.log(snapchatCredentials, "credentials");

  async function getAllCreatives() {
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/get-all-creative`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      setCreatives(res?.data?.data?.creatives?.map((item) => item?.creative));
      return res?.data?.data?.creatives?.map((item) => item?.creative);
    } catch (error) {
      console.log(error, "error");
    }
  }
  // async function getThumbnail(id) {
  //   try {
  //     const res = await axios.post(
  //       `${BACKEND_URL}/snapchat/thumbnil-specfic-media`,
  //       {
  //         access_token: snapchatCredentials?.access_token,
  //         media_id: id,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + token,
  //         },
  //       }
  //     );
  //     return res?.data?.data;
  //   } catch (error) {
  //     console.log(error, "error");
  //   }
  // }
  // async function fetchMediaDetails(id) {
  //   try {
  //     const res = await axios.post(
  //       `${BACKEND_URL}/snapchat/specific-media`,
  //       {
  //         access_token: snapchatCredentials?.access_token,
  //         media_id: id,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + token,
  //         },
  //       }
  //     );

  //     return res?.data?.data?.media[0]?.media;
  //   } catch (error) {
  //     toast.error("Unable to load ad details", {
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     console.log(error, "this is an error");
  //   }
  // }
  async function getAllMedia() {
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/all-media`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      // setAllMedia(
      //   res?.data?.data?.media
      //     ?.map((obj) => obj?.media)
      //     ?.filter((media) => media?.download_link)
      // );
      return res?.data?.data?.media?.map((obj) => obj?.media);
    } catch (error) {
      toast.error("Unable to get all media files", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(error, "this is an error");
    }
  }
  async function getPublicProfiles(id) {
    try {
      const res = await axios.get(
        `https://businessapi.snapchat.com/public/v1/organizations/${snapchatCredentials?.organizations[0]?.id}/public_profiles`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + snapchatCredentials?.access_token,
          },
          // params: {
          //   access_token: snapchatCredentials?.access_token,
          // },
        }
      );
      // return res?.data?.data;
    } catch (error) {
      console.log(error, "error");
    }
  }

  useEffect(() => {
    if (snapchatCredentials?.access_token) {
      thumbnailMaping();
      // getPublicProfiles();
    }
  }, [snapchatCredentials]);

  const thumbnailMaping = async () => {
    try {
      const creatives = await getAllCreatives();
      // const creativePromises = creatives?.map(async (creative) => {
      //   try {
      //     return await fetchMediaDetails(creative?.top_snap_media_id);
      //   } catch (error) {
      //     console.log(error, "this is an error in thumbnail");
      //     return {};
      //   }
      // });
      // let thumbnailObjs = await Promise.all(creativePromises);
      let thumbnailObjs = await getAllMedia();
      console.log(thumbnailObjs, "obejects of thumbnail");
      setCreatives((pre) => {
        return [
          ...pre.map((creative) => {
            creative.thumbnail = thumbnailObjs.find(
              (thumbnail) => thumbnail?.id == creative?.top_snap_media_id
            )?.download_link;
            creative.mediaType = thumbnailObjs.find(
              (thumbnail) => thumbnail?.id == creative?.top_snap_media_id
            )?.type;
            console.log(creative, "creative");
            return { ...creative };
          }),
        ];
      });
    } catch (error) {}
  };

  // useEffect(() => {
  //   if (creatives?.length > 0) {

  //   }
  // }, [creatives]);

  return (
    <>
      <div className="px-9">
        <div className="flex justify-between items-center py-8">
          <h2
            className={`${
              currentMode === "dark" ? "text-white" : "text-[rgb(68,69,70)]"
            }  font-bold text-[24px] font-sans mb-3`}
          >
            Creative Library
          </h2>
          <button
            // onClick={() => setInviteMember(true)}
            className="flex items-center gap-2 p-2 px-5 rounded-[16px] bg-black text-white"
          >
            <span
              className={`text-[14px] font-medium`}
              onClick={() => {
                setIsSingleCreative(true);
                setCreativeDetails(null);
              }}
            >
              New Creative
            </span>
          </button>
        </div>
        {/* <CreativeT creatives={creatives} /> */}
        <CreativeTable
          creatives={creatives}
          isSingleCreative={isSingleCreative}
          setIsSingleCreative={setIsSingleCreative}
          creativeDetails={creativeDetails}
          setCreativeDetails={setCreativeDetails}
          getAllCreatives={getAllCreatives}
        />
      </div>
      <SingleCreative
        isSingleCreative={isSingleCreative}
        setIsSingleCreative={setIsSingleCreative}
        creativeDetails={creativeDetails}
        getAllCreatives={thumbnailMaping}
      />
    </>
  );
};

export default CreativeLibrary;

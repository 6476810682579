import React, { useState, useEffect } from "react";
import { Button } from "@material-tailwind/react";
import { IoIosSwap } from "react-icons/io";
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { GoDuplicate } from "react-icons/go";
import axios from "../../axoisConfig";
import { toast } from "react-toastify";
import Select from "react-select";
// import { selectStyles } from "../_elements/SelectStyles";
import useCurrencyInfo from "../_elements/currencyConverter";
import { useStateContext } from "../../context/ContextProvider";
import { borderBottom } from "@mui/system";
const style = {
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};
const selectStyles = (currentMode, primaryColor) => ({
  control: (provided, state) => ({
    ...provided,
    background: "transparent",
    color: currentMode === "dark" ? "#FFFFFF" : "#000000",
    height: "34x",
    minHeight: "34px",
    marginBottom: "20px !important",
    "&:hover": {
      borderColor: currentMode === "dark" ? "#F7D901" : "#F7D901",
    },
    "&:focus": {
      borderBottom: "2px solid #F7D901",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: currentMode === "dark" ? "#EEEEEE" : "#333333",
  }),
  input: (provided) => ({
    ...provided,
    color: currentMode === "dark" ? "#EEEEEE" : "#333333",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: currentMode === "dark" ? "#EEEEEE" : "#333333",
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  menu: (provided, state) => {
    const hasBgcolor = state.selectProps.options.some(
      (option) => option.bgColor
    );
    return {
      ...provided,
      filter:
        currentMode === "dark"
          ? "drop-shadow(1px 1px 6px rgb(238 238 238 / 0.3))"
          : "drop-shadow(1px 1px 6px rgb(28 28 28 / 0.3))",
      background: hasBgcolor
        ? currentMode === "dark"
          ? "#000000"
          : "#FFFFFF"
        : currentMode === "dark"
        ? "rgb(28 28 28 / 0.9)"
        : "rgb(238 238 238 / 0.9)",
      color: currentMode === "dark" ? "#EEEEEE" : "#333333",
      borderRadius: "10px",
      padding: "5px 10px",
    };
  },
  menuList: (provided) => ({
    ...provided,
    "&::-webkit-scrollbar": {
      width: "2px !important",
    },
    scrollbarWidth: "2px",
  }),
  option: (provided, state) => ({
    ...provided,
    background: state?.data?.bgColor
      ? state?.data?.bgColor
      : state.isSelected
      ? primaryColor
      : currentMode === "dark"
      ? "#000000"
      : "#FFFFFF",
    color: state?.data?.color
      ? state?.data?.color
      : state.isSelected
      ? "#FFFFFF"
      : currentMode === "dark"
      ? "#EEEEEE"
      : "#333333",
    padding: "5px 10px",
    borderRadius: "5px",
    marginTop: "5px",
    marginBottom: "5px",
    wordWrap: "break-word",
    fontSize: "11px",
    "&:hover": {
      background: !state?.data?.bgColor && primaryColor,
      color: !state?.data?.bgColor && "#FFFFFF",
    },
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "auto",
    width: "30px",
    "& svg": {
      height: "12px",
      width: "12px",
      color: currentMode === "dark" ? "#EEEEEE" : "#666666",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
});

const rolesOptions = [
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "Business Admin",
    value: "business_admin",
  },
  {
    label: "Data Admin",
    value: "data_admin",
  },
  {
    label: "Member",
    value: "member",
  },
];

const InviteMember = ({
  setInviteMemberModal,
  inviteMemberModal,
  fetchSnapchatMembers,
}) => {
  const [selectedRoles, setSelectedRoles] = useState([
    {
      label: "Member",
      value: "member",
    },
  ]);
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    t,
    primaryColor,
    themeBgImg,
    i18n,
    isLangRTL,
    snapchatCredentials,
  } = useStateContext();
  const [details, setDetails] = useState({});

  const sendInvitation = async () => {
    const token = localStorage?.getItem("auth-token");
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/create-member`,
        {
          organization_id: snapchatCredentials?.organizations[0]?.id,
          access_token: snapchatCredentials?.access_token,
          display_name: details?.displayName,
          email: details?.email,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      assignRolesToMember(res?.data?.data?.members[0]?.member?.id);
    } catch (error) {
      console.log(error, "it is an error");
    }
  };

  const assignRolesToMember = async (member_id) => {
    const token = localStorage?.getItem("auth-token");
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/assign-role-to-member`,
        {
          organization_id: snapchatCredentials?.organizations[0]?.id,
          type: selectedRoles.map((role) => role?.value),
          member_id: member_id,
          access_token: snapchatCredentials?.access_token,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      setInviteMemberModal(false);
      fetchSnapchatMembers();
      setDetails({
        email: "",
        displayName: "",
      });
      toast.success("Member is Successfully Invited", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.log(error, "this is an error");
      toast.error("Member can't be Invited", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const stripeStyle = {
    backgroundColor: "#e5e7eb", // Light gray background
    backgroundImage:
      "linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent)",
    backgroundSize: "20px 20px", // Size of the stripes
  };
  function validateEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }
  return (
    <Modal
      open={inviteMemberModal}
      onClose={() => setInviteMemberModal(false)}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div
        style={style}
        className={`w-[calc(100%-20px)] md:w-[35%]  ${
          currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
        } absolute top-1/2 left-1/2 p-6 rounded-[12px]`}
      >
        <Box
          sx={{
            ...darkModeColors,
            "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
              {
                right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                transformOrigin: isLangRTL(i18n.language) ? "right" : "left",
              },
            "& legend": {
              textAlign: isLangRTL(i18n.language) ? "right" : "left",
            },
          }}
          className={`flex flex-col gap-[30px] `}
        >
          <IconButton
            sx={{
              position: "absolute",
              right: 12,
              top: 10,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={() => setInviteMemberModal(false)}
          >
            <IoMdClose size={18} />
          </IconButton>
          <div className="flex flex-col gap-8 p-3">
            <h2 className="text-[16px] font-semibold">Invite Members</h2>
            <div className="flex flex-col">
              <label htmlFor="">Email</label>
              <input
                type="email"
                className="p-3 px-4 mt-[2px] bg-[#EDEEEF] rounded-2xl focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
                value={details?.email}
                onChange={(e) =>
                  setDetails((pre) => ({ ...pre, email: e?.target?.value }))
                }
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="">Name</label>
              <input
                type="email"
                className="p-3 px-4 mt-[2px] bg-[#EDEEEF] rounded-2xl focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
                value={details?.displayName}
                onChange={(e) =>
                  setDetails((pre) => ({
                    ...pre,
                    displayName: e?.target?.value,
                  }))
                }
              />
            </div>
            <div>
              <label htmlFor="">Role</label>
              <Select
                id="roles"
                isMulti
                options={rolesOptions}
                value={selectedRoles}
                onChange={(e) => {
                  setSelectedRoles(e);
                }}
                placeholder={t("Role")}
                menuPortalTarget={document.body}
                styles={selectStyles(currentMode, primaryColor)}
              />
              {selectedRoles?.length == 0 && (
                <p className="text-[#C2243E]">
                  A member need to be assigned with role.
                </p>
              )}
            </div>
            <div className="flex justify-end">
              <button
                style={
                  details?.email &&
                  validateEmail(details?.email) &&
                  details?.displayName &&
                  selectedRoles?.length > 0
                    ? { backgroundColor: "black", color: "white" }
                    : stripeStyle
                }
                onClick={() => sendInvitation()}
                className="p-3 px-5 hover:border-[#F7D901] rounded-[20px] border font-semibold bg-white"
                disabled={
                  !(
                    details?.email &&
                    validateEmail(details?.email) &&
                    details?.displayName &&
                    selectedRoles?.length > 0
                  )
                }
              >
                Invite Member
              </button>
            </div>
          </div>
        </Box>
      </div>
    </Modal>
  );
};

export default InviteMember;

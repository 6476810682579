import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function DeleteConfirm({
  open,
  setOpen,
  onDelete,
  message,
  title,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // sx={{ "& .MuiDialog-paper": { width: "300px" } }}
      >
        <DialogTitle id="alert-dialog-title">
          <span className="font-semibold"> {title}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span className="text-black">{message}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            onClick={handleClose}
            className="p-3 px-5 hover:border-[#F7D901] rounded-[20px] border font-semibold bg-white"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              handleClose();
              onDelete(open?.id);
            }}
            className="flex items-center gap-2 p-2 px-5 rounded-[16px] bg-black text-white"
          >
            OK
          </button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

import { useEffect } from "react";
import { TextField } from "@mui/material";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { useStateContext } from "../../../context/ContextProvider";

const ListingAutoComplete = ({
  isDisabled,
  defaultLocation,
  setListingLocation,
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const { currentMode } = useStateContext();
  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
    () => {
      setValue(description, false);
      setListingLocation((prev) => ({ ...prev, addressText: description }));
      clearSuggestions();

      getGeocode({ address: description }).then((results) => {
        console.log("autocomplete", results);
        const { lat, lng } = getLatLng(results[0]);
        const addressComponents = results[0]?.address_components;

        let city = "";
        let country = "";

        // Extract city and country from address_components
        addressComponents?.forEach((component) => {
          console.log("autocomplete addres componenets: ", addressComponents);
          if (component.types.includes("locality")) {
            city = component.long_name; // City
          }
          if (component.types.includes("country")) {
            country = component.long_name; // Country
          }
        });
        setListingLocation((listingLocation) => {
          return { ...listingLocation, lat, lng, city, country };
        });
      });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      console.log(suggestion);
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  useEffect(() => {
    setValue(defaultLocation);
    console.log("addres suggestion is changed:: ", value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLocation]);

  console.log("default location === address text:: ", defaultLocation);

  return (
    <div ref={ref}>
      <TextField
        type={"text"}
        fullWidth
        sx={{
          "& input": {
            color: currentMode === "dark" ? "white" : "black",
            fontFamily: "Noto Kufi Arabic",
          },
        }}
        disabled={isDisabled ? true : !ready}
        onChange={handleInput}
        required
        placeholder="Search Location"
        value={value}
      />
      {status === "OK" && !isDisabled && <ul>{renderSuggestions()}</ul>}
    </div>
  );
};

export default ListingAutoComplete;

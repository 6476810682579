import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { selectStyles } from "../_elements/SelectStyles";
import Select from "react-select";
import { useStateContext } from "../../context/ContextProvider";
import axios from "../../axoisConfig";
import { useEffect } from "react";
import { BsPerson } from "react-icons/bs";
import { RiCloseFill } from "react-icons/ri";
import Loader from "./Loader";

const stripeStyle = {
  backgroundColor: "#e5e7eb", // Light gray background
  backgroundImage:
    "linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent)",
  backgroundSize: "20px 20px", // Size of the stripes
};
const rolesOptions = [
  {
    label: "Account Admin",
    value: "admin",
    description:
      "Account Admins can change ad account details and manage all advertising collateral owned by the Ad Account. Can also view the Snap Pixel code in Ads Manager.",
  },
  {
    label: "Data Manager",
    value: "audience",
    description:
      "Data Managers can upload and manage audience segments within an Ad Account.",
  },
  {
    label: "Creative Manager",
    value: "creative",
    description:
      "Creative Managers can create new creatives and view previously created creatives and media within an Ad Account.",
  },
  {
    label: "Compaign Manager",
    value: "general",
    description:
      "Campaign Managers can create and manage advertising collateral owned by an Ad Account.",
  },
  {
    label: "Data Analyst",
    value: "reports",
    description:
      "Data Analysts can view advertising collateral and pull performance metrics in an Ad Account",
  },
];
const token = localStorage?.getItem("auth-token");
const AdAccountsRoles = ({ member_id, deleteRole }) => {
  const {
    currentMode,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    themeBgImg,
  } = useStateContext();
  const [allAssignedRoles, setAllAssignedRoles] = useState([]);

  const [currentDetails, setCurrentDetails] = useState({
    selectedRole: null,
    selectedAdAccount: null,
  });

  async function getRolesOfMember() {
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/get-role-for-member`,
        {
          access_token: snapchatCredentials?.access_token,
          member_id: member_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      setAllAssignedRoles(
        res?.data?.data?.roles
          ?.map((item) => item?.role)
          ?.filter((role) => role?.container_kind === "AdAccounts")
      );
    } catch (error) {
      console.log(error);
    }
  }
  async function updateRole(ad_account_id, role_id, type) {
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/update-adaccount-role`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_account_id: `${ad_account_id}/`,
          member_id: member_id,
          id: role_id,
          type: type,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      getRolesOfMember();
      toast.success("Role is successfully Updated", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.log(error);
      toast.success("Role can't be updated", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  async function createAdAccountRole() {
    try {
      await axios.post(
        `${BACKEND_URL}/snapchat/create-adaccount-role`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_account_id: `${currentDetails?.selectedAdAccount?.value}/`,
          member_id: member_id,
          type: currentDetails?.selectedRole?.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      setCurrentDetails({ selectedAdAccount: null, selectedRole: null });
      getRolesOfMember();
      toast.success("Role is successfully created", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.log(
        error,
        "this is an error occured while creating new role of ad account"
      );
      toast.error("Role can't be created", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  useEffect(() => {
    getRolesOfMember();
  }, []);

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  return (
    <div>
      <h2
        //  className="text-[rgb(68,69,70)] font-bold text-[20px] font-sans flex gap-4 items-center py-4"
        className={`${
          currentMode === "dark" ? "text-white" : "text-[rgb(68,69,70)]"
        } font-bold text-[20px] font-sans flex gap-4 items-center`}
      >
        Account Memberships
      </h2>
      {/* <Loader /> */}
      <div className={`rounded-2xl  pt-6 ${getSummaryBgClass(0)}`}>
        <div className="flex items-start w-full gap-4  px-6">
          <div className="flex-1 ">
            <Select
              id="adAccounts"
              options={snapchatCredentials?.organizations[0]?.ad_accounts
                ?.map((adAccount) => ({
                  label: adAccount?.name,
                  value: adAccount?.id,
                }))
                .filter(
                  (adAccount) =>
                    !allAssignedRoles?.find(
                      (role) => role?.ad_account_id == adAccount?.value
                    )
                )}
              value={currentDetails?.selectedAdAccount}
              onChange={(e) => {
                setCurrentDetails((pre) => ({ ...pre, selectedAdAccount: e }));
              }}
              placeholder={"Type to link new ad accounts"}
              // className={`mb-5`}
              menuPortalTarget={document.body}
              styles={selectStyles(currentMode, primaryColor)}
            />
          </div>
          <div className="w-[20%]">
            <Select
              id="Billing Center"
              options={rolesOptions}
              value={currentDetails?.selectedRole}
              onChange={(e) => {
                setCurrentDetails((pre) => ({ ...pre, selectedRole: e }));
              }}
              placeholder={t("Role")}
              // className={`mb-5`}
              menuPortalTarget={document.body}
              styles={selectStyles(currentMode, primaryColor)}
            />
          </div>
          <button
            // style={
            // currentDetails.selectedRole && currentDetails?.selectedAdAccount
            //   ? { backgroundColor: "black", color: "white" }
            //   : stripeStyle
            style={
              currentDetails?.selectedRole && currentDetails?.selectedAdAccount
                ? { backgroundColor: "black", color: "white" }
                : currentMode === "dark"
                ? { backgroundColor: "#282b30", color: "white" }
                : stripeStyle
            }
            // }
            className="p-3 px-5 hover:border-[#F7D901] rounded-[20px] border font-semibold bg-white"
            disabled={
              !(
                currentDetails.selectedRole && currentDetails?.selectedAdAccount
              )
            }
            onClick={() => createAdAccountRole()}
          >
            Add Role
          </button>
        </div>
        <div>
          {allAssignedRoles?.map((role) => {
            return (
              <div className="flex items-center justify-between border-t border-gray-300 py-3">
                <div className="flex items-center w-[60%] pl-6">
                  <div className="px-2 pr-5 ">
                    <BsPerson size={22} />
                  </div>
                  <div>
                    <h3 className="text-[14px] font-medium">
                      {
                        snapchatCredentials?.organizations[0]?.ad_accounts?.find(
                          (account) => account?.id == role?.ad_account_id
                        )?.name
                      }
                    </h3>
                    <p>
                      {
                        rolesOptions?.find(
                          (option) => option.value == role?.type
                        )?.description
                      }
                    </p>
                  </div>
                </div>
                <div className="flex items-center w-[18%] gap-8 pr-6">
                  <div className="flex-1 flex flex-col justify-center">
                    {console.log(
                      { ...selectStyles(currentMode, primaryColor)?.control() },
                      "control styles"
                    )}
                    <Select
                      id="Billing Center"
                      options={rolesOptions}
                      value={rolesOptions.find(
                        (option) => option?.value == role?.type
                      )}
                      onChange={(e) => {
                        updateRole(role?.ad_account_id, role?.id, e?.value);
                      }}
                      placeholder={t("Role")}
                      // className={`mb-5`}
                      menuPortalTarget={document.body}
                      styles={{
                        ...selectStyles(currentMode, primaryColor),
                        control: (provided) => ({
                          ...provided,
                          ...selectStyles(currentMode, primaryColor)?.control(),
                          marginBottom: "0px",
                        }),
                      }}
                    />
                  </div>
                  <button
                    className="hover:text-blue-600"
                    onClick={async () => {
                      await deleteRole(role?.id);
                      getRolesOfMember();
                    }}
                  >
                    <RiCloseFill size={20} />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AdAccountsRoles;

import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useStateContext } from "../../context/ContextProvider";
import axios from "../../axoisConfig";
import { useState, useEffect } from "react";
import {
  Addlisting,
  AddListingAttribute,
  AddListingAttrType,
  AddListingMeta,
  AddListingType,
} from "./listingFormComp";
import { toast } from "react-toastify";
import AddListingNearBy from "./listingFormComp/AddListingNearBy";

export default function MultiStepForm({
  FetchListings,
  updateData,
  handleClose,
  fetchSingleListing,
}) {
  const steps = updateData ? [1, 2, 3, 4] : [1, 2, 3, 4, 5];

  console.log("steps lenght::: ", steps.length);

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [allImages, setAllImages] = useState([]);
  const [allDocs, setAllDocs] = useState([]);
  const [listingIds, setListingIDs] = useState({
    listing_attribute_id: null,
    listing_type_id: null,
    listing_arrtibute_type_id: null,
    meta_description: null,
    new_listing_id: null,
  });
  console.log("listingIDS::: ", listingIds);
  const [column, setColumn] = useState({
    list_type: [],
    list_attribute: [],
    list_attr_type: [],
  });
  const [data, setData] = useState({
    list_type: [],
    list_attribute: [],
    list_attr_type: [],
  });
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    isArabic,
    primaryColor,
    t,
    isLangRTL,
    i18n,
    fontFam,
  } = useStateContext();
  const token = localStorage.getItem("auth-token");
  const [last_page, setLastPage] = useState(null);
  const [total, setTotal] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(null);
  const [loading, setLoading] = useState(false);
  const [developers, setDevelopers] = useState([]);
  const [listData, setListData] = useState({
    listing_type: updateData?.listing_type || "",
    property_type: updateData?.property_type || "",
    developer: updateData?.developer || "",
    project: updateData?.project || "",
    bedrooms: updateData?.bedrooms || "",
    bathrooms: updateData?.bathrooms || "",
    size: updateData?.size || "",
    address: updateData?.address || "",
    area: updateData?.area || "",
    latlong: updateData?.latlong || "",
    location: updateData?.location || "",
    city: updateData?.city || "",
    country: updateData?.country || "",
    listing_status: updateData?.listing_status || 1,
    is_featured: updateData?.is_featured || 2,
    currency: updateData?.currency || "AED",
    is_start_price: updateData?.is_start_price || 2,
    price: updateData?.price || "",
    handover: updateData?.handover || "",
    listing_title: updateData?.listing_title || "",
    description: updateData?.description || "",
    nearby: updateData?.nearby || "",
    banner_img: updateData?.banner_img || "",
    promo_video: updateData?.promo_video || "",
    size_unit: updateData?.size_unit || "sq.ft",
  });

  console.log("listing data state :: ", listData);

  let type =
    activeStep == 0
      ? "list_attribute"
      : // : activeStep == 1
        // ? "list_attribute"
        // : activeStep == 2
        // ? "list_attr_type"
        null;

  console.log("form type ::: ", type);

  const isStepOptional = (step) => {};

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // const FetchData = async () => {
  //   setLoading(true);
  //   let url = `${BACKEND_URL}/listing-types?page=${page}`;

  //   try {
  //     const [listingsData, developers] = await Promise.all([
  //       axios.get(url, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + token,
  //         },
  //       }),
  //       axios.get(`${BACKEND_URL}/vendors`, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + token,
  //         },
  //       }),
  //     ]);

  //     console.log("all listings: ", listingsData);
  //     console.log("all devloper: ", developers);
  //     let listings = listingsData?.data?.data?.data || [];

  //     let rowsDataArray = "";
  //     if (listingsData?.data?.data?.current_page > 1) {
  //       const theme_values = Object.values(listings);
  //       rowsDataArray = theme_values;
  //     } else {
  //       rowsDataArray = listings;
  //     }

  //     let rowsData = rowsDataArray?.map((row, index) => {
  //       if (type === "list_attribute") {
  //         return {
  //           lid: row?.id,
  //           id: page > 1 ? page * pageSize - (pageSize - 1) + index : index + 1,
  //           name: row?.name,
  //         };
  //       } else {
  //         return {};
  //       }
  //     });

  //     setData((prevData) => ({
  //       ...prevData,
  //       list_type: rowsData,
  //     }));

  //     // vendors
  //     const vendors = developers?.data?.data?.data;
  //     const filterDevs = vendors?.filter(
  //       (ven) => ven?.type?.toLowerCase() === "developer"
  //     );

  //     setLoading(false);
  //     setDevelopers(filterDevs);
  //     setLastPage(listingsData?.data?.data?.last_page);
  //     setPageSize(listingsData?.data?.data?.per_page);
  //     setTotal(listingsData?.data?.data?.total);
  //   } catch (error) {
  //     console.log("listings not fetched. ", error);
  //     toast.error("Unable to fetch data.", {
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     setLoading(false);
  //   }
  // };

  const FetchData = async () => {
    setLoading(true);
    let url = `${BACKEND_URL}/listing-types?page=${page}`;

    try {
      let listingsData, developers;

      // Check if updateData is true or false
      // if (updateData) {
      //   listingsData = await axios.get(url, {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: "Bearer " + token,
      //     },
      //   });
      // } else {
      // Call both listing-types and vendors APIs
      // [listingsData, developers] = await Promise.all([
      //   axios.get(url, {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: "Bearer " + token,
      //     },
      //   }),
      //   axios.get(`${BACKEND_URL}/vendors`, {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: "Bearer " + token,
      //     },
      //   }),
      // ]);
      if (!updateData) {
        developers = await axios.get(`${BACKEND_URL}/vendors`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
      }
      // }

      console.log("all listings: ", listingsData);
      if (developers) {
        console.log("all developers: ", developers);
      }

      let listings = listingsData?.data?.data?.data || [];

      let rowsDataArray = "";
      if (listingsData?.data?.data?.current_page > 1) {
        const theme_values = Object.values(listings);
        rowsDataArray = theme_values;
      } else {
        rowsDataArray = listings;
      }

      let rowsData = rowsDataArray?.map((row, index) => {
        if (type === "list_attribute") {
          return {
            lid: row?.id,
            id: page > 1 ? page * pageSize - (pageSize - 1) + index : index + 1,
            name: row?.name,
          };
        } else {
          return {};
        }
      });

      setData((prevData) => ({
        ...prevData,
        list_type: rowsData,
      }));

      // Process vendors only if the developers data is available
      if (developers) {
        const vendors = developers?.data?.data?.data;
        const filterDevs = vendors?.filter(
          (ven) => ven?.type?.toLowerCase() === "developer"
        );
        setDevelopers(filterDevs);
      }

      setLoading(false);
      setLastPage(listingsData?.data?.data?.last_page);
      setPageSize(listingsData?.data?.data?.per_page);
      setTotal(listingsData?.data?.data?.total);
    } catch (error) {
      console.log("listings not fetched. ", error);
      toast.error("Unable to fetch data.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      type === "list_attribute"
      // type === "list_type" ||
      // type === "list_attr_type"
    ) {
      console.log("api called: ");

      FetchData();
    }
  }, [page, pageSize, type]);

  return (
    <Box
      sx={{
        ...darkModeColors,
        "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
          {
            right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
            transformOrigin: isLangRTL(i18n.language) ? "right" : "left",
          },
        "& legend": {
          textAlign: isLangRTL(i18n.language) ? "right" : "left",
        },
      }}
    >
      <Stepper activeStep={activeStep}>
        {steps?.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography
                variant="caption"
                className={`${
                  currentMode === "dark" ? "text-white" : "text-black"
                }`}
              >
                Optional
              </Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel
                {...labelProps}
                sx={{
                  "& .css-1hv8oq8-MuiStepLabel-label .Mui-active": {
                    color:
                      currentMode === "dark "
                        ? "text-white !important"
                        : "text-black !important",
                  },
                  "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active":
                    {
                      color: `${primaryColor}`,
                    },
                }}
              >
                <span
                  className={`${
                    currentMode === "dark" ? "text-white" : "text-dark"
                  } ${isLangRTL(i18n.language) ? "mr-2" : "ml-1"} `}
                >
                  {label}
                </span>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography
            sx={{
              mt: 2,
              mb: 1,

              color: currentMode === "dark" ? "text-white" : "text-black",
            }}
          >
            New List added successfully. Click on reset button to add a new
            listing.
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <AddListingType
              data={data}
              setData={setData}
              column={column}
              setColumn={setColumn}
              type="list_type"
              loading={loading}
              setLoading={setLoading}
              page={page}
              pageSize={pageSize}
              total={total}
              setPage={setPage}
              setPageSize={setPageSize}
              FetchData={FetchData}
            /> */}
          {activeStep === 0 && (
            <AddListingAttribute
              data={data}
              setData={setData}
              column={column}
              setColumn={setColumn}
              type="list_attribute"
              loading={loading}
              setLoading={setLoading}
              page={page}
              pageSize={pageSize}
              total={total}
              setPage={setPage}
              setPageSize={setPageSize}
              FetchData={FetchData}
              listingIds={listingIds}
              setListingIDs={setListingIDs}
              handleNext={handleNext}
              developers={developers}
              setDevelopers={setDevelopers}
              listingData={listData}
              listData={updateData}
              setListingData={setListData}
            />
          )}
          {activeStep === 1 && (
            <AddListingAttrType
              data={data}
              setData={setData}
              column={column}
              setColumn={setColumn}
              type="list_attr_type"
              loading={loading}
              setLoading={setLoading}
              page={page}
              pageSize={pageSize}
              total={total}
              setPage={setPage}
              setPageSize={setPageSize}
              FetchData={FetchData}
              listingIds={listingIds}
              setListingIDs={setListingIDs}
              handleNext={handleNext}
              setListingData={setListData}
              listingData={listData}
              listData={updateData}
            />
          )}
          {activeStep === 2 && (
            <Addlisting
              data={data}
              listingIds={listingIds}
              setListingIDs={setListingIDs}
              handleNext={handleNext}
              listingData={listData}
              setListingData={setListData}
              listData={updateData}
            />
          )}
          {activeStep === 3 && (
            <AddListingNearBy
              data={data}
              listingIds={listingIds}
              setListingIDs={setListingIDs}
              handleNext={handleNext}
              listingData={listData}
              setListingData={setListData}
              listData={updateData}
              handleClose={handleClose}
              fetchSingleListing={fetchSingleListing}
              FetchListings={FetchListings}
            />
          )}
          {activeStep === 4 && !updateData ? (
            <AddListingMeta
              listingIds={listingIds}
              setListingIDs={setListingIDs}
              handleNext={handleNext}
              FetchData={FetchData}
              FetchListings={FetchListings}
              listingData={listData}
              setListingData={setListData}
              allImages={allImages}
              setAllImages={setAllImages}
              allDocs={allDocs}
              setAllDocs={setAllDocs}
              listData={updateData}
            />
          ) : null}
          {/* {activeStep === 4 && } */}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            {activeStep == 0 ? null : (
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
            )}
            <Box sx={{ flex: "1 1 auto" }} />
            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}
            {updateData && activeStep === 3 ? null : (
              <Button onClick={handleNext}>
                <span className="text-primary">
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </span>
              </Button>
            )}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}

import React, { useState } from "react";
import { PiCopyLight } from "react-icons/pi";
import { toast, ToastContainer } from "react-toastify";
import { selectStyles } from "../_elements/SelectStyles";
import Select from "react-select";
import { useStateContext } from "../../context/ContextProvider";
import axios from "../../axoisConfig";
import { useEffect } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import DeleteConfirm from "./DeleteConfirmation";
import AdAccountsRoles from "./AdAccountsRoles";
const rolesOptions = [
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "Business Admin",
    value: "business_admin",
  },
  {
    label: "Data Admin",
    value: "data_admin",
  },
  {
    label: "Member",
    value: "member",
  },
];
const SingleMember = ({ member, setMember, fetchSnapchatMembers }) => {
  const {
    currentMode,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    themeBgImg,
  } = useStateContext();
  const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [isChangesOccured, setIsChangesOccured] = useState(false);
  const [displayName, setDisplayName] = useState("");

  useEffect(() => {
    setSelectedRoles(
      member?.roles?.map((role) => ({
        label: role?.type
          ?.replace("_", " ")
          ?.replace(/^\w/, (c) => c.toUpperCase()),
        value: role?.type,
      }))
    );
    setDisplayName(member?.display_name);
  }, [member]);

  useEffect(() => {
    let isDeleted = member?.roles?.map((role) => {
      return Boolean(selectedRoles?.find((sRole) => sRole.value == role?.type));
    });
    let isAdded = selectedRoles.map((sRole) => {
      return Boolean(member?.roles?.find((role) => sRole.value == role?.type));
    });
    console.log(isAdded, "is added", isDeleted, "is deleted");

    isDeleted =
      isDeleted.length == 0
        ? false
        : isDeleted.reduce((pre, curr) => pre && curr, true);
    isAdded =
      isAdded?.length == 0
        ? false
        : isAdded.reduce((pre, curr) => pre && curr, true);
    setIsChangesOccured(!(isDeleted && isAdded));
  }, [member, selectedRoles]);
  const stripeStyle = {
    backgroundColor: "#e5e7eb", // Light gray background
    backgroundImage:
      "linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent)",
    backgroundSize: "20px 20px", // Size of the stripes
  };

  async function deleteMember() {
    const token = localStorage?.getItem("auth-token");
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/delete-member`,
        {
          access_token: snapchatCredentials?.access_token,
          member_id: member?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      toast.success("Member is successfully deleted", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setMember(null);
      fetchSnapchatMembers();
    } catch (error) {
      console.log(error, "this is an error by delete snapchat member");
      toast.success("Member can't be deleted", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
  const assignRolesToMember = async (member_id, roles) => {
    const token = localStorage?.getItem("auth-token");
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/assign-role-to-member`,
        {
          organization_id: snapchatCredentials?.organizations[0]?.id,
          type: roles,
          member_id: member_id,
          access_token: snapchatCredentials?.access_token,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    } catch (error) {
      console.log(error, "this is an error");
    }
  };

  async function deleteRole(role_id) {
    const token = localStorage?.getItem("auth-token");
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/delete-role`,
        {
          access_token: snapchatCredentials?.access_token,
          role_id: role_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    } catch (error) {
      console.log(error, "this is an erro occured while deleting the role");
    }
  }

  async function saveChanges() {
    try {
      member?.roles?.forEach(async (role) => {
        if (!selectedRoles?.find((sRole) => sRole.value == role?.type)) {
          try {
            await deleteRole(role?.id);
          } catch (error) {
            console.log(error);
          }
        }
      });
      selectedRoles.map(async (sRole) => {
        if (!member?.roles?.find((role) => sRole.value == role?.type)) {
          try {
            await assignRolesToMember(member?.id, [sRole?.value]);
          } catch (error) {
            console.log(error);
          }
        }
      });

      if (member?.display_name !== displayName) {
        await updateMember();
      }
      fetchSnapchatMembers();
      toast.success("Changes are successfully saved", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.log(error);
      toast.error("Changes can't be saved", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  async function updateMember() {
    const token = localStorage?.getItem("auth-token");
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/update-member`,
        {
          access_token: snapchatCredentials?.access_token,
          organization_id: snapchatCredentials?.organizations[0]?.id,
          email: member?.email,
          display_name: displayName,
          member_id: member?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  }
  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  return (
    <>
      <div className={`px-[80px] py-10 ${getSummaryBgClass()}`}>
        <div
          onClick={() => setMember(null)}
          className="flex gap-2 items-center"
        >
          <div
            className={`p-2  cursor-pointer rounded-full w-fit  border hover:border-[#F7D901] box-border ${
              currentMode === "dark" ? "bg-black text-white" : "bg-white"
            }`}
          >
            <MdKeyboardArrowLeft size={16} />
          </div>
          Back to Members List
        </div>
        <div className="flex justify-between items-center">
          <h2
            className={`${
              currentMode === "dark" ? "text-white" : "text-[rgb(68,69,70)]"
            } font-bold text-[20px] font-sans flex gap-4 items-center`}
          >
            {displayName}
            {member?.member_status == "INVITED" && (
              <span className="bg-[#EF5001] py-2 px-3 text-[12px] font-normal rounded-[14px] text-white ">
                Invite Sent
              </span>
            )}
          </h2>
          <div className="flex items-center gap-2 mb-3 ">
            <button
              onClick={() => setOpenDeleteConfirm(true)}
              className={`p-3 px-5 hover:border-[#F7D901] rounded-[20px] border font-semibold ${
                currentMode == "dark" ? "bg-black text-white" : "bg-white"
              } `}
            >
              Remove Member
            </button>

            <button
              // style={
              //   isChangesOccured
              //     ? { backgroundColor: "black", color: "white" }
              //     : stripeStyle
              // }
              style={
                isChangesOccured
                  ? { backgroundColor: "black", color: "white" }
                  : currentMode === "dark"
                  ? { backgroundColor: "#282b30", color: "white" }
                  : stripeStyle
              }
              // className="p-3 px-5 hover:border-[#F7D901] rounded-[20px] border font-semibold bg-white"
              className={`p-3 px-5 hover:border-[#F7D901] rounded-[20px] border font-semibold ${
                currentMode == "dark" ? "bg-black text-white" : "bg-white"
              } disabled:cursor-not-allowed `}
              disabled={!isChangesOccured}
              onClick={() => saveChanges()}
            >
              Save Changes
            </button>
          </div>
        </div>

        <div
          className={`rounded-2xl  p-6 ${
            currentMode === "dark" ? "bg-dark-neu" : "bg-white"
          }`}
        >
          <div className="grid grid-cols-2 pt-7 gap-3">
            <div className="flex flex-col">
              <label htmlFor="">Name</label>
              <input
                type="text"
                // className="p-2 mt-[2px] bg-[#F9F9FA] rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
                className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
                value={displayName}
                onChange={(e) => {
                  setDisplayName(e?.target?.value);
                  setIsChangesOccured(
                    !(e?.target?.value == member?.display_name)
                  );
                }}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="">Email</label>
              <input
                type="text"
                // style={stripeStyle}
                style={
                  currentMode === "dark"
                    ? { backgroundColor: "#282b30", color: "white" }
                    : stripeStyle
                }
                // className="p-2 mt-[2px] bg-[#F9F9FA] rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] "
                className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
                value={member?.email}
                disabled={true}
              />
            </div>
            <div>
              <label htmlFor="">Role</label>
              <Select
                id="Billing Center"
                isMulti
                options={rolesOptions}
                value={selectedRoles}
                onChange={(e) => {
                  setSelectedRoles(e);
                }}
                placeholder={t("Role")}
                // className={`mb-5`}
                menuPortalTarget={document.body}
                styles={selectStyles(currentMode, primaryColor)}
              />
              {selectedRoles?.length == 0 && (
                <p className="text-[#C2243E]">
                  A member need to be assigned with role.
                </p>
              )}
            </div>
            {/* 
            <div>
              <label htmlFor="">Advertising Organization</label>
              <Select
                id="Lead Source"
                // options={source_options(t)}
                // value={filtersData?.leadSource}
                // onChange={(e) => {
                //   setFiltersData({
                //     ...filtersData,
                //     leadSource: {
                //       label: e?.label,
                //       value: e?.value?.toLowerCase(),
                //     },
                //   });
                // }}
                placeholder={t("lead_source")}
                // className={`mb-5`}
                menuPortalTarget={document.body}
                styles={selectStyles(currentMode, primaryColor)}
              />
            </div> */}
          </div>
        </div>
        <AdAccountsRoles member_id={member?.id} deleteRole={deleteRole} />
      </div>
      <DeleteConfirm
        open={openDeleteConfirm}
        setOpen={setOpenDeleteConfirm}
        message={` Are you sure you want to remove ${member?.display_name}? All of their asset
              memberships will also be removed.`}
        title="Confirm Remove Member"
        onDelete={deleteMember}
      />
    </>
  );
};

export default SingleMember;

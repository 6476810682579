import React, { useEffect, useState, useRef } from "react";

import { toast } from "react-toastify";
import { AiOutlineHistory } from "react-icons/ai";
import {
  TextField,
  CircularProgress,
  Tooltip,
  Modal,
  Backdrop,
  InputAdornment,
  FormControlLabel,
  Switch,
  Checkbox,
  Box,
} from "@mui/material";
import moment from "moment/moment";
import { IoSearch } from "react-icons/io5";

import axios from "../../axoisConfig";
import usePermission from "../../utils/usePermission";
import { useStateContext } from "../../context/ContextProvider";
import { MdClose } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
const style = {
  transform: "translate(0%, 0%)",
  boxShadow: 24,
};
const stripeStyle = {
  backgroundColor: "#e5e7eb", // Light gray background
  backgroundImage:
    "linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent)",
  backgroundSize: "20px 20px", // Size of the stripes
};
const token = localStorage?.getItem("auth-token");
const MediaFiles = ({
  isMediaFilesModal,
  setIsMediaFilesModal,
  setCreativeDetails,
}) => {
  const {
    isLangRTL,
    BACKEND_URL,
    i18n,
    currentMode,
    t,
    snapchatCredentials,
    themeBgImg,
    darkModeColors,
  } = useStateContext();
  const [isClosing, setIsClosing] = useState(false);
  const [allMedia, setAllMedia] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleClose = () => {
    setIsClosing(true);

    setTimeout(() => {
      setIsClosing(false);
      setIsMediaFilesModal(false);
    }, 1000);
  };

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  async function getAllMedia() {
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/all-media`,
        {
          access_token: snapchatCredentials?.access_token,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      setAllMedia(
        res?.data?.data?.media
          ?.map((obj) => obj?.media)
          ?.filter((media) => media?.download_link)
      );
      return res?.data?.data?.media?.map((obj) => obj?.media);
    } catch (error) {
      toast.error("Unable to get all media files", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(error, "this is an error");
    }
  }

  useEffect(() => {
    if (snapchatCredentials?.access_token) {
      getAllMedia();
    }
  }, [snapchatCredentials]);
  function convertSecondsToTime(durationInSeconds) {
    // Get whole hours, minutes, and seconds
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60); // Round down to the nearest whole second

    // Format each part to be two digits, adding leading zeros where needed
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  // Example usage
  const durationInSeconds = 30.528;
  console.log(convertSecondsToTime(durationInSeconds)); // Output: "00:00:30"

  return (
    <>
      <Modal
        keepMounted
        open={isMediaFilesModal}
        onClose={() => setIsMediaFilesModal(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        sx={{ zIndex: 1400 }} // Adjust z-index as needed
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          className={`${
            isLangRTL(i18n.language) ? "modal-open-left" : "modal-open-right"
          } ${
            isClosing
              ? isLangRTL(i18n.language)
                ? "modal-close-left"
                : "modal-close-right"
              : ""
          }
        w-[100vw] h-[100vh] flex items-start justify-end `}
        >
          <button
            // onClick={handleCloseTimelineModel}
            onClick={handleClose}
            className={`${
              isLangRTL(i18n.language) ? "rounded-r-full" : "rounded-l-full"
            }
            bg-primary w-fit h-fit p-3 my-4 z-10`}
          >
            <MdClose
              size={18}
              color={"white"}
              className=" hover:border hover:border-white hover:rounded-full"
            />
          </button>
          {/* ${
              currentMode === "dark"
                ? "bg-[#000000] text-white"
                : "bg-[#F9F9FA] text-black"
            }  */}
          <div
            style={style}
            className={`${getSummaryBgClass()} ${
              isLangRTL(i18n.language)
                ? currentMode === "dark" && " border-primary border-r-2"
                : currentMode === "dark" && " border-primary border-l-2"
            } 
             pt-4 h-[100vh] w-[40vw] overflow-y-scroll border-primary px-7 flex flex-col relative
            `}
          >
            <h2 className="font-bold text-[20px] mb-5">Select Media</h2>
            <div
              className={`flex items-center gap-4 border-b rounded-2xl py-4 px-7 ${
                currentMode === "dark" ? "bg-dark-neu" : "bg-[rgb(249,249,250)]"
              } `}
            >
              <IoSearch size={16} />

              <input
                type="text"
                className={`focus:outline-none border-none h-full flex-1  ${
                  currentMode === "dark" ? "bg-dark" : "bg-[rgb(249,249,250)]"
                }`}
                placeholder="Search media ..."
                onChange={(e) => setSearchTerm(e?.target?.value)}
                value={searchTerm}
              />
            </div>
            <div className="mt-6 flex flex-wrap gap-8">
              {allMedia
                ?.filter((media) =>
                  media?.name
                    ?.toLowerCase()
                    ?.includes(searchTerm?.toLocaleLowerCase())
                )
                ?.map((media) => {
                  return (
                    <div
                      onClick={() => {
                        setCreativeDetails((pre) => ({
                          ...pre,
                          topMedia: media,
                        }));
                        setIsMediaFilesModal(false);
                      }}
                      className="relative h-[320px] w-[180px] rounded-2xl overflow-hidden border-[2px] border-transparent cursor-pointer hover:border-[#F7D901]"
                    >
                      <div className="absolute inset-0">
                        {media?.type === "IMAGE" ? (
                          <img
                            src={media?.download_link}
                            className="h-full w-full object-cover"
                            alt=""
                          />
                        ) : (
                          <video
                            src={media?.download_link}
                            className="h-full w-full object-cover"
                          ></video>
                        )}
                      </div>
                      <div className="relative z-10 flex flex-col justify-between py-4 h-full px-4 items-start">
                        <div
                          className={`${
                            currentMode === "dark" ? "bg-dark" : "bg-white"
                          } rounded-full px-4 py-1`}
                        >
                          {media?.type === "IMAGE"
                            ? media?.image_metadata?.width_px +
                              " * " +
                              media?.image_metadata?.height_px
                            : convertSecondsToTime(media?.duration_in_seconds)}
                        </div>
                        <div className={`text-white`}>{media?.name}</div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MediaFiles;

import React, { useEffect, useState, useRef } from "react";

import { toast } from "react-toastify";
import { AiOutlineHistory } from "react-icons/ai";
import {
  TextField,
  CircularProgress,
  Tooltip,
  Modal,
  Backdrop,
  InputAdornment,
  FormControlLabel,
  Switch,
  Checkbox,
} from "@mui/material";
import moment from "moment/moment";

import axios from "../../axoisConfig";
import usePermission from "../../utils/usePermission";
import { useStateContext } from "../../context/ContextProvider";
import { MdClose } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Box } from "@mui/material";
const style = {
  transform: "translate(0%, 0%)",
  boxShadow: 24,
};
const stripeStyle = {
  backgroundColor: "#e5e7eb", // Light gray background
  backgroundImage:
    "linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent)",
  backgroundSize: "20px 20px", // Size of the stripes
};
const token = localStorage?.getItem("auth-token");
const SingleCampaign = ({
  isSingleCampaign,
  setIsSingleCampaign,
  setCreateCampaignModal,
  oldObjectives,
  currentCampaignDetails,
  setCurrentCampaignDetails,
  getAllCompaigns,
}) => {
  const {
    isLangRTL,
    BACKEND_URL,
    i18n,
    currentMode,
    t,
    snapchatCredentials,
    themeBgImg,
    darkModeColors,
  } = useStateContext();
  const [isClosing, setIsClosing] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isChangesOccured, setIsChangesOccured] = useState(false);
  const [campaignDetails, setCampaignDetials] = useState({
    campaignName: "",
    status: false,
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
    dailySpendCap: null,
    lifeTimeSpendCap: null,
  });
  const [localData, setLocalData] = useState({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
  });
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleClose = () => {
    setIsClosing(true);

    setTimeout(() => {
      setIsClosing(false);
      setIsSingleCampaign(false);
    }, 1000);
  };

  console.log(localData?.startDate, localData?.startTime, "date and time");

  useEffect(() => {
    if (currentCampaignDetails) {
      const {
        name,
        objective,
        start_time,
        end_time,
        lifetime_spend_cap_micro,
        daily_budget_micro,
        status,
      } = currentCampaignDetails;
      const fullStart = dayjs(start_time);
      const fullEnd = end_time ? dayjs(end_time) : undefined;

      setCampaignDetials({
        ...(daily_budget_micro
          ? {
              dailySpendCap: daily_budget_micro / 1000000,
            }
          : { dailySpendCap: "" }),
        ...(lifetime_spend_cap_micro
          ? {
              lifeTimeSpendCap: lifetime_spend_cap_micro / 1000000,
            }
          : { lifeTimeSpendCap: "" }),
        campaignName: name,
        status: status === "ACTIVE",
      });
      setLocalData({
        startDate: fullStart.startOf("day"), // Set time to 00:00:00
        startTime: fullStart,
        ...(fullEnd?.startOf("day") && { endDate: fullEnd.startOf("day") }),
        ...(fullEnd?.startOf("day") && { endTime: fullEnd }),
      });

      setChecked(Boolean(fullEnd?.startOf("day")));
    } else {
      setLocalData({});
      setCampaignDetials({
        campaignName: "",
        status: false,
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
        dailySpendCap: 0,
        lifeTimeSpendCap: 0,
      });
      setChecked(false);
    }
  }, [currentCampaignDetails]);

  useEffect(() => {
    if (currentCampaignDetails) {
      const {
        name,
        objective,
        start_time,
        end_time,
        lifetime_spend_cap_micro,
        daily_budget_micro,
        status,
      } = currentCampaignDetails;
      const {
        campaignName,
        dailySpendCap,
        lifeTimeSpendCap,
        status: localStatus,
      } = campaignDetails;

      console.log(Boolean(end_time), checked);

      if (
        campaignName != name ||
        (lifetime_spend_cap_micro ? lifetime_spend_cap_micro / 1000000 : "") !=
          lifeTimeSpendCap ||
        dailySpendCap !=
          (daily_budget_micro ? daily_budget_micro / 1000000 : "") ||
        status != (localStatus ? "ACTIVE" : "PAUSED") ||
        Boolean(end_time) != checked
      ) {
        setIsChangesOccured(true);
      } else {
        const startDate = dayjs(localData?.startDate.$d).format("YYYY-MM-DD");
        const startTime = dayjs(localData?.startTime.$d).format("HH:mm:ss.SSS");

        const endDate = dayjs(localData?.endDate?.$d).format("YYYY-MM-DD");
        const endTime = dayjs(localData?.endTime?.$d).format("HH:mm:ss.SSS");
        const START_TIME = localData?.startDate
          ? new Date(`${startDate}T${startTime}`)?.toISOString()
          : null;
        const END_TIME = localData?.endDate
          ? new Date(`${endDate}T${endTime}`)?.toISOString()
          : null;
        if (START_TIME != start_time || END_TIME != end_time) {
          setIsChangesOccured(true);
        } else {
          setIsChangesOccured(false);
        }
      }
    }
  }, [campaignDetails, localData, checked]);

  async function createCampaign() {
    try {
      const startDate = dayjs(localData?.startDate?.$d).format("YYYY-MM-DD");
      const startTime = dayjs(localData?.startTime?.$d).format("HH:mm:ss.SSS");

      const endDate = dayjs(localData?.endDate?.$d).format("YYYY-MM-DD");
      const endTime = dayjs(localData?.endTime?.$d).format("HH:mm:ss.SSS");

      await axios.post(
        `${BACKEND_URL}/snapchat/create/campaign`,
        {
          access_token: snapchatCredentials?.access_token,
          name: campaignDetails?.campaignName,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
          status: campaignDetails?.status ? "ACTIVE" : "PAUSED",
          objective: isSingleCampaign?.identifier,
          start_time: new Date(`${startDate}T${startTime}`)?.toISOString(),
          ...(endDate &&
            checked && {
              end_time: new Date(`${endDate}T${endTime}`)?.toISOString(),
            }),
          ...(campaignDetails?.dailySpendCap && {
            daily_budget_micro: campaignDetails?.dailySpendCap,
          }),
          ...(campaignDetails?.lifeTimeSpendCap && {
            lifetime_spend_cap_micro: campaignDetails?.lifeTimeSpendCap,
          }),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      toast.success("Successfully Created Campaign", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      getAllCompaigns();
    } catch (error) {
      toast.error("Unable to create campaign", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(error, "this is an error");
      if (error.response?.data?.errors) {
        Object.values(error.response?.data?.errors)
          .flat()
          .map((err) =>
            toast.error(err, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            })
          );
      }
    }
  }
  async function updateCampaign() {
    try {
      const startDate = dayjs(localData?.startDate?.$d).format("YYYY-MM-DD");
      const startTime = dayjs(localData?.startTime?.$d).format("HH:mm:ss.SSS");

      const endDate = dayjs(localData?.endDate?.$d).format("YYYY-MM-DD");
      const endTime = dayjs(localData?.endTime?.$d).format("HH:mm:ss.SSS");

      await axios.post(
        `${BACKEND_URL}/snapchat/update/campaign`,
        {
          campaign_id: currentCampaignDetails?.id,
          access_token: snapchatCredentials?.access_token,
          name: campaignDetails?.campaignName,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
          status: campaignDetails?.status ? "ACTIVE" : "PAUSED",
          objective: isSingleCampaign?.identifier,
          start_time: new Date(`${startDate}T${startTime}`)?.toISOString(),
          ...(endDate &&
            checked && {
              end_time: new Date(`${endDate}T${endTime}`)?.toISOString(),
            }),
          ...(campaignDetails?.dailySpendCap && {
            daily_budget_micro: campaignDetails?.dailySpendCap,
          }),
          ...(campaignDetails?.lifeTimeSpendCap && {
            lifetime_spend_cap_micro: campaignDetails?.lifeTimeSpendCap,
          }),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      toast.success(" Campaign is Successfully Updated", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      getAllCompaigns();
    } catch (error) {
      toast.error("Unable to update campaign", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(error, "this is an error");
      if (error.response?.data?.errors) {
        Object.values(error.response?.data?.errors)
          .flat()
          .map((err) =>
            toast.error(err, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            })
          );
      }
    }
  }
  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };

  return (
    <>
      <Modal
        keepMounted
        open={isSingleCampaign}
        onClose={() => setIsSingleCampaign(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          className={`${
            isLangRTL(i18n.language) ? "modal-open-left" : "modal-open-right"
          } ${
            isClosing
              ? isLangRTL(i18n.language)
                ? "modal-close-left"
                : "modal-close-right"
              : ""
          }
        w-[100vw] h-[100vh] flex items-start justify-end `}
        >
          <button
            // onClick={handleCloseTimelineModel}
            onClick={handleClose}
            className={`${
              isLangRTL(i18n.language) ? "rounded-r-full" : "rounded-l-full"
            }
            bg-primary w-fit h-fit p-3 my-4 z-10`}
          >
            <MdClose
              size={18}
              color={"white"}
              className=" hover:border hover:border-white hover:rounded-full"
            />
          </button>
          {/* ${
              currentMode === "dark"
                ? "bg-[#000000] text-white"
                : "bg-[#F9F9FA] text-black"
            }  */}

          <div
            style={style}
            className={` ${getSummaryBgClass()} ${
              isLangRTL(i18n.language)
                ? currentMode === "dark" && " border-primary border-r-2"
                : currentMode === "dark" && " border-primary border-l-2"
            } 
             pt-4 h-[100vh] w-[80vw] overflow-y-scroll border-primary px-7 flex flex-col 
            `}
          >
            <h2 className="font-bold text-[20px] mb-5">Build Your Campaign</h2>
            <h3 className="font-bold text-[16px] mb-5">Campaign Setup</h3>
            <div
              className={`${getSummaryBgClass()}  rounded-3xl px-8 py-4 shadow-lg flex-1`}
            >
              <span className="font-medium">Objective</span>
              <div className="flex gap-3 py-6 items-center">
                <img src={isSingleCampaign?.banner} className="w-[40px] " />
                <div className="flex-1">
                  <h4>{isSingleCampaign?.label}</h4>
                  <p>{isSingleCampaign?.content?.para}</p>
                </div>
                {!currentCampaignDetails && (
                  <button
                    className="flex gap-2 text-[#0894FA]"
                    onClick={() => setCreateCampaignModal(true)}
                  >
                    <MdModeEdit color="#0894FA" />
                    Change
                  </button>
                )}
              </div>

              <div className="flex flex-col">
                <label htmlFor="">Campaign Name</label>
                <input
                  type="text"
                  className={`p-2 mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
                  value={campaignDetails?.campaignName}
                  onChange={(e) =>
                    setCampaignDetials((pre) => ({
                      ...pre,
                      campaignName: e?.target?.value,
                    }))
                  }
                />
              </div>
              {/* <div>
                <Switch
                  checked={params.row.status == "PAUSED" ? false : true}
                /> */}
              <FormControlLabel
                value=""
                control={
                  <Switch
                    color="primary"
                    size="medium"
                    checked={campaignDetails?.status}
                    onChange={(e) =>
                      setCampaignDetials((pre) => ({
                        ...pre,
                        status: e?.target?.checked,
                      }))
                    }
                  />
                }
                label="Status"
                labelPlacement="top"
                sx={{
                  margin: "0px",
                  marginTop: `10px`,
                }}
              />
              {/* </div> */}
              <Box
                sx={{
                  ...darkModeColors,
                  "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                    {
                      right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                      transformOrigin: isLangRTL(i18n.language)
                        ? "right"
                        : "left",
                    },
                  "& legend": {
                    textAlign: isLangRTL(i18n.language) ? "right" : "left",
                  },
                }}
              >
                <div className="grid grid-cols-2 gap-6 items-center py-4">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={localData?.startDate || null}
                      label={t("start_date")}
                      views={["day", "month", "year"]}
                      onChange={(val) =>
                        setLocalData((pre) => ({ ...pre, startDate: val }))
                      }
                      format="DD-MM-YYYY"
                      minDate={dayjs()} // Restrict to future dates
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            "& input": {
                              color: currentMode === "dark" ? "white" : "black",
                            },
                            "& .MuiSvgIcon-root": {
                              color: currentMode === "dark" ? "white" : "black",
                            },
                            // "& .MuiOutlinedInput-notchedOutline": {
                            //   borderColor:
                            //     fieldErrors?.date === true &&
                            //     "#DA1F26 !important",
                            // },
                            // marginBottom: "20px",
                          }}
                          fullWidth
                          // size="small"
                          {...params}
                          onKeyDown={(e) => e.preventDefault()}
                          readOnly={true}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Select time"
                      value={localData?.startTime || null}
                      onChange={(newValue) => {
                        setLocalData((pre) => ({
                          ...pre,
                          startTime: newValue,
                        }));
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox checked={checked} onChange={handleChange} />
                    }
                    label="Set an End Date (Optional)"
                  />
                </div>
                {checked && (
                  <div className="grid grid-cols-2 gap-6 items-center py-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={localData?.endDate || null}
                        label={t("end_date")}
                        views={["day", "month", "year"]}
                        onChange={(val) =>
                          setLocalData((pre) => ({ ...pre, endDate: val }))
                        }
                        format="DD-MM-YYYY"
                        minDate={localData?.startDate || dayjs()} // End date should be after start date
                        disabled={!localData?.startDate}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              "& input": {
                                color:
                                  currentMode === "dark" ? "white" : "black",
                              },
                              "& .MuiSvgIcon-root": {
                                color:
                                  currentMode === "dark" ? "white" : "black",
                              },
                              // "& .MuiOutlinedInput-notchedOutline": {
                              //   borderColor:
                              //     fieldErrors?.date === true &&
                              //     "#DA1F26 !important",
                              // },
                              // marginBottom: "20px",
                            }}
                            fullWidth
                            // size="small"
                            {...params}
                            onKeyDown={(e) => e.preventDefault()}
                            readOnly={true}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Select time"
                        value={localData?.endTime || null}
                        onChange={(newValue) => {
                          setLocalData((pre) => ({
                            ...pre,
                            endTime: newValue,
                          }));
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                )}
              </Box>
              <div className="flex flex-col gap-7 py-5">
                <div className="flex flex-col">
                  <label htmlFor="">Daily Spend Cap</label>
                  <div className="relative w-full">
                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                      $
                    </span>
                    <input
                      type="number"
                      className={`pl-6 p-2 py-3 w-full mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA]`}
                      value={campaignDetails?.dailySpendCap}
                      placeholder="No cap"
                      onChange={(e) =>
                        setCampaignDetials((pre) => ({
                          ...pre,
                          dailySpendCap: e?.target?.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="">Lifetime Spend Cap</label>
                  <div className="relative w-full">
                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                      $
                    </span>
                    <input
                      type="number"
                      className={`pl-6 p-2 py-3 w-full mt-[2px] ${getSummaryBgClass()} rounded-lg focus:outline-none  border border-[#F9F9FA] hover:border-[#F7D901] focus:border-b-[2px] focus:border-b-[#F7D901] focus:border-[#F9F9FA] `}
                      value={campaignDetails?.lifeTimeSpendCap}
                      placeholder="No cap"
                      onChange={(e) =>
                        setCampaignDetials((pre) => ({
                          ...pre,
                          lifeTimeSpendCap: e?.target?.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`flex justify-end py-5 ${getSummaryBgClass()} shadow-md px-4 mt-3`}
            >
              {!currentCampaignDetails && (
                <button
                  onClick={() => createCampaign()}
                  // className="p-3 px-5 hover:border-[#F7D901] rounded-[20px] border font-semibold bg-white"
                  className={`p-3 px-5 hover:border-[#F7D901] rounded-[20px] border disabled:cursor-not-allowed font-semibold ${
                    currentMode == "dark" ? "bg-black text-white" : "bg-white"
                  } `}
                  disabled={
                    !campaignDetails?.campaignName ||
                    !localData?.startDate ||
                    !localData?.startTime
                    //  ||
                    // (checked && (!localData?.endDate || !localData?.endTime))
                  }
                >
                  Next
                </button>
              )}
              {currentCampaignDetails && (
                <button
                  style={
                    isChangesOccured
                      ? { backgroundColor: "black", color: "white" }
                      : currentMode === "dark"
                      ? { backgroundColor: "#282b30", color: "white" }
                      : stripeStyle
                  }
                  // className="p-3 px-5 hover:border-[#F7D901] rounded-[20px] border font-semibold bg-white"
                  className={`p-3 px-5 hover:border-[#F7D901] rounded-[20px] border font-semibold ${
                    currentMode == "dark" ? "bg-black text-white" : "bg-white"
                  } disabled:cursor-not-allowed `}
                  disabled={!isChangesOccured}
                  onClick={() => updateCampaign()}
                >
                  Save Changes
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SingleCampaign;

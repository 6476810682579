import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  Switch,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStateContext } from "../../../context/ContextProvider";
import Select from "react-select";
import { selectStyles } from "../../../Components/_elements/SelectStyles";
import { toast } from "react-toastify";
import axios from "../../../axoisConfig";
import {
  currencies,
  listing_options,
  listing_status,
} from "../../_elements/SelectOptions";
import { CiCirclePlus } from "react-icons/ci";
import { FaPlus } from "react-icons/fa";
import LocationCheckBox from "./LocationCheckbox";
import AddNearByLocaModal from "./AddNearByLocaModal";

const AddListingNearBy = ({
  listData,
  listingIds,
  setListingIDs,
  handleNext,
  edit,
  handleClose,
  fetchSingleListing,
  FetchListings,
  listingData,
  setListingData,
}) => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    isArabic,
    primaryColor,
    t,
    isLangRTL,
    i18n,
    fontFam,
    themeBgImg,
  } = useStateContext();

  const token = localStorage.getItem("auth-token");

  const [loading, setLoading] = useState(false);
  const [nearByLocations, setNearByLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [nearByModal, setNearByModal] = useState(false);
  const handleCloseNearByModal = () => setNearByModal(false);

  const handleNearByLocaModal = () => {
    setNearByModal(true);
  };

  const fetchLocations = () => {
    setLoading(true);

    axios
      .get(`${BACKEND_URL}/nearby`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        console.log("near by location list : ", result);
        setNearByLocations(result?.data?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        console.log(err);
        const errors = err.response?.data?.errors;

        if (errors) {
          const errorMessages = Object.values(errors).flat().join(" ");
          toast.error(`Errors: ${errorMessages}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Unable to fetch locations", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  const AddListMeta = () => {
    setBtnLoading(true);

    const formData = new FormData();

    Object.keys(listingData)?.forEach((key) =>
      formData.append(key, listingData[key])
    );

    console.log("sending meta data:: ", listingData);

    let url = listData
      ? `${BACKEND_URL}/listings/${listData?.id}`
      : `${BACKEND_URL}/listings`;

    axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        console.log("listing  added : ", result);
        setBtnLoading(false);

        toast.success(`Listing updated successfully.`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        handleClose();
        fetchSingleListing();
        FetchListings(1);
      })
      .catch((err) => {
        console.error(err);
        setBtnLoading(false);
        console.log(err);
        const errors = err.response?.data?.data;

        if (errors) {
          const errorMessages = Object.values(errors).flat().join(" ");
          toast.error(`Errors: ${errorMessages}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Something Went Wrong! Please Try Again", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  return (
    <div className="my-4" style={{ marginTop: "20px" }}>
      {/* <div className="relative flex items-center justify-center my-3 space-y-14">
        <h4 className="absolute  left-0 right-0 text-primary text-center font-semibold pb-5">
          {t("heading_near_by_locations")}
        </h4>
        <Button
          sx={{ mt: 1 }}
          variant="contained"
          className="bg-btn-primary flex items-center gap-x-3 px-3"
          size="small"
          onClick={handleNearByLocaModal}
          style={{ position: "absolute", right: 0 }}
        >
          <FaPlus size={16} />
          {t("add_near_by_loc")}
        </Button>
      </div> */}

      <h4 className=" text-primary text-center font-semibold pb-5">
        {t("heading_near_by_locations")}
      </h4>

      <Button
        sx={{ mt: 1 }}
        variant="contained"
        className="bg-btn-primary flex items-center gap-x-3 px-3"
        size="small"
        onClick={handleNearByLocaModal}
        style={{
          position: "absolute",
          right: 20,
          top: isLangRTL(i18n.language) ? 60 : listData?.nearby ? 60 : 90,
        }}
      >
        <FaPlus size={16} />
        {t("add_near_by_loc")}
      </Button>

      {loading ? (
        <div className="w-full h-[400px] flex justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
        // <div className="grid sm:grid-cols-1   md:grid-cols-2 lg:grid-cols-2 gap-3">

        <div className="grid sm:grid-cols-1   md:grid-cols-4 lg:grid-cols-5 gap-x-3 mt-5">
          {nearByLocations?.length > 0 ? (
            nearByLocations?.map((location) => (
              <LocationCheckBox
                key={location?.id}
                location={location}
                selectedLocations={selectedLocations}
                setSelectedLocations={setSelectedLocations}
                listingData={listingData}
                setListingData={setListingData}
              />
            ))
          ) : (
            <p>{t("no_data_found")}</p>
          )}
        </div>

        // </div>
      )}
      {listData ? (
        <Button
          className={`w-full text-white rounded-md py-3 font-semibold disabled:opacity-50 disabled:cursor-not-allowed hover:shadow-none`}
          ripple={true}
          style={{
            fontFamily: fontFam,
            background: `${primaryColor}`,
          }}
          size="lg"
          type="submit"
          disabled={btnLoading ? true : false}
          sx={{ marginTop: "20px" }}
          onClick={AddListMeta}
        >
          {btnLoading ? (
            <CircularProgress
              size={20}
              sx={{ color: "white" }}
              className="text-white"
            />
          ) : (
            <span className="text-white">
              {listData ? t("update") : t("submit")}
            </span>
          )}
        </Button>
      ) : null}
      {nearByModal && (
        <AddNearByLocaModal
          nearByModal={nearByModal}
          setNearByModal={setNearByModal}
          fetchLocations={fetchLocations}
          handleCloseNearByModal={handleCloseNearByModal}
        />
      )}
    </div>
  );
};

export default AddListingNearBy;

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  Switch,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStateContext } from "../../../context/ContextProvider";
import Select from "react-select";
import { selectStyles } from "../../../Components/_elements/SelectStyles";
import { toast } from "react-toastify";
import axios from "../../../axoisConfig";
import {
  currencies,
  listing_options,
  listing_status,
} from "../../_elements/SelectOptions";
import { CiCirclePlus } from "react-icons/ci";
import { FaPlus } from "react-icons/fa";

const Addlisting = ({
  listData,
  listingIds,
  setListingIDs,
  handleNext,
  edit,
  handleClose,
  fetchSingleListing,
  listingData,
  setListingData,
}) => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    isArabic,
    primaryColor,
    t,
    isLangRTL,
    i18n,
    fontFam,
    themeBgImg,
  } = useStateContext();

  console.log("list data::: ", listingData);

  const token = localStorage.getItem("auth-token");

  const [btnLoading, setBtnLoading] = useState(false);
  const [addBtnLoading, setAddBtnLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityLoading, setCityLoading] = useState(false);
  const [displayForm, setDisplayForm] = useState({
    isOpen: false,
    type: null,
  });

  console.log("disply form:: ", displayForm);

  const [countryData, setCountryData] = useState({
    name: null,
    code: null,
    country_id: null,
    state_id: null,
  });
  const [bannerPreview, setBannerPreview] = useState(null);

  // const [listingData, setlistingData] = useState({
  //   title: listData?.title || "",
  //   listing_type_id: edit
  //     ? listData?.listing_type?.id
  //     : listingIds?.listing_type_id,
  //   listing_attribute_id: edit
  //     ? listData?.listing_attribute?.id
  //     : listingIds?.listing_attribute_id,
  //   listing_arrtibute_type_id: edit
  //     ? listData?.listing_attribute_type?.id
  //     : listingIds?.listing_arrtibute_type_id,
  //   country_id: listData?.country?.id || "",
  //   state_id: listData?.state?.id || "",
  //   city_id: listData?.city?.id || "",
  //   short_description: listData?.short_description || "",
  //   status: listData?.status || "",
  // });

  const handleChange = (e) => {
    setListingData((prevListingAttr) => ({
      ...prevListingAttr,
      [e.target.id]: e.target.value,
    }));
  };

  const handleData = (e) => {
    setCountryData({
      ...countryData,
      [e.target.id]: e.target.value,
    });
  };

  const handleCloseModal = () => {
    setDisplayForm({ isOpen: false, type: null });
  };

  const handleVideoUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Check if the file is an MP4
      const isMp4 = file.type === "video/mp4";
      if (!isMp4) {
        toast.error(`Only mp4 video type is allowed`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      // Check if the file size is less than or equal to 1MB
      const isFileSizeValid = file.size <= 2 * 1024 * 1024; // 1MB in bytes
      if (!isFileSizeValid) {
        toast.error(`File size must be under 1mb.`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      setListingData({
        ...listingData,
        promo_video: file,
      });
    }
  };

  return (
    <div className="my-4">
      <h4 className={`text-primary text-center font-semibold pb-5`}>
        {t("heading_add_listing")}
      </h4>

      <div className="grid sm:grid-cols-1   md:grid-cols-2 lg:grid-cols-2 gap-x-10">
        <Box
          sx={{
            ...darkModeColors,
            "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
              {
                right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                transformOrigin: isLangRTL(i18n.language) ? "right" : "left",
              },
            "& legend": {
              textAlign: isLangRTL(i18n.language) ? "right" : "left",
            },
          }}
        >
          <TextField
            id="listing_title"
            type={"text"}
            label={t("title")}
            className="w-full"
            sx={{
              marginBottom: "20px !important",
            }}
            variant="outlined"
            size="small"
            value={listingData?.listing_title}
            name="projectLocation"
            onChange={handleChange}
            required
          />

          <TextField
            id="description"
            type={"text"}
            label={t("description")}
            className="w-full"
            sx={{
              marginBottom: "20px !important",
            }}
            variant="outlined"
            size="small"
            value={listingData?.description}
            name="area"
            onChange={handleChange}
            multiline
            rows={4}
            required
          />

          <div>
            <FormControlLabel
              sx={{
                marginBottom: "20px",
              }}
              control={
                <Switch
                  size="small"
                  defaultChecked={
                    listingData?.listing_status == 1 ? true : false
                  }
                  value={listingData?.listing_status}
                  onChange={(e) => {
                    const value = e.target.value;
                    const isSelected = value == 1 ? 2 : 1;
                    setListingData({
                      ...listingData,
                      listing_status: isSelected,
                    });
                  }}
                  sx={{
                    // marginBottom: "20px",
                    color: "green !important",
                    "& .MuiSwitch-thumb": {
                      color:
                        listingData?.listing_status == 1
                          ? "green !important"
                          : "#B91C1C !important",
                    },
                    "& .Mui-checked": {
                      color:
                        listingData?.listing_status == 1
                          ? "green !important"
                          : "#B91C1C !important",
                    },
                    "& .MuiSwitch-track": {
                      backgroundColor:
                        listingData?.listing_status == 1
                          ? "green !important"
                          : "#B91C1C !important",
                    },
                    "& .css-1q0bjt2-MuiSwitch-root .MuiSwitch-thumb": {
                      backgroundColor:
                        listingData?.listing_status == 1
                          ? "green !important"
                          : "#B91C1C !important",
                    },
                  }}
                />
              }
              label={t("status")}
            />

            <FormControlLabel
              sx={{
                marginBottom: "20px",
              }}
              control={
                <Switch
                  size="small"
                  defaultChecked={listingData?.is_featured == 1 ? true : false}
                  value={listingData?.is_featured}
                  // checked={listingData?.is_featured === 1}
                  onChange={(e) => {
                    const value = e.target.value;
                    const isSelected = value == 1 ? 2 : 1;
                    setListingData({
                      ...listingData,
                      is_featured: isSelected,
                    });
                  }}
                  sx={{
                    // marginBottom: "20px",
                    color: "green !important",
                    "& .MuiSwitch-thumb": {
                      color:
                        listingData?.is_featured == 1
                          ? "green !important"
                          : "#B91C1C !important",
                    },
                    "& .Mui-checked": {
                      color:
                        listingData?.is_featured == 1
                          ? "green !important"
                          : "#B91C1C !important",
                    },
                    "& .MuiSwitch-track": {
                      backgroundColor:
                        listingData?.is_featured == 1
                          ? "green !important"
                          : "#B91C1C !important",
                    },
                    "& .css-1q0bjt2-MuiSwitch-root .MuiSwitch-thumb": {
                      backgroundColor:
                        listingData?.is_featured == 1
                          ? "green !important"
                          : "#B91C1C !important",
                    },
                  }}
                />
              }
              label={t("label_is_featured")}
            />

            {/* <FormControlLabel
              sx={{ marginBottom: "30px" }}
              control={
                <Checkbox
                  value={listingData?.is_featured}
                  onClick={(e) => {
                    const value = e.target.value;
                    const isTrue = value == 0 ? 1 : 0;
                    setListingData({
                      ...listingData,
                      is_featured: isTrue,
                    });
                  }}
                  fullWidth
                  inputProps={{ "aria-label": "controlled" }}
                  style={{
                    color: currentMode === "dark" ? "#fff" : "#000",
                  }}
                />
              }
              label={t("label_is_featured")}
            /> */}
          </div>

          {listData ? null : (
            <>
              {bannerPreview ? (
                <div className="w-max rounded-lg border">
                  <img
                    src={bannerPreview}
                    width="100px"
                    height="100px"
                    alt="Banner Preview"
                  />
                </div>
              ) : null}

              <input
                accept="image/*"
                style={{ display: "none" }}
                id="banner-image-file"
                type="file"
                name="picture"
                onChange={(e) => {
                  console.log("event of banner image: ", e);

                  const image = e.target.files[0]; // Single file selection
                  const reader = new FileReader();

                  reader.onload = () => {
                    setBannerPreview(reader.result); // Set the preview URL for the single image
                    setListingData({
                      ...listingData,
                      banner_img: image, // Store the selected image in the state
                    });
                  };

                  if (image) {
                    reader.readAsDataURL(image); // Read the image file and trigger the onload event
                  }
                }}
              />

              <label htmlFor="banner-image-file">
                <Button
                  variant="contained"
                  size="lg"
                  className="bg-main-red-color w-full bg-btn-primary text-white rounded-lg py-3 border-primary font-semibold my-3"
                  style={{
                    fontFamily: fontFam,
                    color: "#ffffff",
                    // marginTop: "px",
                  }}
                  component="span"
                >
                  <span>{t("label_banner")}</span>
                </Button>
                <p className="text-primary mt-2 italic">
                  {listingData?.banner_img ? `Banner image selected.` : null}
                </p>
              </label>
            </>
          )}

          {listData ? null : (
            <>
              <input
                accept="video/*"
                style={{ display: "none" }}
                id="promo-video-file"
                type="file"
                name="video"
                onChange={handleVideoUpload}
              />
              <label htmlFor="promo-video-file">
                <Button
                  variant="contained"
                  size="lg"
                  className="bg-main-red-color w-full bg-btn-primary text-white rounded-lg py-3 border-primary font-semibold "
                  style={{
                    fontFamily: fontFam,
                    color: "#ffffff",
                    // marginBottom: "20px",
                  }}
                  component="span"
                >
                  <span>{t("label_promo_video")}</span>
                </Button>
                <p className="text-primary mt-2 italic">
                  {listingData?.promo_video ? `Promo video selected.` : null}
                </p>
              </label>
            </>
          )}

          {/* 
          <div className="flex items-center space-x-2">
            <Select
              id="country_id"
              value={{
                value: listingData?.country_id,
                label: listingData?.country_id
                  ? countryList?.find(
                      (cont) => cont.id === listingData?.country_id
                    )?.name || ""
                  : t("label_country"),
              }}
              onChange={(e) => {
                setListingData({
                  ...listingData,
                  country_id: e.value,
                });
              }}
              options={countryList?.map((cont) => ({
                value: cont.id,
                label: cont.name,
              }))}
              isLoading={userLoading}
              className="w-full"
              placeholder={t("label_country")}
              menuPortalTarget={document.body}
              styles={selectStyles(currentMode, primaryColor)}
            />
            {displayForm?.isOpen === false ? (
              <IconButton
                onClick={() =>
                  setDisplayForm({
                    type: "country",
                    isOpen: true,
                  })
                }
                className="rounded bg-btn-primary "
              >
                <CiCirclePlus color="#fff" size={22} />
              </IconButton>
            ) : null}
          </div>

          <div className="flex items-center space-x-2">
            <Select
              id="state"
              value={{
                value: listingData?.state_id,
                label: listingData?.state_id
                  ? stateList?.find((cont) => cont.id === listingData?.state_id)
                      ?.name || ""
                  : t("state"),
              }}
              onChange={(e) => {
                setListingData({
                  ...listingData,
                  state_id: e.value,
                });
              }}
              isLoading={cityLoading}
              options={stateList?.map((cont) => ({
                value: cont.id,
                label: cont.name,
              }))}
              className="w-full"
              placeholder={t("state")}
              menuPortalTarget={document.body}
              styles={selectStyles(currentMode, primaryColor)}
            />

            <IconButton
              onClick={() =>
                setDisplayForm({
                  type: "state",
                  isOpen: true,
                })
              }
              className="rounded bg-btn-primary "
            >
              <CiCirclePlus color="#fff" size={22} />
            </IconButton>
          </div>
          <div className="flex items-center space-x-2">
            <Select
              id="city_id"
              value={{
                value: listingData?.city_id,
                label: listingData?.city_id
                  ? cityList?.find((cont) => cont.id === listingData?.city_id)
                      ?.name || ""
                  : t("label_city"),
              }}
              onChange={(e) => {
                setListingData({
                  ...listingData,
                  city_id: e.value,
                });
              }}
              isLoading={cityLoading}
              options={cityList?.map((cont) => ({
                value: cont.id,
                label: cont.name,
              }))}
              className="w-full"
              placeholder={t("label_city")}
              menuPortalTarget={document.body}
              styles={selectStyles(currentMode, primaryColor)}
            />
            <IconButton
              onClick={() =>
                setDisplayForm({
                  type: "city",
                  isOpen: true,
                })
              }
              className="rounded bg-btn-primary "
            >
              <CiCirclePlus color="#fff" size={22} />
            </IconButton>
          </div> */}
        </Box>
        <Box
          sx={{
            ...darkModeColors,
            "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
              {
                right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                transformOrigin: isLangRTL(i18n.language) ? "right" : "left",
              },
            "& legend": {
              textAlign: isLangRTL(i18n.language) ? "right" : "left",
            },
          }}
        >
          <Select
            id="type"
            value={listing_options(t)?.find(
              (list_type) =>
                list_type.value?.toLowerCase() ===
                listingData?.listing_type?.toLowerCase()
            )}
            onChange={(e) => {
              setListingData({
                ...listingData,
                listing_type: e.value,
              });
            }}
            options={listing_options(t)?.map((list_attr_type) => ({
              value: list_attr_type.value,
              label: list_attr_type.label,
            }))}
            className="w-full"
            placeholder={t("label_listing_type")}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          />

          <div className="grid grid-cols-3">
            <Select
              id="currency"
              options={currencies(t)?.map((curr) => ({
                value: curr.value,
                label: curr.label,
              }))}
              value={currencies(t)?.filter(
                (curr) => curr?.value === listingData?.currency
              )}
              onChange={(e) => {
                setListingData({
                  ...listingData,
                  currency: e.value,
                });
              }}
              placeholder={t("label_currency")}
              menuPortalTarget={document.body}
              // styles={getMergedStyles(
              //   fieldErrors.currency,
              //   selectStyles(currentMode, primaryColor)
              // )}
              styles={selectStyles(currentMode, primaryColor)}
            />

            <TextField
              type={"number"}
              // label={t("label_item_price")}
              className="w-full col-span-2"
              style={{
                marginBottom: "20px",
              }}
              placeholder={t("label_price")}
              variant="outlined"
              name="price"
              size="small"
              id="price"
              value={listingData?.price}
              onChange={handleChange}
            />
          </div>
          <div>
            <FormControlLabel
              sx={{
                marginBottom: "20px",
              }}
              control={
                <Switch
                  size="small"
                  defaultChecked={
                    listingData?.is_start_price == 1 ? true : false
                  }
                  value={listingData?.is_start_price}
                  onChange={(e) => {
                    const value = e.target.value;
                    const isSelected = value == 1 ? 2 : 1;
                    setListingData({
                      ...listingData,
                      is_start_price: isSelected,
                    });
                  }}
                  sx={{
                    // marginBottom: "20px",
                    color: "green !important",
                    "& .MuiSwitch-thumb": {
                      color:
                        listingData?.is_start_price == 1
                          ? "green !important"
                          : "#B91C1C !important",
                    },
                    "& .Mui-checked": {
                      color:
                        listingData?.is_start_price == 1
                          ? "green !important"
                          : "#B91C1C !important",
                    },
                    "& .MuiSwitch-track": {
                      backgroundColor:
                        listingData?.is_start_price == 1
                          ? "green !important"
                          : "#B91C1C !important",
                    },
                    "& .css-1q0bjt2-MuiSwitch-root .MuiSwitch-thumb": {
                      backgroundColor:
                        listingData?.is_start_price == 1
                          ? "green !important"
                          : "#B91C1C !important",
                    },
                  }}
                />
              }
              label={t("is_start_price")}
            />
          </div>
        </Box>
      </div>
      {/* <Button
        className={`w-full text-white rounded-md py-3 font-semibold disabled:opacity-50 disabled:cursor-not-allowed hover:shadow-none`}
        ripple={true}
        style={{
          fontFamily: fontFam,
          background: `${primaryColor}`,
        }}
        size="lg"
        type="submit"
        disabled={btnLoading ? true : false}
        onClick={AddListings}
      >
        {btnLoading ? (
          <CircularProgress
            size={20}
            sx={{ color: "white" }}
            className="text-white"
          />
        ) : (
          <span className="text-white">{t("submit")}</span>
        )}
      </Button> */}
    </div>
  );
};

export default Addlisting;

import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "react-toastify";
import moment from "moment";
import usePermission from "../../utils/usePermission";
import { useStateContext } from "../../context/ContextProvider";
import ReportProjectBar from "../../Components/charts/ReportProjectBar";
import ReportMeetingsClosed from "../../Components/charts/ReportMeetingsClosed";
import DoughnutChart from "../../Components/charts/DoughnutChart";
import ReportClosedMeetingDoughnut from "../../Components/charts/ReportClosedMeetingDoughnut";
import Loader from "../../Components/Loader";
import axios from "../../axoisConfig";
import SocialChart from "../../Components/charts/SocialChart";
import SaleBubbleChart from "../../Components/charts/SaleBubbleChart";

import { BiImport, BiArchive, BiMessageRoundedDots } from "react-icons/bi";
import {
  BsSnow2,
  BsPersonCircle,
  BsInstagram,
  BsFacebook,
  BsSnapchat,
  BsTiktok,
  BsYoutube,
  BsTwitter,
  BsMegaphone,
  BsWhatsapp,
  BsChatDots,
  BsChatLeftText,
  BsGlobe2,
  BsLink45Deg,
  BsDownload,
  BsArchive,
  BsPersonRolodex,
} from "react-icons/bs";
import {
  FaFacebookF,
  FaSnapchatGhost,
  FaTiktok,
  FaYoutube,
  FaTwitter,
  FaWhatsapp,
  FaRegComments,
  FaHandshake,
  FaWallet,
  FaUserTie,
  FaCalendarCheck,
  FaInstagram,
  FaGoogle,
  FaRegHandshake,
  FaRegUser,
  FaRegCalendarAlt,
} from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { GiMagnifyingGlass } from "react-icons/gi";
import { MdCampaign } from "react-icons/md";
import { TbWorldWww } from "react-icons/tb";
import ReportPdfModal from "../../Components/ReportPdfModal";
import LineBarAreaChart from "../../Components/charts/LineBarAreaChart";
import HeadingTitle from "../../Components/_elements/HeadingTitle";
import BiaxleLineChart from "../../Components/charts/BiaxleLineChart";
import ActiveShapePieChart from "../../Components/charts/ActiveShapePieChart";
import StackedBarChart from "../../Components/charts/statisticsCharts/StackedBarChart";
import ProgressBarChart from "../../Components/charts/ProgressBarChart";
import SimpleBarChart from "../../Components/charts/SimpleBarChart";
import { IoWalletOutline } from "react-icons/io5";

const Reports = () => {
  const {
    currentMode,
    DashboardData,
    setDashboardData,
    setSales_chart_data,
    PYTHON_URL,
    t,
    themeBgImg,
    primaryColor,
    darkModeColors,
    BACKEND_URL,
  } = useStateContext();
  const [pageLoading, setPageLoading] = useState(true);

  const platforms = [
    {
      icon: <FaFacebookF />,
      count: 5,
      gradient: "bg-gradient-to-r from-blue-500 to-blue-300",
      source: "facebook",
    },
    {
      icon: <FaInstagram />,
      count: 5,
      gradient: "bg-gradient-to-r from-pink-500 to-pink-200",
      source: "instagram",
    },
    {
      icon: <FaTiktok />,
      count: 5,
      gradient: "bg-gradient-to-r from-gray-800 to-gray-400",
      source: "tiktok",
    },
    {
      icon: <FaSnapchatGhost />,
      count: 5,
      gradient: "bg-gradient-to-r from-yellow-400 to-yellow-200",
      source: "snapchat",
    },
    {
      icon: <FaYoutube />,
      count: 5,
      gradient: "bg-gradient-to-r from-red-500 to-red-300",
      source: "youtube",
    },
    {
      icon: <FaGoogle color="red" />,
      count: 5,
      gradient: "bg-white border",
      source: "google",
    },
  ];

  const [saleschart_loading, setsaleschart_loading] = useState(true);
  // const [loading, setLoading] = useState(false);
  const [socialChartData, setSocialChartData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedMonthSocial, setSelectedMonthSocial] = useState();
  const [selectedMonthProject, setSelectedMonthProject] = useState();
  const [selectedMonthSales, setSelectedMonthSales] = useState(null);
  const [counters, setCounter] = useState([]);
  const { hasPermission } = usePermission();
  const [countFilter, setCountFilter] = useState();
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [reportTabValue, setReportTabValue] = useState(0);
  const token = localStorage.getItem("auth-token");
  const [leadSourceFilter, setLeadSourceFilter] = useState(null);

  // NEW STATES
  const [reportsData, setReportsData] = useState({
    performance: [],
    closed_projects: [],
    leads_feedback: [],
    stats: {},
  });
  const [comparisonData, setComparisonData] = useState({
    deals: [],
    feedback: [],
  });
  const [targetData, setTargetData] = useState([]);
  const [loading, setLoading] = useState({
    reports: false,
    comparison: false,
    target: false,
    social_chart: false,
  });
  const [targetFilters, setTargetFilters] = useState({
    this_month: false,
    last_month: false,
  });
  const [reportFilters, setReportFilters] = useState({
    this_month: false,
    last_month: false,
    this_year: false,
    last_year: false,
  });

  const reportsRef = useRef(null);
  const comparisonRef = useRef(null);
  const targetRef = useRef(null);
  const sourceRef = useRef(null);

  const stats = [
    {
      title: "Deals Closed",
      value: reportsData?.stats?.deal_closed,
      icon: <FaRegHandshake size={40} />,
    },
    {
      title: "Amount Closed",
      value: reportsData?.stats?.amount_closed,
      icon: <IoWalletOutline size={40} />,
    },
    {
      title: "Star Consultants",
      value: reportsData?.stats?.star_consultants,
      icon: <FaRegUser size={40} />,
    },
    {
      title: "Attended Meetings",
      value: reportsData?.stats?.attended_meetings,
      icon: <FaRegCalendarAlt size={40} />,
    },
  ];

  const handleChange = (event, newValue) => {
    console.log("tab value: ", newValue);
    if (newValue == 0) {
      setTargetFilters({
        ...targetFilters,
        last_month: false,
        this_month: true,
      });
    } else if (newValue == 1) {
      setTargetFilters({
        ...targetFilters,
        this_month: false,
        last_month: true,
      });
    }
    setTabValue(newValue);
  };

  const handleFilterChanged = (event, newValue) => {
    console.log("tab value: ", newValue);
    if (newValue == 0) {
      setReportFilters({
        ...reportFilters,
        last_month: false,
        this_month: true,
      });
    } else if (newValue == 1) {
      setReportFilters({
        ...reportFilters,
        this_month: false,
        last_month: true,
      });
    } else if (newValue == 2) {
      setReportFilters({
        ...reportFilters,
        this_month: false,
        last_month: false,
        this_year: true,
      });
    } else if (newValue == 3) {
      setReportFilters({
        ...reportFilters,
        this_month: false,
        last_month: false,
        this_year: false,
        last_year: true,
      });
    }

    setReportTabValue(newValue);
  };

  console.log("report tab value : ", reportTabValue);

  const fetchReports = async () => {
    // if (
    //   reportsData?.performance?.length > 0 ||
    //   reportsData?.closed_projects?.length > 0 ||
    //   reportsData?.leads_feedback?.length > 0
    // )
    //   return;
    setLoading((prev) => ({ ...prev, reports: true }));

    let params = {
      year: moment().format("YYYY"),
      month: moment().format("M"),
    };
    if (reportFilters?.last_month) {
      params = {};
      params.year = moment().format("YYYY");
      params.month = moment().subtract(1, "months").format("M"); // 'M' for month without leading zero
    } else if (reportFilters?.this_month) {
      params = {};

      params.year = moment().format("YYYY");
      params.month = moment().format("M");
    } else if (reportFilters?.this_year) {
      params = {};

      params.year = moment().format("YYYY");
    } else if (reportFilters?.last_year) {
      params = {};

      params.year = moment().subtract(1, "year").format("YYYY");
    }
    try {
      const urls = [
        `${PYTHON_URL}/performance`,
        `${PYTHON_URL}/close_deals_projects`,
        `${PYTHON_URL}/feedback_report`,
      ];

      const responses = await Promise.all(
        urls.map((url) => {
          return axios.post(url, params, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          });
        })
      );

      console.log("reports section apis : ", responses);

      const performance = responses[0]?.data?.data || [];
      const closed_projects = responses[1]?.data?.data;
      const leads_feedback = responses[2]?.data?.data?.entries;

      // STATS
      let totalDealsSum = 0;
      let totalAmountSum = 0;
      let totalMeetingsSum = 0;

      // Calculate sums
      performance?.forEach((item) => {
        totalDealsSum += item?.totalDeals || 0;
        totalAmountSum += Number(item?.amount) || 0;
        totalMeetingsSum += item?.totalMeetings || 0;
      });

      console.log("total amount : ", totalAmountSum);

      totalAmountSum = Number(totalAmountSum).toFixed(2);

      const stats_data = {
        star_consultants: performance?.length,
        amount_closed: totalAmountSum,
        deal_closed: totalDealsSum,
        attended_meetings: totalMeetingsSum,
      };

      setReportsData({
        ...reportsData,
        performance: performance,
        closed_projects: closed_projects,
        leads_feedback: leads_feedback,
        stats: stats_data,
      });

      setLoading((prev) => ({ ...prev, reports: false }));
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);

      console.log(error);
      setLoading((prev) => ({ ...prev, reports: false }));
      toast.error("Unable to fetch reports.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const fetchComparison = async () => {
    if (
      comparisonData?.deals?.length > 0 &&
      comparisonData?.feedback?.length > 0
    )
      return;
    setLoading((prev) => ({ ...prev, comparison: true }));

    let params;

    try {
      const urls = [
        `${PYTHON_URL}/monthly_deals`,
        `${PYTHON_URL}/feedback_count`,
      ];

      const responses = await Promise.all(
        urls.map((url) => {
          if (url.includes("/monthly_deals")) {
            // Use GET request for the monthly_deals endpoint
            return axios.get(url, {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
              params: params, // Add params if needed for the GET request
            });
          } else {
            // Use POST request for other endpoints
            return axios.post(
              url,
              { coldcall: 0 },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                },
              }
            );
          }
        })
      );

      console.log("comparison section apis : ", responses);

      const deals = responses[0]?.data?.data;
      const feedback = responses[1]?.data?.data;

      setComparisonData({
        ...comparisonData,
        deals: deals,
        feedback: feedback,
      });
      setLoading((prev) => ({ ...prev, comparison: false }));
    } catch (error) {
      console.log(error);
      setLoading((prev) => ({ ...prev, comparison: false }));
      toast.error("Unable to fetch comparison apis.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const fetchTarget = async () => {
    if (
      !targetFilters?.this_month &&
      !targetFilters?.last_month &&
      targetData?.length > 0
    )
      return;
    setLoading((prev) => ({ ...prev, target: true }));

    let params = {
      year: moment().format("YYYY"),
      month: moment().format("M"),
    };

    if (targetFilters?.last_month) {
      params.year = moment().format("YYYY");
      params.month = moment().subtract(1, "months").format("M"); // 'M' for month without leading zero
    } else if (targetFilters?.this_month) {
      params.year = moment().format("YYYY");
      params.month = moment().format("M");
    }

    try {
      const response = await axios.post(
        `${PYTHON_URL}/monthly_target`,
        params,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      console.log("target section apis : ", response);
      setTargetData(response?.data?.data);

      setLoading((prev) => ({ ...prev, target: false }));
    } catch (error) {
      console.log(error);
      setLoading((prev) => ({ ...prev, target: false }));
      toast.error("Unable to fetch target apis.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const fetchSocialChart = async () => {
    if (leadSourceFilter === null && socialChartData?.length > 0) return;
    setLoading((prev) => ({ ...prev, social_source: true }));

    let params = {};
    const currentDate = moment(countFilter).format("YYYY-MM-DD");

    if (leadSourceFilter) {
      params.date = leadSourceFilter;
    } else {
      params.date = currentDate;
    }

    try {
      const responses = await axios.get(`${BACKEND_URL}/totalSource`, {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      console.log("social chart response: ", responses);

      // const data = responses?.data?.social_chart;
      const data = responses?.data?.data?.query_result;

      const filterData = platforms?.map((platform) => {
        console.log("platform: ", platform);
        const filteredItems = data?.filter((item) => {
          const name = item?.leadSource
            ?.toLowerCase()
            ?.replace("campaign ", "");
          return name === platform?.source;
        });

        // Log the filtered items to see what's being passed to reduce
        console.log("Filtered Items for platform:", platform, filteredItems);

        const total = filteredItems?.reduce((sum, item) => {
          console.log("sum ,item: ", sum, item); // This should now log if there are filtered items

          return sum + (item?.total || 0);
        }, 0);

        return {
          leadSource: platform,
          total,
        };
      });

      console.log("filter DAta of social chart: ", filterData);

      // setSocialChartData(filterData);
      setSocialChartData(data);
      setLoading((prev) => ({ ...prev, social_source: false }));
    } catch (error) {
      setLoading((prev) => ({ ...prev, social_source: false }));

      console.log(error);
      toast.error("Unable to fetch social chart api.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    fetchReports(); // Initial load for the reports section

    const observer = new IntersectionObserver(
      (entries) => {
        entries?.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target === comparisonRef.current) {
              fetchComparison();
            } else if (entry.target === targetRef.current) {
              fetchTarget();
            } else if (entry.target === sourceRef.current) {
              fetchSocialChart();
            }
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the section is visible
    );

    if (comparisonRef.current) {
      observer.observe(comparisonRef.current);
    }
    if (targetRef.current) {
      observer.observe(targetRef.current);
    }
    if (sourceRef.current) {
      observer.observe(sourceRef.current);
    }

    return () => {
      if (comparisonRef.current) observer.unobserve(comparisonRef.current);
      if (targetRef.current) observer.unobserve(targetRef.current);
      if (sourceRef.current) observer.unobserve(sourceRef.current);
    };
  }, []);

  useEffect(() => {
    if (leadSourceFilter) {
      fetchSocialChart();
    } else if (targetFilters?.this_month || targetFilters?.last_month) {
      fetchTarget();
    } else if (
      reportFilters?.this_month ||
      reportFilters?.last_month ||
      reportFilters?.this_year ||
      reportFilters?.last_year
    ) {
      fetchReports();
    }
  }, [leadSourceFilter, targetFilters, reportTabValue]);

  const sourceCounters = [
    {
      Facebook: {
        icon: <BsFacebook size={20} color={"white"} />,
        bg: "#0E82E1",
        gradient: "bg-gradient-to-r from-blue-500 to-blue-300",
      },
    },
    {
      Instagram: {
        icon: <BsInstagram size={20} color={"white"} />,
        bg: "#BE238D",
        gradient: "bg-gradient-to-r from-pink-500 to-pink-200",
      },
    },
    {
      Snapchat: {
        icon: <BsSnapchat size={18} color={"white"} />,
        bg: "#EDBD34", //"#F6D80A",
        gradient: "bg-gradient-to-r from-yellow-400 to-yellow-200",
      },
    },
    {
      TikTok: {
        icon: <BsTiktok size={18} color={"white"} />,
        bg: "#000000",
        gradient: "bg-gradient-to-r from-gray-800 to-gray-400",
      },
    },
    {
      YouTube: {
        icon: <BsYoutube size={20} color={"white"} />,
        bg: "#C4302B",
        gradient: "bg-gradient-to-r from-red-500 to-red-300",
      },
    },
    {
      GoogleAds: {
        icon: <FcGoogle size={20} />,
        bg: currentMode === "dark" ? "#000000" : "#FFFFFF",
        gradient: "bg-white border",
      },
    },
    // {
    //   Twitter: {
    //     icon: <BsTwitter size={20} color={"white"} />,
    //     bg: "#00ACEE",
    //   },
    // },
    // {
    //   Campaign: {
    //     icon: <BsMegaphone size={20} color={"white"} />,
    //     bg: "#696969",
    //   },
    // },
    // {
    //   WhatsApp: {
    //     icon: <BsWhatsapp size={20} color={"white"} />,
    //     bg: "#53CC60",
    //   },
    // },
    // {
    //   Message: {
    //     icon: <BsChatDots size={20} color={"white"} />,
    //     bg: "#6A5ACD",
    //   },
    // },
    // {
    //   Comment: {
    //     icon: <BsChatLeftText size={20} color={"white"} />,
    //     bg: "#A9B3C6",
    //   },
    // },
    // {
    //   Website: {
    //     icon: <BsGlobe2 size={20} color={"white"} />,
    //     bg: "#AED6F1",
    //   },
    // },
    // {
    //   "Property Finder": {
    //     icon: <BsLink45Deg size={22} color={"white"} />,
    //     bg: "#EF5E4E",
    //   },
    // },
    // {
    //   "Bulk Import": {
    //     icon: <BsDownload size={20} color={"white"} />,
    //     bg: primaryColor,
    //   },
    // },
    // {
    //   Warm: {
    //     icon: <BsArchive size={20} color={"white"} />,
    //     bg: "#AEC6CF",
    //   },
    // },
    // {
    //   Cold: {
    //     icon: <BsSnow2 size={20} color={"white"} />,
    //     bg: "#0EC7FF",
    //   },
    // },
    // {
    //   Personal: {
    //     icon: <BsPersonRolodex size={20} color={"white"} />,
    //     bg: "#6C7A89",
    //   },
    // },
    {
      Twitter: {
        icon: <BsTwitter size={20} color={"white"} />,
        gradient: "bg-gradient-to-r from-[#00ACEE] to-[#78CFF9]",
      },
    },
    {
      Campaign: {
        icon: <BsMegaphone size={20} color={"white"} />,
        gradient: "bg-gradient-to-r from-[#696969] to-[#A9A9A9]",
      },
    },
    {
      WhatsApp: {
        icon: <BsWhatsapp size={20} color={"white"} />,
        gradient: "bg-gradient-to-r from-[#53CC60] to-[#A2E3A8]",
      },
    },
    {
      Message: {
        icon: <BsChatDots size={20} color={"white"} />,
        gradient: "bg-gradient-to-r from-[#6A5ACD] to-[#9F93F5]",
      },
    },
    {
      Comment: {
        icon: <BsChatLeftText size={20} color={"white"} />,
        gradient: "bg-gradient-to-r from-[#A9B3C6] to-[#D4D9E6]",
      },
    },
    {
      Website: {
        icon: <BsGlobe2 size={20} color={"white"} />,
        gradient: "bg-gradient-to-r from-[#AED6F1] to-[#D9ECF9]",
      },
    },
    {
      "Property Finder": {
        icon: <BsLink45Deg size={22} color={"white"} />,
        gradient: "bg-gradient-to-r from-[#EF5E4E] to-[#F8A39B]",
      },
    },
    {
      "Bulk Import": {
        icon: <BsDownload size={20} color={"white"} />,
        gradient: "bg-gradient-to-r from-[#primaryColor] to-[#secondaryShade]",
      },
    },
    {
      Warm: {
        icon: <BsArchive size={20} color={"white"} />,
        gradient: "bg-gradient-to-r from-[#AEC6CF] to-[#D0E4E9]",
      },
    },
    {
      Cold: {
        icon: <BsSnow2 size={20} color={"white"} />,
        gradient: "bg-gradient-to-r from-[#0EC7FF] to-[#8CE3FF]",
      },
    },
    {
      Personal: {
        icon: <BsPersonRolodex size={20} color={"white"} />,
        gradient: "bg-gradient-to-r from-[#6C7A89] to-[#A0AAB6]",
      },
    },
  ];

  const fetchCounter = async () => {
    const currentDate = moment(countFilter).format("YYYY-MM-DD");
    try {
      const callCounter = await axios.get(
        `${BACKEND_URL}/totalSource?date=${currentDate}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      console.log("counter===> :", callCounter);

      setCounter(callCounter?.data?.data?.query_result);
    } catch (error) {
      console.log("Error::: ", error);
      toast.error("Unable to fetch count.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // if (pageLoading) {
  //   return <Loader />;
  // } else {
  return (
    <>
      <div className="flex min-h-screen">
        <div
          className={`w-full ${
            !themeBgImg &&
            (currentMode === "dark" ? "bg-[#1C1C1C]" : "bg-[#EEEEEE]")
          } ${currentMode === "dark" ? "text-white" : "text-black"} `}
        >
          <div className="w-full flex justify-end mt-4 mr-5">
            <IconButton
              sx={{
                marginRight: "10px",
              }}
              className="bg-btn-primary mr-3"
              onClick={() => setOpenPdfModal(true)}
            >
              <BsDownload size={16} color={"#FFFFFF"} />
            </IconButton>
          </div>
          {hasPermission("leadSource_counts") && (
            <div
              className={`${
                themeBgImg &&
                (currentMode === "dark" ? "blur-bg-dark" : "blur-bg-light")
              } ${currentMode === "dark" ? "text-white" : "text-black"} p-4`}
            >
              <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-3 flex justify-between">
                <div className="lg:col-span-2 gap-3 flex flex-wrap justify-end">
                  {counters && counters?.length > 0
                    ? counters?.map((counter) => {
                        const matchingSource = sourceCounters.find(
                          (sourceCounter) =>
                            counter?.leadSource
                              ?.toLowerCase()
                              ?.includes(
                                Object.keys(sourceCounter)[0]?.toLowerCase()
                              )
                        );

                        if (!matchingSource) return null;

                        const leadSource = Object.keys(matchingSource)[0];
                        const { icon, bg } = matchingSource[leadSource];

                        return (
                          <Tooltip
                            title={counter?.leadSource}
                            key={counter?.leadSource}
                            arrow
                          >
                            <div className="px-1">
                              <div
                                className="shadow-sm card-hover flex items-center justify-between"
                                style={{
                                  border: `1px solid #AAAAAA`,
                                }}
                              >
                                <div
                                  className="p-2 h-full flex items-center justify-center"
                                  style={{
                                    backgroundColor: bg,
                                  }}
                                >
                                  {icon}
                                </div>
                                <div className="p-2 px-3 font-bold">
                                  {counter?.count}
                                </div>
                              </div>
                            </div>
                          </Tooltip>
                        );
                      })
                    : ""}
                </div>
              </div>
            </div>
          )}

          <div className="mb-10 p-4">
            {/* REPORTS */}
            <div className="mb-3" ref={reportsRef}>
              <div className=" mb-4 ">
                <HeadingTitle title={t("menu_reports")} />
              </div>
              <div className="w-full">
                {loading?.reports ? (
                  <div className="flex items-center justify-center h-[500px]">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    {/* PERFORMANCE FILTERS */}
                    <Box sx={darkModeColors} className="font-semibold">
                      <Tabs
                        value={reportTabValue}
                        onChange={handleFilterChanged}
                        variant="standard"
                      >
                        <Tab label={t("this_month")} />
                        <Tab label={t("last_month")} />
                        <Tab label={t("this_year")} />
                        <Tab label={t("last_year")} />
                      </Tabs>
                    </Box>
                    <div
                      className={`${
                        !themeBgImg
                          ? currentMode === "dark"
                            ? "bg-[#1c1c1c] text-white"
                            : "bg-[#EEEEEE] text-black"
                          : currentMode === "dark"
                          ? "blur-bg-dark text-white"
                          : "blur-bg-light text-black"
                      } rounded-lg p-2 h-auto`}
                    >
                      <h6 className="mb-2 p-2">
                        <span className="font-semibold">
                          {t("performance")}
                        </span>
                      </h6>
                      <div className="justify-between items-center">
                        {/* // <SalesAmountChartAdmin
                          //   selectedMonthSales={selectedMonthSales}
                          // /> */}
                        <>
                          {/* <SaleBubbleChart
                          selectedMonthSales={selectedMonthSales}
                        /> */}
                          <LineBarAreaChart
                            selectedMonthSales={selectedMonthSales}
                            performance={reportsData?.performance}
                          />
                        </>
                      </div>

                      {/* STATS */}
                      <div className=" w-ful grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3 p-4">
                        {stats?.map((stat, index) => (
                          <div
                            key={index}
                            className={`flex items-center justify-between  ${
                              themeBgImg
                                ? currentMode === "dark"
                                  ? "blur-bg-black"
                                  : "blur-bg-white"
                                : currentMode === "dark"
                                ? "bg-dark-neu"
                                : "bg-light-neu"
                            } p-4 rounded-lg shadow-md w-full  `}
                          >
                            <div>
                              <h3>{stat.title}</h3>
                              <p className="text-2xl font-semibold">
                                {stat.value}
                              </p>
                            </div>
                            <div className="text-gray-500 text-3xl mr-4">
                              {stat.icon}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="w-full grid grid-cols-1 lg:grid-cols-5 sm:grid-cols-1 gap-3 mb-3">
                      {/* CLOSED PROJECTS */}
                      <div
                        className={`${
                          currentMode === "dark" ? "text-white" : "text-black"
                        } rounded-lg p-2 col-span-3 lg:col-span-3 sm:col-span-1`}
                      >
                        <h6 className="mb-2 p-2">
                          <span className="font-semibold">
                            {t("closed_projects")?.toUpperCase()}
                          </span>
                        </h6>
                        <div className="justify-between items-center mb-3">
                          <BiaxleLineChart
                            closed_projects={reportsData?.closed_projects}
                          />
                        </div>
                      </div>

                      {/* LEADS BY FEEDBACK */}
                      <div
                        className={`${
                          currentMode === "dark" ? "text-white" : "text-black"
                        } rounded-lg p-2 col-span-2 lg:col-span-2 sm:col-span-1`}
                      >
                        <h6 className="p-2">
                          <span className="font-semibold">
                            {t("leads_by_feedback")?.toUpperCase()}
                          </span>
                        </h6>
                        <div className="justify-between items-center mb-3">
                          <ActiveShapePieChart
                            leads_feedback={reportsData?.leads_feedback}
                          />
                        </div>
                      </div>
                    </div>

                    {/* MOTHLY TARGET */}
                    {/* <div className="grid grid-cols-2 gap-3 mb-3">
                    <div
                      className={`${
                        currentMode === "dark" ? " text-white" : "text-black"
                      } rounded-lg p-2`}
                    >
                      <h6 className="mb-2 p-2">
                        <span className="font-semibold">
                          {t("label_target")?.toUpperCase()}
                        </span>
                      </h6>
                      <div className="justify-between items-center mb-3">
                        <DoughnutChart
                          target={DashboardData?.user?.target}
                          target_reached={DashboardData?.target_reached}
                          target_remaining={DashboardData?.target_remaining}
                        />
                      </div>
                      <h6 className="text-xs text-center mt-3 italic">
                        {t("target_graph_caption")}
                      </h6>
                    </div>
                    <div
                      className={`${
                        currentMode === "dark" ? "text-white" : "text-black"
                      } rounded-lg p-2`}
                    >
                      <h6 className="mb-2 p-2">
                        <span className="font-semibold">
                          {t("closed_over_meeting")?.toUpperCase()}
                        </span>
                      </h6>
                      <div className="justify-between items-center mb-3">
                        <ReportClosedMeetingDoughnut />
                      </div>
                      <h6 className="text-xs text-center mt-3 italic">
                        {t("closedovermeeting_graph_caption")}
                      </h6>
                    </div>
                  </div> */}
                  </>
                )}
              </div>
            </div>

            {/* COMPARISON */}
            <div className="mb-3" ref={comparisonRef}>
              <div className="mb-4 ">
                <HeadingTitle title={t("comparison")} />
              </div>
              {loading?.comparison ? (
                <div className="flex items-center justify-center h-[400px]">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <Box
                    sx={{
                      ...darkModeColors,
                      "& .MuiTabs-indicator": {
                        height: "100%",
                        borderRadius: "5px",
                      },
                      "& .Mui-selected": {
                        color: "white !important",
                        zIndex: "1",
                      },
                    }}
                    className="font-semibold"
                  >
                    <Tabs
                      value={tabValue}
                      onChange={handleChange}
                      variant="standard"
                    >
                      <Tab label={t("last_six_month")} />
                    </Tabs>
                  </Box>

                  <div className="grid grid-cols-1 lg:grid-cols-2 sm:grid-cols-1 gap-3 mb-3">
                    {/*DEALS */}
                    <div
                      className={`${
                        currentMode === "dark" ? " text-white" : "text-black"
                      } rounded-lg p-2`}
                    >
                      <h6 className="mb-2 p-2">
                        <span className="font-semibold">
                          {t("menu_deals")?.toUpperCase()}
                        </span>
                      </h6>
                      <div className="justify-between items-center mb-3">
                        <SimpleBarChart deals={comparisonData?.deals} />
                      </div>
                    </div>

                    {/*  FEEDBACK */}
                    <div
                      className={`${
                        currentMode === "dark" ? "text-white" : "text-black"
                      } rounded-lg p-2`}
                    >
                      <h6 className="mb-2 p-2">
                        <span className="font-semibold">
                          {t("label_feedback")?.toUpperCase()}
                        </span>
                      </h6>
                      <div className="justify-between items-center mb-3">
                        <StackedBarChart feedback={comparisonData?.feedback} />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            {/* LEAD SOURCE */}
            <div className=" w-full  mb-5" ref={sourceRef}>
              <div className=" mb-2 ">
                <div className="flex items-center justify-between">
                  <HeadingTitle title={t("lead_source")} />
                  <Box sx={darkModeColors} className="font-semibold">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={
                          leadSourceFilter || moment().format("YYYY-MM-DD")
                        }
                        label={t("date")}
                        views={["day", "month", "year"]}
                        onChange={(val) =>
                          setLeadSourceFilter(
                            moment(val?.$d).format("YYYY-MM-DD")
                          )
                        }
                        format="DD-MM-YYYY"
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              "& input": {
                                color:
                                  currentMode === "dark" ? "white" : "black",
                              },
                              "& .MuiSvgIcon-root": {
                                color:
                                  currentMode === "dark" ? "white" : "black",
                              },
                              // "& .MuiOutlinedInput-notchedOutline": {
                              //   borderColor:
                              //     fieldErrors?.date === true &&
                              //     "#DA1F26 !important",
                              // },
                              marginBottom: "20px",
                            }}
                            size="small"
                            {...params}
                            onKeyDown={(e) => e.preventDefault()}
                            readOnly={true}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </div>
                {loading?.social_source ? (
                  <div className="flex items-center justify-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    <div
                      className={`${
                        !themeBgImg
                          ? currentMode === "dark"
                            ? "bg-[#1c1c1c] text-white"
                            : "bg-[#EEEEEE] text-black"
                          : currentMode === "dark"
                          ? "blur-bg-dark text-white"
                          : "blur-bg-light text-black"
                      } rounded-lg p-3`}
                    >
                      {/* <div className="w-full flex space-x-4 p-4">
                        {socialChartData?.map((platform, index) => (
                          <div
                            key={index}
                            className={`flex items-center ${platform?.leadSource?.gradient} p-4 rounded-lg shadow-md w-full justify-between`}
                          >
                            <div className="text-white text-2xl">
                              {platform?.leadSource?.icon}
                            </div>
                            <div className="text-black text-2xl">
                              {platform?.total}
                            </div>
                          </div>
                        ))}
                      </div> */}
                      <div className="w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-6 gap-2 p-4">
                        {socialChartData?.length > 0 ? (
                          socialChartData?.map((platform, index) => {
                            const matchingSource = sourceCounters.find(
                              (sourceCounter) =>
                                platform?.leadSource
                                  ?.toLowerCase()
                                  ?.includes(
                                    Object.keys(sourceCounter)[0]?.toLowerCase()
                                  )
                            );

                            if (!matchingSource) return null;

                            const leadSource = Object.keys(matchingSource)[0];
                            const { icon, bg, gradient } =
                              matchingSource[leadSource];
                            return (
                              <div
                                key={index}
                                className={`flex items-center ${gradient} p-4 rounded-lg shadow-md w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 mb-4 justify-between`}
                                style={{ minWidth: "150px" }} // Ensures a minimum width for better spacing
                              >
                                <div className="text-white text-xl sm:text-2xl">
                                  {/* {platform?.leadSource?.icon} */}
                                  {icon}
                                </div>
                                <div className="text-black text-xl sm:text-2xl">
                                  {platform?.count}
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div>
                            <p>{t("no_data_to_display")}</p>
                          </div>
                        )}
                      </div>

                      {/* <div className="justify-between items-center">
                    <ReportProjectBar
                      total_projects={DashboardData?.total_projects}
                      selectedMonthProject={selectedMonthProject}
                    />
                  </div> */}
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* TARGET */}
            <div class="mb-5" ref={targetRef}>
              {loading?.target ? (
                <div className="flex items-center justify-center">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <div className="mb-4">
                    <HeadingTitle title={t("label_target")} />
                  </div>

                  <Box sx={darkModeColors} className="font-semibold">
                    <Tabs
                      value={tabValue}
                      onChange={handleChange}
                      variant="standard"
                    >
                      <Tab label={t("this_month")} />
                      <Tab label={t("last_month")} />
                    </Tabs>
                  </Box>

                  {/* <div className="grid grid-cols-2 gap-3 mb-3">
                    <div
                      className={`${
                        currentMode === "dark" ? " text-white" : "text-black"
                      } rounded-lg p-2 col-span-1`}
                    >
                      <div className="justify-between items-center mb-3"> */}
                  <ProgressBarChart targetData={targetData} />
                  {/* </div>
                    </div>
                  </div> */}
                </>
              )}
            </div>
          </div>
        </div>
        {openPdfModal && (
          <ReportPdfModal
            reportModal={openPdfModal}
            setReportModal={setOpenPdfModal}
          />
        )}
      </div>
    </>
  );
  // }
};

export default Reports;

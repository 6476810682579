import React, { useEffect, useRef, useState } from "react";

import moment from "moment";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  Tooltip,
  IconButton,
  Modal,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { GoogleMap, Marker } from "@react-google-maps/api";

import axios from "../../axoisConfig";
import Error404 from "../Error";
import { useStateContext } from "../../context/ContextProvider";
import Loader from "../../Components/Loader";
import { load } from "../App";
import Select from "react-select";

import { BiBed, BiBath } from "react-icons/bi";
import {
  BsImages,
  BsFiles,
  BsPen,
  BsFileEarmarkText,
  BsTrash,
} from "react-icons/bs";
import {
  FaCity,
  FaGlobe,
  FaMap,
  FaRegBuilding,
  FaRegImages,
  FaRulerCombined,
  FaStar,
  FaUserPlus,
} from "react-icons/fa";
import { MdLocationPin, MdClose } from "react-icons/md";
import {
  TbCurrentLocation,
  TbPhone,
  TbMail,
  TbUserCircle,
  TbCurrencyDollar,
} from "react-icons/tb";
import SelectImagesModal from "./SelectImagesModal";
import SelectDocumentModal from "./SelectDocumentModal";
import EditListingModal from "../../Components/Leads/listings/EditListingComponent";
import SingleImageModal from "./SingleImageModal";
import SingleDocModal from "./SingleDocModal";
import usePermission from "../../utils/usePermission";
import { IoIosVideocam } from "react-icons/io";
import UpdateListModal from "./UpdateListModal";
import AddDocumentModal from "./AddDocumentModal";
import HeadingTitle from "../../Components/_elements/HeadingTitle";
import { CiStar } from "react-icons/ci";
import { IoDocumentsOutline, IoLocationOutline } from "react-icons/io5";
import {
  currencies,
  size_unit,
} from "../../Components/_elements/SelectOptions";
import { selectStyles } from "../../Components/_elements/SelectStyles";
import useCurrencyInfo from "../../Components/_elements/currencyConverter";

const convertUnit = (size, fromUnit, toUnit) => {
  if (fromUnit === "sq.ft" && toUnit === "sq.m") {
    return size / 10.7639; // Convert from sq.ft to sq.m
  } else if (fromUnit === "sq.m" && toUnit === "sq.ft") {
    return size * 10.7639; // Convert from sq.m to sq.ft
  }
  return size; // If units are the same, return the original size
};

const SingleListingsModal = ({
  ListingData,
  handleCloseSingleListingModel,
  singleListingModelOpen,
  FetchListings,
  currentPage,
}) => {
  console.log("single listing Data:: ", ListingData);
  const fileInputRef = useRef(null);

  const handleImageSelect = () => {
    fileInputRef.current.click();
  };
  const [loading, setloading] = useState(true);
  const [listData, setListingData] = useState({});
  const [developers, setDevelopers] = useState([]);
  const [openEdit, setOpenEdit] = useState({
    open: false,
    data: null,
    type: null,
  });
  const [leadNotFound, setLeadNotFound] = useState(false);
  const { hasPermission } = usePermission();
  const closingTImeoutId = useRef(null);
  const [singleImageModal, setSingleImageModal] = useState({
    isOpen: false,
    url: "",
    id: null,
    video: false,
  });
  const [singleDocModal, setSingleDocModal] = useState({
    isOpen: false,
    url: "",
    id: null,
  });
  const [selectImagesModal, setSelectImagesModal] = useState({
    isOpen: false,
    listingId: null,
    img_id: null,
  });
  const [selectDocumentModal, setSelectDocumentModal] = useState({
    isOpen: false,
    listingId: null,
  });
  const {
    currentMode,
    setopenBackDrop,
    BACKEND_URL,
    isArabic,
    isLangRTL,
    i18n,
    User,
    t,
    primaryColor,
  } = useStateContext();
  const [btnLoading, setBtnLoading] = useState(false);

  const [currConvertor, setCurrConvertor] = useState("aed");
  const [size, setSize] = useState(null);
  const [sizeUnit, setSizeUnit] = useState(null);
  const [price, setPrice] = useState(null);
  const [allCurrencies, filteredOptions] = useCurrencyInfo(
    currConvertor?.toLowerCase()
  );
  console.log("currency convertor: ", currConvertor);
  console.log("currency convertor hook: ", allCurrencies, filteredOptions);
  console.log("all Currencies type : ", typeof allCurrencies);
  console.log("price: ", price);

  const handleEdit = (type) => {
    setOpenEdit({ open: true, data: listData, type: type });
  };

  // CURRENCY CONVERTOR
  const convert = (e) => {
    console.log("event value: ", typeof e?.value);
    setCurrConvertor(e?.value?.toLowerCase());

    const convertedPrice =
      allCurrencies && allCurrencies[e?.value?.toLowerCase()]
        ? price * allCurrencies[e?.value?.toLowerCase()]
        : price;

    console.log("converted Price: ", convertedPrice);

    setPrice(convertedPrice);
  };

  // UNIT CONVERTOR
  const handleUnitChange = (e) => {
    const newUnit = e.value;
    const convertedSize = convertUnit(size, sizeUnit, newUnit); // Convert size based on selected unit
    setSize(convertedSize); // Update the size state
    setSizeUnit(newUnit); // Update the unit state
  };

  const [isClosing, setIsClosing] = useState(false);
  const handleClose = () => {
    setIsClosing(true);
    closingTImeoutId.current = setTimeout(() => {
      setIsClosing(false);
      handleCloseSingleListingModel();
    }, 1000);
  };

  // const { lid } = useParams();
  const lid = ListingData;
  console.log("lid ===================", lid);

  const openDoc = (open, url) => {
    window.open(url, "__blank");
  };

  let lat = "";
  let long = "";

  const handleDeleteDocument = async (e, id) => {
    try {
      setloading(true);
      const token = localStorage.getItem("auth-token");
      const deleteDoc = await axios.delete(
        `${BACKEND_URL}/listings/${listData?.id}/documents?doc_ids[]=${id}`,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );

      console.log("update banner: ", deleteDoc);

      setloading(false);
      fetchSingleListing();
      FetchListings(currentPage);
    } catch (error) {
      setloading(false);
      console.log("Error", error);
      const errors = error.response?.data?.errors;

      if (errors) {
        const errorMessages = Object.values(errors).flat().join(" ");
        toast.error(`Errors: ${errorMessages}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Something Went Wrong! Please Try Again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const updateBanner = async (e, type) => {
    const file = e.target.files[0];
    let data = {
      // ...listData,
      banner_img: file,
    };

    console.log("data to be sended ::: ", data);
    try {
      setloading(true);
      const token = localStorage.getItem("auth-token");
      const updateBanner = await axios.post(
        `${BACKEND_URL}/listings/${listData?.id}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );

      console.log("update banner: ", updateBanner);

      setloading(false);
      fetchSingleListing();
      FetchListings(currentPage);
    } catch (error) {
      setloading(false);
      console.log("Error", error);
      const errors = error.response?.data?.errors;

      if (errors) {
        const errorMessages = Object.values(errors).flat().join(" ");
        toast.error(`Errors: ${errorMessages}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Something Went Wrong! Please Try Again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  const fetchSingleListing = async () => {
    try {
      setloading(true);
      const token = localStorage.getItem("auth-token");
      const listing = await axios.get(
        `${BACKEND_URL}/listings/${lid}?images=1&documents=1`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      console.log("SINGLE Listings: ", listing);

      setListingData(listing?.data?.data);
      setCurrConvertor(listing?.data?.data?.currency || "aed");
      setPrice(listing?.data?.data?.price || "");
      setSize(listing?.data?.data?.size || "");
      setSizeUnit(listing?.data?.data?.size_unit || "");
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log("Error", error);
      if (error?.response?.status === 404) {
        setLeadNotFound(true);
      } else {
        toast.error("Something went wrong!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  useEffect(() => {
    setopenBackDrop(false);
    fetchSingleListing(lid);
    return () => {
      clearTimeout(closingTImeoutId.current);
    };
  }, []);

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  const options = {
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeControl: true,
  };

  if (listData?.latlong) {
    const splitLatLong = listData?.latlong?.split(",");
    lat = splitLatLong[0];
    long = splitLatLong[1];
  }

  console.log("maps: ", load);

  const style = {
    transform: "translate(0%, 0%)",
    boxShadow: 24,
  };

  return (
    <>
      {/* <div
        className={`flex min-h-screen w-full p-4 ${
          !themeBgImg && (currentMode === "dark" ? "bg-black" : "bg-white")
        } ${currentMode === "dark" ? "text-white" : "text-black"}`}
      > */}
      <Modal
        keepMounted
        open={singleListingModelOpen}
        // onClose={handleCloseTimelineModel}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          className={`${
            isLangRTL(i18n.language) ? "modal-open-left" : "modal-open-right"
          } ${
            isClosing
              ? isLangRTL(i18n.language)
                ? "modal-close-left"
                : "modal-close-right"
              : ""
          }
          w-[100vw] h-[100vh] flex items-start justify-end `}
        >
          <button
            // onClick={handleCloseTimelineModel}
            onClick={handleClose}
            className={`${
              isLangRTL(i18n.language) ? "rounded-r-full" : "rounded-l-full"
            }
            bg-primary w-fit h-fit p-3 my-4 z-10`}
          >
            <MdClose
              size={18}
              color={"white"}
              className=" hover:border hover:border-white hover:rounded-full"
            />
          </button>

          <div
            style={style}
            className={` ${
              currentMode === "dark"
                ? "bg-[#1C1C1C] text-white"
                : "bg-[#FFFFFF] text-black"
            } ${
              currentMode === "dark" &&
              (isLangRTL(i18n.language)
                ? "border-primary border-r-2"
                : "border-primary border-l-2")
            }
              p-4 h-[100vh] w-[80vw] overflow-y-scroll
              `}
          >
            {loading ? (
              <Loader />
            ) : (
              <>
                {leadNotFound ? (
                  <Error404 />
                ) : (
                  <div className="w-full">
                    <div className="flex items-center justify-between gap-2 mb-3">
                      <div className="flex items-center gap-2">
                        <div className="bg-primary h-8 w-1 rounded-full"></div>
                        <h1
                          className={`text-lg font-semibold uppercase ${
                            currentMode === "dark" ? "text-white" : "text-black"
                          }`}
                          style={{
                            fontFamily: isArabic(listData?.project)
                              ? "Noto Kufi Arabic"
                              : "inherit",
                          }}
                        >
                          {listData?.project || "-"} {t("by")}{" "}
                          {listData?.developer || "-"}
                        </h1>
                      </div>
                      {listData?.is_featured == 1 ? (
                        <div className="flex items-center gap-2">
                          <FaStar size={20} color={"#FFD700"} />

                          <h3
                            className={`text-lg font-semibold uppercase ${
                              currentMode === "dark"
                                ? "text-white"
                                : "text-black"
                            }`}
                            style={{
                              fontFamily: isArabic(listData?.project)
                                ? "Noto Kufi Arabic"
                                : "inherit",
                            }}
                          >
                            {t("label_is_featured")}{" "}
                          </h3>
                        </div>
                      ) : null}
                    </div>

                    <div className="sm:col-span-1 md:col-span-3 lg:col-span-4 relative">
                      {/* IMAGES */}
                      <div className="rounded-xl shadow-sm p-4">
                        <div className="w-full flex items-center gap-x-1 mb-3 overflow-x-scroll">
                          {/* BANNER */}
                          {/* <div className="w-[200px] h-[200px] relative "> */}
                          {listData?.banner_img ? (
                            <>
                              <img
                                onClick={() =>
                                  setSingleImageModal({
                                    isOpen: true,
                                    url: listData?.banner_img,
                                    listingId: listData?.id,
                                  })
                                }
                                src={listData?.banner_img}
                                alt={"banner"}
                                className="max-w-full h-[200px] m-1 rounded-md cursor-pointer object-cover"
                              />
                              <div className="absolute top-0 right-1">
                                <Tooltip title="Edit Banner" arrow>
                                  <IconButton
                                    className={`rounded-full bg-btn-primary`}
                                    onClick={handleImageSelect}
                                  >
                                    <BsPen size={16} color={"#FFFFFF"} />
                                  </IconButton>
                                </Tooltip>
                                <input
                                  type="file"
                                  ref={fileInputRef}
                                  style={{ display: "none" }}
                                  accept="image/*"
                                  onChange={updateBanner}
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {/* </div> */}
                          {/* GALLERY */}
                          {listData?.images?.map((pic) =>
                            pic ? (
                              <img
                                key={pic?.id} // Ensure you have a unique key for each item in the array
                                onClick={() =>
                                  setSingleImageModal({
                                    isOpen: true,
                                    url: pic?.img_url,
                                    listingId: listData?.id,
                                    img_id: pic?.id,
                                  })
                                }
                                src={pic?.img_url}
                                alt={pic?.img_alt}
                                className="w-full h-[200px] object-cover m-1 rounded-md cursor-pointer"
                              />
                            ) : null
                          )}
                        </div>
                      </div>
                    </div>

                    <div className={`w-full py-5`}>
                      <div className="grid sm:grid-cols-1 md:grid-cols-2">
                        <div className="w-full p-1">
                          <div className="flex items-center">
                            <div
                              className={`${
                                listData?.listing_status == 1
                                  ? "bg-green-600"
                                  : "bg-red-600"
                              } rounded-md text-white p-2 ${
                                isLangRTL(i18n.language) ? "ml-2" : "mr-2"
                              } font-semibold`}
                            ></div>
                            <h1
                              className={`text-lg font-bold mx-2 ${
                                currentMode === "dark"
                                  ? "text-white"
                                  : "text-black"
                              }`}
                              style={{
                                fontFamily: isArabic(listData?.listing_title)
                                  ? "Noto Kufi Arabic"
                                  : "inherit",
                              }}
                            >
                              {listData?.listing_title}
                            </h1>
                          </div>
                        </div>
                        <div className="w-full p-1">
                          <div className="flex items-center gap-2 justify-end">
                            {/* WATCH VIDEO  */}
                            {listData?.promo_video && (
                              <button
                                className="bg-btn-primary border border-primary p-2 font-semibold rounded-md shadow-sm text-white"
                                onClick={() =>
                                  setSingleImageModal({
                                    isOpen: true,
                                    url: listData?.promo_video,
                                    listingId: listData?.id,
                                    video: "true",
                                  })
                                }
                              >
                                {t("label_promo_video")}
                              </button>
                            )}

                            {/* EDIT DETAILS  */}
                            <Tooltip title="Edit Listing Details" arrow>
                              <IconButton
                                className={`rounded-full bg-btn-primary`}
                                onClick={() => handleEdit("main")}
                              >
                                <BsPen size={16} color={"#FFFFFF"} />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Edit Gallery" arrow>
                              <IconButton
                                className="rounded-full bg-btn-primary"
                                onClick={() =>
                                  setSelectImagesModal({
                                    isOpen: true,
                                    listingId: listData?.id,
                                  })
                                }
                              >
                                <FaRegImages size={16} color={"#FFFFFF"} />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Edit Document" arrow>
                              <IconButton
                                className="rounded-full bg-btn-primary"
                                onClick={() =>
                                  setSelectDocumentModal({
                                    isOpen: true,
                                    listingId: listData?.id,
                                  })
                                }
                              >
                                <IoDocumentsOutline
                                  size={16}
                                  color={"#FFFFFF"}
                                />
                              </IconButton>
                            </Tooltip>

                            <div className="mx-1"></div>
                          </div>
                        </div>
                      </div>
                      <div className="grid sm:grid-cols-1 md:grid-cols-6 lg:grid-cols-6 gap-5 p-4">
                        <div className="sm:col-span-1 md:col-span-3 lg:col-span-4 space-y-3">
                          {/* SHORT DESCRIPTION  */}
                          {/* <div className="flex gap-3 mb-3">
                            <h6>{listData?.description} </h6>
                          </div> */}
                          {/* COUNTRY  */}
                          <div className="flex gap-3">
                            <IoLocationOutline
                              size={18}
                              className={
                                currentMode === "dark"
                                  ? "text-[#EEEEEE]"
                                  : "text-[#333333]"
                              }
                            />
                            <h6>
                              {listData?.area},{listData?.city},{" "}
                              {listData?.country}
                            </h6>
                          </div>
                          <div className="flex gap-3">
                            <FaRegBuilding
                              size={18}
                              className={
                                currentMode === "dark"
                                  ? "text-[#EEEEEE]"
                                  : "text-[#333333]"
                              }
                            />
                            <h6>
                              {listData?.listing_type} |{" "}
                              {listData?.bedrooms || "-"} |
                              {listData?.bathrooms || "-"}
                            </h6>
                          </div>
                          <div className="flex gap-3 items-center">
                            <TbCurrencyDollar
                              size={18}
                              className={
                                currentMode === "dark"
                                  ? "text-[#EEEEEE]"
                                  : "text-[#333333]"
                              }
                            />
                            <h6>
                              {listData?.is_start_price == 1 &&
                                t("starting_from")}{" "}
                              {/* {currConvertor?.toUpperCase()}{" "} */}
                              {price && Number(price)?.toFixed(2)}
                            </h6>
                            <Select
                              id="currency"
                              options={currencies(t)?.map((curr) => ({
                                value: curr.value,
                                label: curr.label,
                              }))}
                              value={currencies(t)?.filter(
                                (curr) =>
                                  curr?.value?.toLowerCase() ===
                                  currConvertor?.toLowerCase()
                              )}
                              onChange={(e) => convert(e)}
                              placeholder={t("label_currency")}
                              menuPortalTarget={document.body}
                              // styles={getMergedStyles(
                              //   fieldErrors.currency,
                              //   selectStyles(currentMode, primaryColor)
                              // )}
                              styles={selectStyles(currentMode, primaryColor)}
                            />
                          </div>
                          <div className="flex gap-3 items-center">
                            <FaRulerCombined
                              size={18}
                              className={
                                currentMode === "dark"
                                  ? "text-[#EEEEEE]"
                                  : "text-[#333333]"
                              }
                            />
                            <h6>
                              {size && Number(size)?.toFixed(2)}
                              {/* {sizeUnit} */}
                            </h6>
                            <Select
                              id="currency_type"
                              options={size_unit(t)?.map((unit) => ({
                                value: unit.value,
                                label: unit.label,
                              }))}
                              value={size_unit(t)?.filter(
                                (unit) => unit?.value === sizeUnit
                              )}
                              onChange={(e) => handleUnitChange(e)}
                              placeholder={t("label_unit")}
                              menuPortalTarget={document.body}
                              styles={selectStyles(currentMode, primaryColor)}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-1 md:col-span-3 lg:col-span-2 space-y-2 text-right gap-5 mb-5">
                          <div className="flex items-end justify-end h-full w-full ">
                            <div className="text-right">
                              <p className="text-sm my-3">
                                {t("added_on")}{" "}
                                {moment(listData?.created_at).format(
                                  "YYYY-MM-DD HH:MM"
                                )}
                              </p>
                              <p className="text-sm my-2 flex items-center">
                                <FaUserPlus
                                  size={16}
                                  color={`${
                                    currentMode === "dark"
                                      ? "#EEEEEE"
                                      : "#333333"
                                  }`}
                                  className="mx-2"
                                />
                                {listData?.addedBy_name}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* DESCRIPTION */}
                    {listData?.description && (
                      <>
                        <div className="w-full flex items-center pb-3 mb-1 ">
                          <div
                            className={`bg-primary h-10 w-1 rounded-full ${
                              isLangRTL(i18n.language) ? "ml-2" : "mr-2"
                            } my-1`}
                          ></div>
                          <h1
                            className={`text-lg font-semibold ${
                              currentMode === "dark"
                                ? "text-white"
                                : "text-black"
                            }`}
                          >
                            {t("description")}
                          </h1>
                          {/* <Tooltip title="Edit listing meta" arrow>
                            <IconButton
                              className={`rounded-full bg-btn-primary ml-3`}
                              onClick={() => handleEdit("list_meta")}
                            >
                              <BsPen size={16} color={"#FFFFFF"} />
                            </IconButton>
                          </Tooltip> */}
                        </div>

                        <p
                          className="mb-3"
                          style={{ maxHeight: "200px", overflowY: "auto" }}
                        >
                          {listData?.description}
                        </p>
                      </>
                    )}

                    {/* NEAR BY LOCATIONS */}
                    {listData?.nearby?.length > 0 && (
                      <>
                        <div className="w-full flex items-center pb-3 mb-1 ">
                          <div
                            className={`bg-primary h-10 w-1 rounded-full ${
                              isLangRTL(i18n.language) ? "ml-2" : "mr-2"
                            } my-1`}
                          ></div>
                          <h1
                            className={`text-lg font-semibold ${
                              currentMode === "dark"
                                ? "text-white"
                                : "text-black"
                            }`}
                          >
                            {t("label_nearby")}
                          </h1>
                        </div>

                        {listData?.nearby?.map((near) => (
                          <p
                            className="mb-3"
                            style={{ maxHeight: "200px", overflowY: "auto" }}
                          >
                            {near?.name},
                          </p>
                        ))}
                      </>
                    )}

                    {/* IN MAP  */}

                    {listData?.latlong === null || listData?.latlong === "" ? (
                      <></>
                    ) : (
                      <div className="w-full h-[50vh] border border-primary relative">
                        {!load?.isLoaded ? (
                          <div>Your map is loading...</div>
                        ) : (
                          <GoogleMap
                            zoom={12}
                            center={{
                              lat: parseFloat(lat),
                              lng: parseFloat(long),
                            }}
                            mapContainerStyle={mapContainerStyle}
                            options={options}
                          >
                            <Marker
                              key={listData?.id}
                              position={{
                                lat: Number(parseFloat(lat)),
                                lng: Number(parseFloat(long)),
                              }}
                              icon={{
                                url: (
                                  <MdLocationPin size={30} color={"#DA1F26"} />
                                ),
                                scaledSize: window.google.maps
                                  ? new window.google.maps.Size(50, 50)
                                  : null,
                              }}
                            />
                          </GoogleMap>
                        )}
                        {/* <div className="absolute top-0 right-1">
                          <Tooltip title="Edit attrbiute type" arrow>
                            <IconButton
                              className={`rounded-full bg-btn-primary`}
                              onClick={() => handleEdit("list_attr_type")}
                            >
                              <BsPen size={16} color={"#FFFFFF"} />
                            </IconButton>
                          </Tooltip>
                        </div> */}
                      </div>
                    )}

                    {/* <div className="bg-primary h-0.5 w-full my-5"></div> */}

                    {(listData?.addedBy === User?.id ||
                      hasPermission("seller_details") ||
                      User.role === 1) && (
                      <div className={`w-full py-5`}>
                        {/* <div className="sm:col-span-1 md:col-span-3 lg:col-span-4 relative">
                        
                          <div className="rounded-xl shadow-sm p-4">
                            <div className="w-full flex items-center gap-x-1 mb-3 overflow-x-scroll">
                              {listData?.images?.map((pic) =>
                                pic ? (
                                  <img
                                    key={pic?.id} // Ensure you have a unique key for each item in the array
                                    onClick={() =>
                                      setSingleImageModal({
                                        isOpen: true,
                                        url: pic?.img_url,
                                        listingId: listData?.id,
                                        img_id: pic?.id,
                                      })
                                    }
                                    src={pic?.img_url}
                                    alt={pic?.img_alt}
                                    className="w-auto h-[200px] object-cover m-1 rounded-md cursor-pointer"
                                  />
                                ) : null
                              )}
                            </div>

                            <div className="absolute top-0 right-1">
                              <Tooltip title="Edit Banner" arrow>
                                <IconButton
                                  className="rounded-full bg-btn-primary"
                                  onClick={() =>
                                    setSelectImagesModal({
                                      isOpen: true,
                                      listingId: listData?.id,
                                    })
                                  }
                                >
                                  <BsPen size={16} color={"#FFFFFF"} />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </div>
                        </div> */}
                        <div className="sm:col-span-1 md:col-span-3 lg:col-span-4  mt-4">
                          {/* DOCUMENTS */}
                          <div className="w-full flex items-center pb-3">
                            <div
                              className={`bg-primary h-10 w-1 rounded-full ${
                                isLangRTL(i18n.language) ? "ml-2" : "mr-2"
                              } my-1`}
                            ></div>
                            <h1
                              className={`text-lg font-semibold ${
                                currentMode === "dark"
                                  ? "text-white"
                                  : "text-black"
                              }`}
                            >
                              {t("documents")}
                            </h1>
                            {/* <Tooltip title="Edit listing meta" arrow>
                            <IconButton
                              className={`rounded-full bg-btn-primary ml-3`}
                              onClick={() => handleEdit("list_meta")}
                            >
                              <BsPen size={16} color={"#FFFFFF"} />
                            </IconButton>
                          </Tooltip> */}
                          </div>

                          <div className="rounded-xl shadow-sm p-4 relative">
                            <div className="w-full flex items-center gap-x-1 mb-3 overflow-x-scroll">
                              {listData?.documents?.map((doc) =>
                                doc ? (
                                  <div
                                    key={doc?.id}
                                    className="flex w-full justify-center "
                                  >
                                    <div className="w-min ">
                                      <div className="p-2 flex items-center justify-center hover:cursor-pointer relative">
                                        <a
                                          href={doc?.doc_url}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <div className="w-full text-center">
                                            <div className="w-full flex justify-center">
                                              <BsFileEarmarkText
                                                size={70}
                                                color={"#AAAAAA"}
                                                className="hover:-mt-1 hover:mb-1"
                                              />
                                            </div>
                                            <div className="my-3">
                                              {doc?.doc_name}
                                            </div>
                                          </div>
                                        </a>
                                        {hasPermission(
                                          "delete_listing_doc"
                                        ) && (
                                          <div className="absolute top-0 -right-4 p-1 cursor-pointer">
                                            <IconButton
                                              className="bg-btn-primary"
                                              onClick={(e) =>
                                                handleDeleteDocument(e, doc?.id)
                                              }
                                            >
                                              {btnLoading ? (
                                                <CircularProgress />
                                              ) : (
                                                <BsTrash
                                                  size={20}
                                                  color={"#FFFFFF"}
                                                />
                                              )}
                                            </IconButton>
                                          </div>
                                        )}
                                      </div>

                                      {/*  delete document button */}

                                      {/* <div className="absolute top-0 -right-4 p-1 cursor-pointer">
                                        <IconButton
                                          className="bg-btn-primary"
                                          onClick={(e) =>
                                            handleDeleteDocument(e, doc?.id)
                                          }
                                        >
                                          {btnLoading ? (
                                            <CircularProgress />
                                          ) : (
                                            <BsTrash size={20} />
                                          )}
                                        </IconButton>
                                      </div> */}
                                    </div>
                                  </div>
                                ) : (
                                  <p>{t("no_documents")}</p>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {/* <Footer /> */}

                {singleImageModal?.isOpen && (
                  <SingleImageModal
                    singleImageModal={singleImageModal}
                    handleClose={() => setSingleImageModal({ isOpen: false })}
                    fetchSingleListing={fetchSingleListing}
                    listing="true"
                  />
                )}

                {singleDocModal?.isOpen && (
                  <SingleDocModal
                    singleImageModal={singleDocModal}
                    handleClose={() => setSingleDocModal({ isOpen: false })}
                    fetchSingleListing={fetchSingleListing}
                  />
                )}

                {selectImagesModal?.isOpen && (
                  <SelectImagesModal
                    fetchSingleListing={fetchSingleListing}
                    selectImagesModal={selectImagesModal}
                    handleClose={() => setSelectImagesModal({ isOpen: false })}
                    listData={listData}
                  />
                )}

                {selectDocumentModal?.isOpen && (
                  <SelectDocumentModal
                    fetchSingleListing={fetchSingleListing}
                    selectDocumentModal={selectDocumentModal}
                    handleClose={() =>
                      setSelectDocumentModal({ isOpen: false })
                    }
                  />
                )}
                {/* {openEdit?.open && (
                  <UpdateListModal
                    openEdit={openEdit}
                    setOpenEdit={setOpenEdit}
                    fetchSingleListing={fetchSingleListing}
                    handleClose={() => setOpenEdit(false)}
                    FetchListings={FetchListings}
                    developers={developers}
                    setDevelopers={setDevelopers}
                  />
                )} */}
                {openEdit?.open && (
                  <UpdateListModal
                    openEdit={openEdit}
                    setOpenEdit={setOpenEdit}
                    fetchSingleListing={fetchSingleListing}
                    handleClose={() => setOpenEdit(false)}
                    FetchListings={FetchListings}
                    developers={developers}
                    setDevelopers={setDevelopers}
                  />
                )}
                {/* {openEdit && (
                  <EditListingModal
                    setOpenEdit={setOpenEdit}
                    openEdit={openEdit}
                    fetchSingleListing={fetchSingleListing}
                    handleClose={() => setOpenEdit(false)}
                  />
                )} */}
              </>
            )}
          </div>
        </div>
      </Modal>
      {/* </div> */}
    </>
  );
};

export default SingleListingsModal;

import React from "react";
import Header from "./Header";
import AdsGraph from "./AdsGraph";
import Compaigns from "./Compaigns";

const AdsManager = () => {
  return (
    <div>
      {/* <AdsGraph /> */}
      <Compaigns />
    </div>
  );
};

export default AdsManager;

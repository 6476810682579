import React, { useEffect, useRef, useState } from "react";
import { useStateContext } from "../../../context/ContextProvider";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { selectStyles } from "../../../Components/_elements/SelectStyles";
import Select from "react-select";
import axios from "../../../axoisConfig";
import { toast } from "react-toastify";
import ListingDataGrid from "../ListingDataGrid";
import SelectOption from "@material-tailwind/react/components/Select/SelectOption";
import { IoMdClose } from "react-icons/io";
import { BsDash, BsPlus, BsTrash } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import {
  bathroom_options,
  enquiry_options,
  property_options,
  size_unit,
} from "../../_elements/SelectOptions";

const AddListingAttribute = ({
  data,
  setData,
  loading,
  setLoading,
  page,
  pageSize,
  total,
  setPage,
  setPageSize,
  FetchData,
  listingIds,
  setListingIDs,
  handleNext,
  listData,
  edit,
  handleClose,
  fetchSingleListing,
  developers,
  setDevelopers,
  listingData,
  setListingData,
}) => {
  console.log("developers: ", developers);
  console.log("setDeveloper : ", setDevelopers);
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    isArabic,
    primaryColor,
    t,
    isLangRTL,
    i18n,
    fontFam,
    themeBgImg,
  } = useStateContext();
  const token = localStorage.getItem("auth-token");

  console.log("api data from parent:: ", data);

  const searchRef = useRef();
  const [btnLoading, setBtnLoading] = useState(false);

  // console.log("listing attr data:: ", listingAttr);

  const handleChange = (e) => {
    setListingData((prevListingAttr) => ({
      ...prevListingAttr,
      [e.target.id]: e.target.value,
    }));
  };

  const fetchUsers = async (title) => {
    try {
      let url = `${BACKEND_URL}/vendors?vendor_name=${title}`;

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      console.log("Users: ", response);

      const vendors = response?.data?.data?.data;
      const filterDevs = vendors?.filter(
        (ven) => ven?.type?.toLowerCase() === "developer"
      );
      console.log("filter devs: ", filterDevs);
      setDevelopers(filterDevs);
    } catch (error) {
      console.log(error);

      toast.error("Unable to fetch developers.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    if (listData && listingData?.developer) {
      fetchUsers(listingData?.developer);
    }
  }, []);

  return (
    <div className="my-4">
      <h4 className={`text-primary text-center font-semibold pb-5`}>
        {t("project_details")}
      </h4>

      <div className="grid sm:grid-cols-1   md:grid-cols-2 lg:grid-cols-2 gap-3">
        <Box
          sx={{
            ...darkModeColors,
            "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
              {
                right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                transformOrigin: isLangRTL(i18n.language) ? "right" : "left",
              },
            "& legend": {
              textAlign: isLangRTL(i18n.language) ? "right" : "left",
            },
          }}
        >
          {/* <Select
            id="listing_type_id"
            value={{
              value: listingData?.property_type,
              label: listingData?.property_type
                ? data?.list_type?.filter(
                    (list_type) => list_type.name === listingData?.property_type
                  )[0]?.name
                : t("label_property_type"),
            }}
            onChange={(e) => {
              setListingData({
                ...listingData,
                property_type: e.value,
              });
            }}
            options={data?.list_type?.map((list_type) => ({
              value: list_type.name,
              label: list_type.name,
            }))}
            className="w-full"
            placeholder={t("label_property_type")}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          /> */}
          <Select
            id="property-type"
            value={{
              value: property_options(t)?.find(
                (option) => option.value === listingData?.property_type
              ),
              label: listingData?.property_type
                ? property_options(t)?.find(
                    (option) => option.value === listingData?.property_type
                  )?.label
                : t("label_property_type"),
            }}
            onChange={(e) => {
              setListingData({
                ...listingData,
                property_type: e.value,
              });
            }}
            options={property_options(t)}
            placeholder={t("label_property_type")}
            className="w-full"
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          />
          <TextField
            id="project"
            type={"text"}
            label={t("label_project_name")}
            className="w-full"
            sx={{
              marginBottom: "20px !important",
            }}
            variant="outlined"
            size="small"
            value={listingData?.project}
            name="projectLocation"
            onChange={handleChange}
            required
          />
          {/* <TextField
            id="area"
            type={"text"}
            label={t("property_size")}
            className="w-full"
            sx={{
              marginBottom: "20px !important",
            }}
            variant="outlined"
            size="small"
            value={listingData?.area}
            name="area"
            onChange={handleChange}
            required
          /> */}

          <div className="grid grid-cols-3">
            <Select
              id="currency_type"
              options={size_unit(t)?.map((unit) => ({
                value: unit.value,
                label: unit.label,
              }))}
              value={size_unit(t)?.filter(
                (unit) => unit?.value === listingData?.size_unit
              )}
              onChange={(e) => {
                setListingData({
                  ...listingData,
                  size_unit: e.value,
                });
              }}
              placeholder={t("label_unit")}
              menuPortalTarget={document.body}
              styles={selectStyles(currentMode, primaryColor)}
            />

            <TextField
              type={"number"}
              // label={t("label_item_price")}
              className="w-full col-span-2"
              style={{
                marginBottom: "20px",
              }}
              placeholder={t("property_size")}
              variant="outlined"
              name="size"
              size="small"
              id="size"
              value={listingData?.size}
              onChange={handleChange}
            />
          </div>

          {/* <TextField
            id="gallery"
            type={"text"}
            label={t("gallery")}
            className="w-full"
            sx={{
              marginBottom: "20px !important",
            }}
            variant="outlined"
            size="small"
            value={listingData?.gallery}
            name="tourLink"
            onChange={handleChange}
          /> */}
        </Box>
        <Box
          sx={{
            ...darkModeColors,
            "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
              {
                right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                transformOrigin: isLangRTL(i18n.language) ? "right" : "left",
              },
            "& legend": {
              textAlign: isLangRTL(i18n.language) ? "right" : "left",
            },
          }}
        >
          {/* <Select
            id="listing_type_id"
            value={{
              value: listingData?.listing_type_id,
              label: listingData?.listing_type_id
                ? data?.list_type?.filter(
                    (list_type) =>
                      list_type.lid === listingData?.listing_type_id
                  )[0]?.name
                : t("label_listing_type"),
            }}
           
            options={data?.list_type?.map((list_type) => ({
              value: list_type.lid,
              label: list_type.name,
            }))}
            className="w-full"
            placeholder={t("label_listing_type")}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          /> */}
          {/* <TextField
            id="bedroom"
            type={"text"}
            label={t("bedroom")}
            className="w-full"
            sx={{
              marginBottom: "20px !important",
            }}
            variant="outlined"
            size="small"
            value={listingData?.bedroom}
            name="projectLocation"
            onChange={handleChange}
            required
          /> */}
          <Select
            id="bedroom"
            value={enquiry_options(t).find(
              (option) => option.value === listingData?.bedrooms
            )}
            onChange={(e) => {
              setListingData({
                ...listingData,
                bedrooms: e.value,
              });
            }}
            options={enquiry_options(t)}
            placeholder={t("number_of_bedrooms")}
            className="w-full"
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
            required
          />

          <Select
            id="bathroom"
            value={bathroom_options(t).find(
              (option) => option.value === listingData?.bathrooms
            )}
            onChange={(e) => {
              setListingData({
                ...listingData,
                bathrooms: e.value,
              });
            }}
            options={bathroom_options(t)}
            placeholder={t("number_of_bathrooms")}
            className="w-full"
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
            required
          />

          {/* <TextField
            id="project_name"
            type={"text"}
            label={t("label_project_name")}
            className="w-full"
            sx={{
              marginBottom: "20px !important",
            }}
            variant="outlined"
            size="small"
            value={listingData?.project_name}
            name="projectLocation"
            onChange={handleChange}
            required
          /> */}

          <FormControl
            className={`${
              currentMode === "dark" ? "text-white" : "text-black"
            }`}
            sx={{
              minWidth: "100%",
              borderRadius: 1,
              marginBottom: "10px",
            }}
          >
            <TextField
              id="developer"
              select
              value={listingData?.developer || "selected"}
              label={t("form_developer_name")}
              onChange={(e) => {
                console.log("developer eeee: ", e);
                const name = e.target.value;

                setListingData({
                  ...listingData,
                  developer: name,
                });
              }}
              size="small"
              className="w-full border border-gray-300 rounded"
              displayEmpty
              required
              sx={{
                // border: "1px solid #000000",
                height: "40px",
                "& .MuiSelect-select": {
                  fontSize: 11,
                },
              }}
            >
              <MenuItem selected value="selected">
                ---{t("form_developer_name")}----
              </MenuItem>
              <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                <TextField
                  placeholder={t("search_dev")}
                  ref={searchRef}
                  sx={{ "& input": { border: "0" } }}
                  variant="standard"
                  onChange={(e) => {
                    e.preventDefault();
                    const inputValue =
                      searchRef.current.querySelector("input").value;
                    if (inputValue) {
                      fetchUsers(inputValue);
                    }
                  }}
                  onClick={(event) => event.stopPropagation()}
                />
              </MenuItem>
              {developers?.map((developers) => (
                <MenuItem key={developers?.id} value={developers?.vendor_name}>
                  {developers?.vendor_name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Box>
      </div>

      {/* <Button
        className={`w-full text-white rounded-md py-3 font-semibold disabled:opacity-50 disabled:cursor-not-allowed hover:shadow-none`}
        ripple={true}
        style={{
          fontFamily: fontFam,
          background: `${primaryColor}`,
        }}
        size="lg"
        type="submit"
        disabled={btnLoading ? true : false}
        onClick={AddListAttr}
      >
        {btnLoading ? (
          <CircularProgress
            size={20}
            sx={{ color: "white" }}
            className="text-white"
          />
        ) : (
          <span className="text-white">{t("submit")}</span>
        )}
      </Button> */}
    </div>
  );
};

export default AddListingAttribute;

import { Button } from "@material-tailwind/react";
import Switch from "@mui/material/Switch";
import Avatar from "@mui/material/Avatar";

import {
  Box,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import Select from "react-select";
import { useStateContext } from "../../context/ContextProvider";
import usePermission from "../../utils/usePermission";
import { useEffect, useState } from "react";
import axios from "../../axoisConfig";
import AddUserModel from "../../Components/addUser/AddUserModel";
import SecondaryListings from "../../Components/Listings/SecondaryListings";
import AddNewListingModal from "../../Components/Listings/AddNewListingModal";
import { BsBuildingAdd, BsSearch } from "react-icons/bs";
import { toast } from "react-toastify";
import moment from "moment";
import { selectBgStyles } from "../../Components/_elements/SelectStyles";
import {
  bathroom_options,
  enquiry_options,
  listing_options,
  property_options,
} from "../../Components/_elements/SelectOptions";
import { CiDollar, CiLocationOn } from "react-icons/ci";
import { debounce } from "lodash";

const Listings = () => {
  const {
    currentMode,
    BACKEND_URL,
    darkModeColors,
    t,
    themeBgImg,
    primaryColor,
    blurDarkColor,
    blurLightColor,
    isLangRTL,
    i18n,
    fontFam,
  } = useStateContext();

  const [value, setValue] = useState(0);
  const [listing, setListings] = useState([]);
  const [lastPage, setLastPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dropDownLoading, setDropDownLoading] = useState(false);
  const [model, setModel] = useState(false);
  const [btnloading, setbtnloading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageBeingScrolled, setPageBeingScrolled] = useState(1);
  const [listingModalOpen, setListingModalOpen] = useState(false);
  const handleCloseListingModal = () => setListingModalOpen(false);
  const token = localStorage.getItem("auth-token");

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [listings_type, setListingType] = useState([]);
  const [filters, setFilters] = useState({
    bedrooms: null,
    bathrooms: null,
    max_price: null,
    min_price: null,
    listing_type: null,
    listing_status: 0,
    // country: null,
    // state: null,
    is_featured: 0,
    project: null,
    developer: null,
    location: null,
    property_type: null,
    sortby: null,
    order: null,
  });

  const isFilterApplied = Object.values(filters).some(
    (value) => value !== null && value != 0
  );

  console.log("is filter applied: ", isFilterApplied);

  const [searchCriteria, setSearchCriteria] = useState("project");
  const [searchQuery, setSearchQuery] = useState("");
  const [switchValue, setSwitchValue] = useState(false);

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchCriteriaChange = (event) => {
    setSearchCriteria(event.value);
  };

  const clearFilter = (e) => {
    e.preventDefault();

    setFilters({
      bedrooms: null,
      bathrooms: null,
      max_price: "",
      min_price: "",
      listing_type: null,
      listing_status: 0,
      // country: null,
      // state: null,
      is_featured: 0,
      project: null,
      developer: null,
      location: "",
      property_type: null,
      sortby: null,
      order: null,
    });

    setSearchQuery("");
    setSearchCriteria("project");
    setSwitchValue(false);
  };

  console.log("filters::: ", filters);

  const SearchListings = async (page) => {
    setLoading(true);
    if (page > 1) {
      setbtnloading(true);
    }
    let url = `${BACKEND_URL}/listings/?page=${page}`;
    if (filters?.bedrooms) url += `&bedrooms=${filters?.bedrooms}`;
    if (filters?.bathrooms) url += `&bathrooms=${filters?.bathrooms}`;
    if (filters?.property_type)
      url += `&property_type=${filters?.property_type}`;
    if (filters?.listing_type) url += `&listing_type=${filters?.listing_type}`;
    if (filters?.is_featured) url += `&is_featured=${filters?.is_featured}`;
    if (filters?.listing_status)
      url += `&listing_status=${filters?.listing_status}`;
    if (filters?.min_price) url += `&min_price=${filters?.min_price}`;
    if (filters?.max_price) url += `&max_price=${filters?.max_price}`;
    if (filters?.location) url += `&location=${filters?.location}`;
    // if (filters?.sortby) url += `&sortby=${filters?.sortby}`;
    // if (filters?.order) url += `&order=${filters?.order}`;

    if (filters?.sortby) {
      const sortMapping = {
        price_low: { sortby: "price", order: "desc" },
        price_high: { sortby: "price", order: "asc" },
        latest: { sortby: "id", order: "desc" },
        oldest: { sortby: "id", order: "asc" },
      };

      const { sortby, order } = sortMapping[filters.sortby] || {};

      if (sortby && order) {
        url += `&sortby=${sortby}&order=${order}`;
      }
    }

    if (searchCriteria === "developer") url += `&developer=${searchQuery}`;
    if (searchCriteria === "project") url += `&project=${searchQuery}`;

    // if (searchQuery) url += `&title=${searchQuery}`;

    try {
      const all_listings = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      console.log("search listings: ", all_listings);
      let filteredListings = all_listings?.data?.data?.data || [];

      console.log("filtered listings: ", filteredListings);

      setListings(filteredListings);

      setLoading(false);
      setLastPage(all_listings?.data?.data?.last_page);
      setTotal(all_listings?.data?.data?.total);
      setbtnloading(false);
    } catch (error) {
      setLoading(false);
      console.log("listings not fetched. ", error);
      toast.error("Unable to fetch listings.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const FetchListingType = async () => {
    if (listings_type.length > 0) {
      return;
    }
    setDropDownLoading(true);
    let url = `${BACKEND_URL}/listing-types`;

    try {
      const listingType = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      console.log("all listings: ", listingType);
      let listing_type = listingType?.data?.data?.data || [];

      let rowsData = listing_type?.map((row, index) => {
        return {
          value: row?.name,
          label: row?.name,
        };
      });

      setListingType(rowsData);

      setDropDownLoading(false);
    } catch (error) {
      console.log("listings not fetched. ", error);
      toast.error("Unable to fetch data.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setDropDownLoading(false);
    }
  };

  const fetchCountries = () => {
    // if (countryList.length > 0) {
    //   return;
    // }
    setDropDownLoading(true);
    axios
      .get(`${BACKEND_URL}/countries`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        console.log("countries list : ", result);

        const data = result?.data?.data;

        const rows = data?.map((data) => ({
          value: data?.id,
          label: data?.name,
        }));
        setCountryList(rows);
        setDropDownLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setDropDownLoading(false);
        console.log(err);
        const errors = err.response?.data?.errors;

        if (errors) {
          const errorMessages = Object.values(errors).flat().join(" ");
          toast.error(`Errors: ${errorMessages}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Unable to fetch countries", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  const FetchCitynState = () => {
    setDropDownLoading(true);

    const country_id = filters?.country;

    const citiesApi = axios
      .get(`${BACKEND_URL}/cities`, {
        params: { country_id },
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((citiesResult) => {
        const cityData = citiesResult?.data?.data || [];
        const cityRows = cityData.map((city) => ({
          value: city?.id,
          label: city?.name,
        }));
        setCityList(cityRows);
      })
      .catch((err) => {
        console.error("Error fetching cities: ", err);
        setCityList([]);
        handleError(err, "city");
      });

    const statesApi = axios
      .get(`${BACKEND_URL}/states`, {
        params: { country_id },
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((statesResult) => {
        const stateData = statesResult?.data?.data || [];
        const stateRows = stateData.map((state) => ({
          value: state?.id,
          label: state?.name,
        }));
        setStateList(stateRows);
      })
      .catch((err) => {
        console.error("Error fetching states: ", err);
        setStateList([]);
        handleError(err, "state");
      });

    Promise.all([citiesApi, statesApi]).finally(() => {
      setDropDownLoading(false);
    });
  };

  const handleError = (err, type) => {
    const error = err.response?.data?.message;
    if (error) {
      toast.error(`${type === "city" ? "City" : "State"} error: ${error}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(`Unable to fetch ${type}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // open listing modal
  const handleOpenListingModal = () => {
    setListingModalOpen(true);
  };

  const HandleOpenModel = () => {
    console.log("Model Open:");
    setModel(true);
  };

  const HandleModelClose = () => {
    console.log("Model Close:");

    setModel(false);
  };

  useEffect(() => {
    if (filters && !filters?.location) {
      SearchListings(currentPage);
    }
  }, [filters, currentPage]);

  // useEffect(() => {
  //   if (
  //     (searchQuery && searchQuery.length >= 3) ||
  //     (filters?.location && filters?.location?.length >= 3)
  //   ) {
  //     SearchListings(currentPage);
  //   }
  // }, [searchQuery, filters]);

  // useEffect(() => {
  //   const hasValidSearchQuery = searchQuery && searchQuery.length >= 3;
  //   const hasValidLocationFilter =
  //     filters?.location && filters?.location?.length >= 3 ? true : false;

  //   console.log("locations Filter valid: ", hasValidLocationFilter);
  //   console.log("search query valid: ", hasValidSearchQuery);
  //   console.log("search query length: ", searchQuery.length);

  //   // API will be called if either searchQuery or filters.location has at least 2 characters, or if other filters are applied
  //   const shouldFetchListings =
  //     hasValidSearchQuery || hasValidLocationFilter || isFilterApplied;

  //   console.log("should fetch listings: ", shouldFetchListings);

  //   if (shouldFetchListings) {
  //     SearchListings(currentPage);
  //   }
  // }, [searchQuery, filters, currentPage]);

  const debouncedSearch = debounce(() => {
    if (
      (searchQuery && searchQuery.length >= 3) ||
      (filters?.location && filters?.location?.length >= 3)
    ) {
      SearchListings(currentPage); // Call the API when the conditions are met
    } else if (
      !searchQuery ||
      searchQuery.length == 0 ||
      !filters?.location ||
      filters?.location?.length == 0
    ) {
      SearchListings(currentPage); // Handle the case when the search is cleared
    }
  }, 600); // 300ms delay before calling the API

  // Effect that runs whenever searchQuery or filters change
  useEffect(() => {
    debouncedSearch();

    // Cleanup the debounce on unmount or re-run
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchQuery, filters]);

  const searchCriteriaOptions = [
    { value: "project", label: t("label_project") },
    { value: "developer", label: t("form_developer_name") },
  ];

  const sortOptions = [
    // { value: "price", label: t("label_price") },
    // { value: "id", label: t("label_id") },
    { value: "price_low", label: t("label_price_high_to_low") },
    { value: "price_high", label: t("label_price_low_to_high") },
    { value: "latest", label: t("label_latest") },
    { value: "oldest", label: t("label_oldest") },
  ];
  const orderOptions = [
    { value: "asc", label: t("order_asc") },
    { value: "desc", label: t("order_desc") },
  ];

  return (
    <>
      <div className="flex min-h-screen ">
        <div
          className={`w-full p-4 ${
            !themeBgImg && (currentMode === "dark" ? "bg-black" : "bg-white")
          }`}
        >
          <div className="">
            {model && (
              <AddUserModel
                handleOpenModel={HandleOpenModel}
                addUserModelClose={HandleModelClose}
              />
            )}
            <div className="flex justify-between items-center ">
              <div className="flex items-center pb-3">
                <div className="bg-primary h-10 w-1 rounded-full"></div>
                <h1
                  className={`text-lg font-semibold mx-2 uppercase ${
                    currentMode === "dark" ? "text-white" : "text-black"
                  }`}
                >
                  {t("listings")}{" "}
                  <span className="bg-primary text-white px-3 py-1 rounded-sm my-auto">
                    {total}
                  </span>
                </h1>
              </div>

              <div className="mr-4">
                <Button
                  fullWidth
                  sx={{ mt: 1 }}
                  variant="contained"
                  className="bg-btn-primary flex items-center gap-x-3 px-3"
                  size="small"
                  onClick={handleOpenListingModal}
                >
                  <BsBuildingAdd size={16} />
                  {t("btn_add_new_listing")}
                </Button>
              </div>

              {listingModalOpen && (
                <AddNewListingModal
                  // LeadData={LeadData}
                  handleCloseListingModal={handleCloseListingModal}
                  setListingModalOpen={setListingModalOpen}
                  FetchListings={SearchListings}
                />
              )}
            </div>
            <div className={`flex items-center justify-between`}>
              <Box className={`pt-3 border-t-1 overflow-hidden`}>
                <Box
                  className="flex flex-wrap gap-3 items-center mb-5"
                  sx={darkModeColors}
                >
                  {" "}
                  {/* <TextField
                    className="w-[200px]"
                    label="Search"
                    size="small"
                    placeholder="City, area, Project, Neighborhood"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <BsSearch
                            color={
                              currentMode == "dark" ? "#ffffff" : "#000000"
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                  /> */}
                  {/* SEARCH */}
                  <TextField
                    className={`min-w-[200px]`}
                    // label="Search"
                    size="small"
                    placeholder={t("search")}
                    sx={{
                      ".css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root": {
                        paddingLeft: isLangRTL(i18n.language)
                          ? "10px !important"
                          : "0px !important",
                        paddingRight: isLangRTL(i18n.language)
                          ? "0px !important"
                          : "10px !important",
                      },
                      "& .MuiInputBase-root": {
                        backgroundColor:
                          themeBgImg &&
                          (currentMode === "dark"
                            ? blurDarkColor
                            : blurLightColor),
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <BsSearch
                            color={
                              currentMode === "dark" ? "#EEEEEE" : "#333333"
                            }
                          />
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <Box
                          sx={{
                            minWidth: "100px",
                            padding: 0,
                            marginLeft: isLangRTL(i18n.language)
                              ? "10px"
                              : "0px",
                            marginRight: isLangRTL(i18n.language)
                              ? "0px"
                              : "10px",
                          }}
                        >
                          <Select
                            value={searchCriteriaOptions.find(
                              (option) => option.value === searchCriteria
                            )}
                            onChange={handleSearchCriteriaChange}
                            options={searchCriteriaOptions}
                            placeholder={t("label_select")}
                            className={`w-full p-0 ${
                              !themeBgImg
                                ? currentMode === "dark"
                                  ? "bg-[#333333]"
                                  : "bg-[#DDDDDD]"
                                : currentMode === "dark"
                                ? "blur-bg-dark"
                                : "blur-bg-light"
                            } `}
                            menuPortalTarget={document.body}
                            styles={selectBgStyles(
                              currentMode,
                              primaryColor,
                              blurDarkColor,
                              blurLightColor
                            )}
                          />
                        </Box>
                      ),
                    }}
                    variant="outlined"
                    onChange={handleSearchQueryChange}
                    value={searchQuery}
                  />
                  {/* LOCATION */}
                  <TextField
                    className={`min-w-[200px]`}
                    // label="Search"
                    size="small"
                    type="text"
                    placeholder={t("form_project_location")}
                    sx={{
                      ".css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root": {
                        paddingLeft: isLangRTL(i18n.language)
                          ? "10px !important"
                          : "0px !important",
                        paddingRight: isLangRTL(i18n.language)
                          ? "0px !important"
                          : "10px !important",
                      },
                      "& .MuiInputBase-root": {
                        backgroundColor:
                          themeBgImg &&
                          (currentMode === "dark"
                            ? blurDarkColor
                            : blurLightColor),
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CiLocationOn
                            color={
                              currentMode === "dark" ? "#EEEEEE" : "#333333"
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    onChange={(e) => {
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        location: e.target.value,
                      }));
                    }}
                    value={filters?.location}
                  />
                  {/* MIN PRICE */}
                  <TextField
                    className={`min-w-[200px]`}
                    // label="Search"
                    size="small"
                    type="number"
                    placeholder={t("min_price")}
                    sx={{
                      ".css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root": {
                        paddingLeft: isLangRTL(i18n.language)
                          ? "10px !important"
                          : "0px !important",
                        paddingRight: isLangRTL(i18n.language)
                          ? "0px !important"
                          : "10px !important",
                      },
                      "& .MuiInputBase-root": {
                        backgroundColor:
                          themeBgImg &&
                          (currentMode === "dark"
                            ? blurDarkColor
                            : blurLightColor),
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CiDollar
                            color={
                              currentMode === "dark" ? "#EEEEEE" : "#333333"
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    onChange={(e) => {
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        min_price: e.target.value,
                      }));
                    }}
                    value={filters?.min_price}
                  />
                  {/* MAX PRICE */}
                  <TextField
                    className={`min-w-[150px]`}
                    // label="Search"
                    size="small"
                    type="number"
                    placeholder={t("max_price")}
                    sx={{
                      ".css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root": {
                        paddingLeft: isLangRTL(i18n.language)
                          ? "10px !important"
                          : "0px !important",
                        paddingRight: isLangRTL(i18n.language)
                          ? "0px !important"
                          : "10px !important",
                      },
                      "& .MuiInputBase-root": {
                        backgroundColor:
                          themeBgImg &&
                          (currentMode === "dark"
                            ? blurDarkColor
                            : blurLightColor),
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CiDollar
                            color={
                              currentMode === "dark" ? "#EEEEEE" : "#333333"
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    onChange={(e) => {
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        max_price: e.target.value,
                      }));
                    }}
                    value={filters?.max_price}
                  />
                  {/* COUNTRY  */}
                  {/* <Box sx={{ minWidth: "120px" }}>
                    <Select
                      id="country"
                      value={
                        filters?.country
                          ? countryList?.find(
                              (option) => option.id === filters?.country
                            )
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFilters({
                          ...filters,
                          country: selectedOption?.value || null,
                        })
                      }
                      options={countryList}
                      placeholder={t("label_country")}
                      className={`w-full`}
                      isClearable
                      isLoading={dropDownLoading}
                      menuPortalTarget={document.body}
                      styles={{
                        ...selectBgStyles(
                          currentMode,
                          primaryColor,
                          blurDarkColor,
                          blurLightColor
                        ),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          display: filters?.country ? "none" : "block",
                        }),
                        clearIndicator: (provided) => ({
                          ...provided,
                          display: filters?.country ? "block" : "none",
                        }),
                      }}
                      onMenuOpen={fetchCountries}
                    />
                  </Box> */}
                  {/* STATE  */}
                  {/* <Box sx={{ minWidth: "120px" }}>
                    <Select
                      id="state"
                      value={
                        filters?.state
                          ? stateList?.find(
                              (option) => option.id === filters?.state
                            )
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFilters({
                          ...filters,
                          state: selectedOption?.value || null,
                        })
                      }
                      options={stateList}
                      placeholder={t("state")}
                      className={`w-full`}
                      isClearable
                      isLoading={dropDownLoading}
                      menuPortalTarget={document.body}
                      styles={{
                        ...selectBgStyles(
                          currentMode,
                          primaryColor,
                          blurDarkColor,
                          blurLightColor
                        ),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          display: filters?.state ? "none" : "block",
                        }),
                        clearIndicator: (provided) => ({
                          ...provided,
                          display: filters?.state ? "block" : "none",
                        }),
                      }}
                      onMenuOpen={FetchCitynState}
                    />
                  </Box> */}
                  {/* CITY  */}
                  {/* <Box sx={{ minWidth: "120px" }}>
                    <Select
                      id="city"
                      value={
                        filters?.city
                          ? cityList?.find(
                              (option) => option.id === filters?.city
                            )
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFilters({
                          ...filters,
                          city: selectedOption?.value || null,
                        })
                      }
                      options={cityList}
                      placeholder={t("label_city")}
                      className={`w-full`}
                      isClearable
                      isLoading={dropDownLoading}
                      menuPortalTarget={document.body}
                      styles={{
                        ...selectBgStyles(
                          currentMode,
                          primaryColor,
                          blurDarkColor,
                          blurLightColor
                        ),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          display: filters?.city ? "none" : "block",
                        }),
                        clearIndicator: (provided) => ({
                          ...provided,
                          display: filters?.city ? "block" : "none",
                        }),
                      }}
                      onMenuOpen={FetchCitynState}
                    />
                  </Box> */}
                  {/* SORT BY  */}
                  <Box sx={{ minWidth: "120px" }}>
                    <Select
                      id="sortby"
                      value={
                        filters?.sortby
                          ? sortOptions.find(
                              (option) => option.value === filters?.sortby
                            )
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFilters({
                          ...filters,
                          sortby: selectedOption?.value || null,
                        })
                      }
                      options={sortOptions}
                      placeholder={t("label_sort_by")}
                      className={`w-full`}
                      isClearable
                      menuPortalTarget={document.body}
                      styles={{
                        ...selectBgStyles(
                          currentMode,
                          primaryColor,
                          blurDarkColor,
                          blurLightColor
                        ),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          display: filters?.sortby ? "none" : "block",
                        }),
                        clearIndicator: (provided) => ({
                          ...provided,
                          display: filters?.sortby ? "block" : "none",
                        }),
                      }}
                    />
                  </Box>
                  {/* ORDER BY  */}
                  {/* <Box sx={{ minWidth: "120px" }}>
                    <Select
                      id="sortby"
                      value={
                        filters?.order
                          ? orderOptions.find(
                              (option) => option.value === filters?.order
                            )
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFilters({
                          ...filters,
                          order: selectedOption?.value || null,
                        })
                      }
                      options={orderOptions}
                      placeholder={t("order")}
                      className={`w-full`}
                      isClearable
                      menuPortalTarget={document.body}
                      styles={{
                        ...selectBgStyles(
                          currentMode,
                          primaryColor,
                          blurDarkColor,
                          blurLightColor
                        ),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          display: filters?.order ? "none" : "block",
                        }),
                        clearIndicator: (provided) => ({
                          ...provided,
                          display: filters?.order ? "block" : "none",
                        }),
                      }}
                    />
                  </Box> */}
                  {/* PROPERTY TYPE  */}
                  <Box sx={{ minWidth: "120px" }}>
                    <Select
                      id="property_type"
                      value={
                        filters?.property_type
                          ? listings_type?.find(
                              (option) => option.id === filters?.property_type
                            )
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFilters({
                          ...filters,
                          property_type: selectedOption?.value || null,
                        })
                      }
                      options={listings_type}
                      placeholder={t("label_property_type")}
                      className={`w-full`}
                      isClearable
                      isLoading={dropDownLoading}
                      menuPortalTarget={document.body}
                      styles={{
                        ...selectBgStyles(
                          currentMode,
                          primaryColor,
                          blurDarkColor,
                          blurLightColor
                        ),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          display: filters?.property_type ? "none" : "block",
                        }),
                        clearIndicator: (provided) => ({
                          ...provided,
                          display: filters?.property_type ? "block" : "none",
                        }),
                      }}
                      onMenuOpen={FetchListingType}
                    />
                  </Box>
                  {/* BEDROOMS  */}
                  <Box sx={{ minWidth: "120px" }}>
                    <Select
                      id="bedrooms"
                      value={
                        filters?.bedrooms
                          ? enquiry_options(t).find(
                              (option) => option.value === filters?.bedrooms
                            )
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFilters({
                          ...filters,
                          bedrooms: selectedOption?.value || null,
                        })
                      }
                      options={enquiry_options(t)}
                      placeholder={t("label_beds")}
                      className={`w-full`}
                      isClearable
                      menuPortalTarget={document.body}
                      styles={{
                        ...selectBgStyles(
                          currentMode,
                          primaryColor,
                          blurDarkColor,
                          blurLightColor
                        ),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          display: filters?.bedrooms ? "none" : "block",
                        }),
                        clearIndicator: (provided) => ({
                          ...provided,
                          display: filters?.bedrooms ? "block" : "none",
                        }),
                      }}
                    />
                  </Box>
                  {/* BATHROOMS  */}
                  <Box sx={{ minWidth: "120px" }}>
                    <Select
                      id="bathrooms"
                      value={
                        filters?.bathrooms
                          ? bathroom_options(t).find(
                              (option) => option.value === filters?.bathrooms
                            )
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFilters({
                          ...filters,
                          bathrooms: selectedOption?.value || null,
                        })
                      }
                      options={bathroom_options(t)}
                      placeholder={t("label_baths")}
                      className={`w-full`}
                      isClearable
                      menuPortalTarget={document.body}
                      styles={{
                        ...selectBgStyles(
                          currentMode,
                          primaryColor,
                          blurDarkColor,
                          blurLightColor
                        ),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          display: filters?.bathrooms ? "none" : "block",
                        }),
                        clearIndicator: (provided) => ({
                          ...provided,
                          display: filters?.bathrooms ? "block" : "none",
                        }),
                      }}
                    />
                  </Box>
                  {/* LISTING TYPE  */}
                  <Box sx={{ minWidth: "120px" }}>
                    <Select
                      id="listing_type"
                      value={
                        filters?.listing_type
                          ? listing_options(t).find(
                              (option) =>
                                option.value?.toLowerCase() ===
                                filters?.listing_type?.toLowerCase()
                            )
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFilters({
                          ...filters,
                          listing_type: selectedOption?.value || null,
                        })
                      }
                      options={listing_options(t)}
                      placeholder={t("label_listing_type")}
                      className={`w-full`}
                      isClearable
                      menuPortalTarget={document.body}
                      styles={{
                        ...selectBgStyles(
                          currentMode,
                          primaryColor,
                          blurDarkColor,
                          blurLightColor
                        ),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          display: filters?.listing_type ? "none" : "block",
                        }),
                        clearIndicator: (provided) => ({
                          ...provided,
                          display: filters?.listing_type ? "block" : "none",
                        }),
                      }}
                    />
                  </Box>
                  {/* LISTING STATUS  */}
                  <Box
                    sx={{
                      ...darkModeColors,
                      minWidth: "120px",
                      "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                        {
                          right: isLangRTL(i18n.language)
                            ? "2.5rem"
                            : "inherit",
                          transformOrigin: isLangRTL(i18n.language)
                            ? "right"
                            : "left",
                        },
                      "& legend": {
                        textAlign: isLangRTL(i18n.language) ? "right" : "left",
                      },
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        marginLeft: "8px",
                        color: currentMode == "dark" ? "white" : "black",
                      }}
                      control={
                        <Switch
                          size="small"
                          // defaultChecked={filters?.is_featured || 0}
                          // value={filters?.is_featured}
                          checked={filters?.is_featured === 1}
                          onChange={(e) => {
                            const value = e.target.value;
                            const isSelected =
                              filters?.is_featured == 1 ? 0 : 1;
                            setFilters({
                              ...filters,
                              is_featured: isSelected,
                            });
                          }}
                          sx={{
                            // marginBottom: "20px",
                            color: "green !important",
                            "& .MuiSwitch-thumb": {
                              color:
                                filters?.is_featured == 1
                                  ? "green !important"
                                  : "#B91C1C !important",
                            },
                            "& .Mui-checked": {
                              color:
                                filters?.is_featured == 1
                                  ? "green !important"
                                  : "#B91C1C !important",
                            },
                            "& .MuiSwitch-track": {
                              backgroundColor:
                                filters?.is_featured == 1
                                  ? "green !important"
                                  : "#B91C1C !important",
                            },
                            "& .css-1q0bjt2-MuiSwitch-root .MuiSwitch-thumb": {
                              backgroundColor:
                                filters?.is_featured == 1
                                  ? "green !important"
                                  : "#B91C1C !important",
                            },
                          }}
                        />
                      }
                      label={t("label_is_featured")}
                    />
                    <FormControlLabel
                      sx={{
                        // marginBottom: "20px",
                        color: currentMode == "dark" ? "white" : "black",
                      }}
                      control={
                        <Switch
                          size="small"
                          // defaultChecked={1}
                          // value={filters?.listing_status}
                          checked={filters?.listing_status == 1 ? true : false}
                          onChange={(e) => {
                            const value = e.target.value;
                            console.log("listing value: ", e);
                            // const isSelected = value == 1 ? 0 : 1;
                            const isSelected =
                              filters?.listing_status == 1 ? 0 : 1;
                            setFilters({
                              ...filters,
                              listing_status: isSelected,
                            });
                          }}
                          sx={{
                            // marginBottom: "20px",
                            color: "green !important",
                            "& .MuiSwitch-thumb": {
                              color:
                                filters?.listing_status == 1
                                  ? "green !important"
                                  : "#B91C1C !important",
                            },
                            "& .Mui-checked": {
                              color:
                                filters?.listing_status == 1
                                  ? "green !important"
                                  : "#B91C1C !important",
                            },
                            "& .MuiSwitch-track": {
                              backgroundColor:
                                filters?.listing_status == 1
                                  ? "green !important"
                                  : "#B91C1C !important",
                            },
                            "& .css-1q0bjt2-MuiSwitch-root .MuiSwitch-thumb": {
                              backgroundColor:
                                filters?.listing_status == 1
                                  ? "green !important"
                                  : "#B91C1C !important",
                            },
                          }}
                        />
                      }
                      label={t("status")}
                    />
                  </Box>
                  {(isFilterApplied || searchQuery) && (
                    <Button
                      onClick={clearFilter}
                      className="w-max btn py-2 px-3 bg-btn-primary"
                    >
                      {t("clear")}
                    </Button>
                  )}
                </Box>
              </Box>
            </div>
            <SecondaryListings
              listing={listing}
              setCurrentPage={setCurrentPage}
              setPageBeingScrolled={setPageBeingScrolled}
              currentPage={currentPage}
              lastPage={lastPage}
              FetchListings={SearchListings}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Listings;

import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { IoSearchOutline } from "react-icons/io5";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { useStateContext } from "../../context/ContextProvider";
export default function AccountMenu({
  anchorEl,
  setAnchorEl,
  name,
  accounts,
  isAdsAccounts,
}) {
  const open = Boolean(anchorEl);
  const {
    currentMode,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    setSnapchatCredentials,
  } = useStateContext();

  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? "bg-dark text-white"
      : "bg-white text-black";
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          "& .MuiList-root": {
            padding: "0px !important", // Modify border radius
          },
          padding: "0px",
        }}
      >
        <div className={`w-[280px] ${getSummaryBgClass()} py-4`}>
          <div className="flex items-center gap-3 px-4 py-3">
            <IoSearchOutline size={16} />
            <input
              type="text"
              className={`focus:outline-none border-none ${getSummaryBgClass()}`}
              placeholder={`Filter ${name}...`}
            />
          </div>
          {/* bg-[#F9F9FA] */}
          <div
            className={`flex justify-between items-center  ${getSummaryBgClass()} px-4 py-3`}
          >
            <span>{name}</span>
            <button>
              <MdOutlineKeyboardArrowDown />
            </button>
          </div>
          <ul className="py-4 px-4 pb-5">
            {accounts?.map((account) => {
              return (
                <li
                  className="flex gap-2 items-center"
                  onClick={() =>
                    setSnapchatCredentials((pre) => ({
                      ...pre,
                      ...(isAdsAccounts
                        ? { currentAdAccount: account }
                        : { currentOrganization: account }),
                    }))
                  }
                >
                  <div
                    className={`${
                      account?.id == snapchatCredentials?.currentAdAccount?.id
                        ? "visible"
                        : "invisible"
                    }`}
                  >
                    <IoIosArrowDroprightCircle color="#f7d901" size={16} />
                  </div>

                  {account?.name}
                </li>
              );
            })}
          </ul>
          <div className="px-4 border-t border-gray-300 pt-4">
            <h3 className="text-[14px] font-medium">
              {snapchatCredentials?.name}
            </h3>
            <p>
              {isAdsAccounts
                ? snapchatCredentials?.currentAdAccount?.name
                : snapchatCredentials?.organizations &&
                  snapchatCredentials?.organizations[0]?.name}
            </p>
          </div>
        </div>
      </Menu>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { Checkbox, Switch } from "@mui/material";
import { useStateContext } from "../../context/ContextProvider";
import axios from "../../axoisConfig";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineDelete } from "react-icons/md";
import DeleteConfirm from "./DeleteConfirmation";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
// import { deliveryStatus } from "./deliveryStatuses";

const token = localStorage?.getItem("auth-token");

const deliveryStatus = [
  {
    code: "INVALID_NOT_EFFECTIVE_ACTIVE",
    label: "Campaign is inactive",
  },
  {
    code: "INVALID_NOT_ACTIVE",
    label: "Not Devlivering",
  },
];

// Columns definition

const CompaignsTable = ({
  setIsSingleCampaign,
  setCampaignDetails,
  oldObjectives,
  compaigns,
  setComapaigns,
  getAllCompaigns,
  selectedRows = { selectedRows },
  setSelectedRows = { setSelectedRows },
}) => {
  // const [compaigns, setComapaigns] = useState([]);
  const {
    currentMode,
    primaryColor,
    t,
    BACKEND_URL,
    snapchatCredentials,
    DataGridStyles,
  } = useStateContext();
  const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false);

  async function deleteCampaign(id) {
    try {
      const res = await axios.post(
        `${BACKEND_URL}/snapchat/delete/campaign`,
        {
          access_token: snapchatCredentials?.access_token,
          campaign_id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      getAllCompaigns();
      toast.success("Campaign is successfully deleted", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.log(error, "error");
      toast.error("Campaign can't be deleted", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
  async function updateCampaign(campaignDetails, status) {
    console.log(campaignDetails, "details");
    try {
      await axios.post(
        `${BACKEND_URL}/snapchat/update/campaign`,
        {
          campaign_id: campaignDetails?.id,
          access_token: snapchatCredentials?.access_token,
          name: campaignDetails?.name,
          ad_account_id: snapchatCredentials?.currentAdAccount?.id,
          status: status ? "ACTIVE" : "PAUSED",
          start_time: campaignDetails?.start_time,
          objective: campaignDetails?.objective,
          ...(campaignDetails?.end_time && {
            end_time: campaignDetails.end_time,
          }),
          ...(campaignDetails?.daily_budget_micro && {
            daily_budget_micro: campaignDetails?.daily_budget_micro / 1000000,
          }),
          ...(campaignDetails?.lifetime_spend_cap_micro && {
            lifetime_spend_cap_micro:
              campaignDetails?.lifetime_spend_cap_micro / 1000000,
          }),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      toast.success(" Campaign is Successfully Updated", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      getAllCompaigns();
    } catch (error) {
      toast.error("Unable to update campaign", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(error, "this is an error");
      if (error.response?.data?.errors) {
        Object.values(error.response?.data?.errors)
          .flat()
          .map((err) =>
            toast.error(err, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            })
          );
      }
    }
  }

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerAlign: "left",
      headerClassName: "pl-5",
      renderHeader: (params) => {
        return (
          <div className="pl-12 font-semibold">{params.colDef.headerName}</div>
        ); // Apply padding manually
      },
      renderCell: (params) => (
        <Name
          value={params?.value}
          onEdit={() => {
            setIsSingleCampaign(
              oldObjectives?.awareness?.find(
                (obj) => obj.identifier == params?.row?.objective
              ) ||
                oldObjectives?.consideration?.find(
                  (obj) => obj.identifier == params?.row?.objective
                ) ||
                oldObjectives?.conversions?.find(
                  (obj) => obj.identifier == params?.row?.objective
                )
            );
            setCampaignDetails(params?.row);
          }}
          onDelete={() => {
            // deleteCampaign(params?.row?.id,params?.value);
            setOpenDeleteConfirm({ id: params?.row?.id, name: params?.value });
          }}
        />
      ),
      minWidth: 250,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      minWidth: 150,
      renderCell: (params) => (
        <Switch
          checked={params.row.status == "PAUSED" ? false : true}
          onChange={(e) => updateCampaign(params?.row, e?.target?.checked)}
        />
      ),
    },
    {
      field: "delivery_status",
      headerName: "Delivery Status",
      headerAlign: "center",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div>
            {params?.value?.map(
              (val) =>
                deliveryStatus?.find((status) => status?.code == val)
                  ?.label && (
                  <div>
                    {
                      deliveryStatus?.find((status) => status?.code == val)
                        ?.label
                    }
                  </div>
                )
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <Box
          className={`closed-datatable ${currentMode}-mode-datatable`}
          sx={{
            ...DataGridStyles,
            position: "relative",
            marginBottom: "50px",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <DataGrid
            rows={compaigns}
            columns={columns}
            pageSize={5}
            checkboxSelection
            disableSelectionOnClick
            autoHeight
            selectionModel={selectedRows?.campaigns}
            onSelectionModelChange={(rows) => {
              setSelectedRows((pre) => ({ ...pre, campaigns: rows }));
              console.log(rows, "rows");
            }}
          />
        </Box>
      </div>
      <DeleteConfirm
        open={openDeleteConfirm}
        setOpen={setOpenDeleteConfirm}
        message={` Are you sure you want to delete campaign ${openDeleteConfirm?.name}? `}
        onDelete={deleteCampaign}
        title="Confirm Delete Campaign"
      />
    </>
  );
};

export default CompaignsTable;

const Name = ({ value, onEdit, onDelete }) => {
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const { currentMode } = useStateContext();
  return (
    <>
      <div
        className="pl-11 w-full text-left"
        onMouseEnter={() => setIsMouseEnter(true)}
        onMouseLeave={() => setIsMouseEnter(false)}
      >
        <p>{value}</p>
        {isMouseEnter && (
          <div className="flex gap-3">
            <button
              className={`mt-2 ${
                currentMode === "dark"
                  ? "text-white hover:text-gray-600"
                  : "text-gray-600 hover:text-gray-900"
              } hover:text-gray-900`}
              onClick={onEdit}
            >
              Edit
            </button>
            <button
              className={`mt-2 ${
                currentMode === "dark"
                  ? "text-white hover:text-gray-600"
                  : "text-gray-600 hover:text-gray-900"
              }  flex gap-2 items-center`}
              onClick={onDelete}
            >
              Delete <MdOutlineDelete size={16} />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

import React from 'react'
import { useStateContext } from '../../context/ContextProvider';
//for fresh pull
import {
    Backdrop,
    Box,
    CircularProgress,
    IconButton,
    Modal,
    TextField,
  } from "@mui/material";
import { Button } from '@material-tailwind/react';
import { IoMdClose } from 'react-icons/io';
  const style = {
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
  };
const EditAutomationModal = ({
  workFlowSaveModal, setWorkFlowSaveModal, saveWorkFlow,saveMetaData,metaAutomation,setMetaAutomation
}) => {
    const {isLangRTL,currentMode,i18n,darkModeColors,t,themeBgImg} = useStateContext()
  return (
    <Modal
    keepMounted
    open={workFlowSaveModal}
    onClose={() => setWorkFlowSaveModal(null)}
    aria-labelledby="keep-mounted-modal-title"
    aria-describedby="keep-mounted-modal-description"
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
   
      <div
        style={style}
        className={`w-[calc(100%-20px)] md:w-[45%]  ${
          currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
        } absolute top-1/2 left-1/2 p-6 rounded-[12px]`}
      >
         <Box    
      sx={{
        ...darkModeColors,
        "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
          {
            right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
            transformOrigin: isLangRTL(i18n.language) ? "right" : "left",
          },
        "& legend": {
          textAlign: isLangRTL(i18n.language) ? "right" : "left",
        },
      }}
      className={`flex flex-col gap-[30px] w-full bg-transparent`}
    >
      
       <IconButton
            sx={{
              position: "absolute",
              right: 12,
              top: 10,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={() => setWorkFlowSaveModal(false)}
          >
            
            <IoMdClose size={18} />
          </IconButton>
         <div className='flex flex-col gap-3 bg-transparent'>
            <h3 className={`text-[16px] w-full text-center p-2 ${currentMode ==="dark"?"text-white":"text-black"}`}>
              Set Meta Information
            </h3>
           {/* <textarea rows={3} value={metaAutomation?.greeting} onChange={(e)=>{setMetaAutomation((pre)=>({
            ...pre,
            greeting:e?.target?.value
           }))}} className={`focus:outline-none border-[1px] rounded-md p-3 ${currentMode==="dark"?"bg-[#1c1c1c] text-white border-white":"bg-white border-gray-500 hover:border-gray-950 focus:border-blue-600 "}`}>
           
            </textarea> */}
            </div>
            
        <div className='flex gap-3 '>
            <TextField
                          id="question"
                          type={"number"}
                          label={t("add_res_delay")}
                          className="flex-1"
                          style={{
                          }}
                          value={metaAutomation?.delay_seconds}
                          onChange={(e)=>{setMetaAutomation((pre)=>({
                            ...pre,
                            delay_seconds:e?.target?.value
                           }))}}
                          inputProps={{ min: 0, max: 3 }} 
                          sx={{
                            ...darkModeColors,
                            "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                              {
                                right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                                transformOrigin: isLangRTL(i18n.language)
                                  ? "right"
                                  : "left",
                              },
                            "& legend": {
                              textAlign: isLangRTL(i18n.language) ? "right" : "left",
                            },
                          }}
                          variant="outlined"
                          size="small"
                        />
            </div>
            <TextField
                          id="question"
                          type={"text"}
                          label={t("Exception Message")}
                          className="flex-1"
                          value={metaAutomation?.exception}
                          onChange={(e)=>{setMetaAutomation((pre)=>({
                            ...pre,
                            exception:e?.target?.value
                           }))}}
                          style={{
                          }}
                          sx={{
                            ...darkModeColors,
                            "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                              {
                                right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                                transformOrigin: isLangRTL(i18n.language)
                                  ? "right"
                                  : "left",
                              },
                            "& legend": {
                              textAlign: isLangRTL(i18n.language) ? "right" : "left",
                            },
                          }}
                          variant="outlined"
                          size="small"
                        />
                       <TextField
                          id="question"
                          type={"text"}
                          label={t("Ask for Email")}
                          className="flex-1"
                          value={metaAutomation?.ask_for_email}
                          onChange={(e)=>{setMetaAutomation((pre)=>({
                            ...pre,
                            ask_for_email:e?.target?.value
                           }))}}
                          style={{
                          }}
                          sx={{
                            ...darkModeColors,
                            "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                              {
                                right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                                transformOrigin: isLangRTL(i18n.language)
                                  ? "right"
                                  : "left",
                              },
                            "& legend": {
                              textAlign: isLangRTL(i18n.language) ? "right" : "left",
                            },
                          }}
                          variant="outlined"
                          size="small"
                        />
            <TextField
                          id="question"
                          type={"text"}
                          label={t("Ask for Contact Number")}
                          className="flex-1"
                          value={metaAutomation?.ask_for_contact}
                          onChange={(e)=>{setMetaAutomation((pre)=>({
                            ...pre,
                            ask_for_contact:e?.target?.value
                           }))}}
                          style={{
                          }}
                          sx={{
                            ...darkModeColors,
                            "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                              {
                                right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                                transformOrigin: isLangRTL(i18n.language)
                                  ? "right"
                                  : "left",
                              },
                            "& legend": {
                              textAlign: isLangRTL(i18n.language) ? "right" : "left",
                            },
                          }}
                          variant="outlined"
                          size="small"
                        />
            <TextField
                          id="question"
                          type={"text"}
                          label={t("Thanks Message")}
                          className="flex-1"
                          value={metaAutomation?.thanks_message}
                          onChange={(e)=>{setMetaAutomation((pre)=>({
                            ...pre,
                            thanks_message:e?.target?.value
                           }))}}
                          style={{
                          }}
                          sx={{
                            ...darkModeColors,
                            "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                              {
                                right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                                transformOrigin: isLangRTL(i18n.language)
                                  ? "right"
                                  : "left",
                              },
                            "& legend": {
                              textAlign: isLangRTL(i18n.language) ? "right" : "left",
                            },
                          }}
                          variant="outlined"
                          size="small"
                        />
            <div>
              <div className='w-full flex justify-end'>
              <Button
            onClick={() => {
              saveWorkFlow(true);
              saveMetaData();
              // setWorkFlowSaveModal(false)
            }}
            ripple={true}
            variant="outlined"
            disabled={!metaAutomation?.exception || !metaAutomation?.delay_seconds}
            className={`shadow-none px-5  rounded-lg h-full text-sm  gap-2 bg-black text-white border border-black items-center flex`}
          >
          
            Continue
          </Button>
              </div>
           
            </div>
        </Box>
        </div>
        
        </Modal>
  )
}

export default EditAutomationModal
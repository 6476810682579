import * as React from "react";
import { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { FaPlus, FaRegCircle } from "react-icons/fa";
import { IoIosStar, IoMdAdd } from "react-icons/io";
import { ImStatsBars } from "react-icons/im";
import { IoHelpBuoyOutline, IoPersonOutline } from "react-icons/io5";
import { PiPixLogo } from "react-icons/pi";
import { CgMenuGridO } from "react-icons/cg";
import { BsCart4, BsFolder } from "react-icons/bs";
import { LuFlaskConical, LuFolder, LuLibrary } from "react-icons/lu";
import { GoVideo } from "react-icons/go";
import { RxMagicWand } from "react-icons/rx";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { LiaHomeSolid } from "react-icons/lia";
import { RiTeamLine } from "react-icons/ri";
import { MdPayment } from "react-icons/md";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useStateContext } from "../../context/ContextProvider";
const menu = [
  {
    name: "Advertise",
    links: [
      // {
      //   label: "Create Ads",
      //   icon: <IoMdAdd size={16} />,
      //   identifier: "createAds",
      // },
      {
        label: "Manage Ads",
        icon: <ImStatsBars size={16} />,
        identifier: "manageAds",
      },
      // {
      //   label: "Help Center",
      //   icon: <IoHelpBuoyOutline size={16} />,
      //   identifier: "helpCenter",
      // },
    ],
  },
  // {
  //   name: "Assets",
  //   links: [
  //     {
  //       label: "Events Manager",
  //       icon: <FaRegCircle size={16} />,
  //       identifier: "eventsManager",
  //     },
  //     {
  //       label: "Audiences",
  //       icon: <IoPersonOutline size={16} />,
  //       identifier: "audiences",
  //     },
  //     {
  //       label: "Pixels",
  //       icon: <PiPixLogo size={16} />,
  //       identifier: "pixels",
  //     },
  //     {
  //       label: "Apps",
  //       icon: <CgMenuGridO size={16} />,
  //       identifier: "apps",
  //     },
  //     {
  //       label: "Catalogs",
  //       icon: <BsCart4 size={16} />,
  //       identifier: "catalogs",
  //     },
  //   ],
  // },
  {
    name: "Creative",
    links: [
      {
        label: "Creative Library",
        icon: <LuLibrary size={16} />,
        identifier: "creativeLibrary",
      },
      // {
      //   label: "Lens Folder",
      //   icon: <LuFolder size={16} />,
      //   identifier: "lensFolder",
      // },
      // {
      //   label: "Create Lens and Filter",
      //   icon: <RxMagicWand size={16} />,
      //   identifier: "createLensAndFilter",
      // },
    ],
  },
  // {
  //   name: "Analyze",
  //   links: [
  //     {
  //       label: "Audience Insights",
  //       icon: <IoPersonOutline size={16} />,
  //       identifier: "audienceInsights",
  //     },
  //     {
  //       label: "Reports",
  //       icon: <HiOutlineDocumentReport size={16} />,
  //       identifier: "reports",
  //     },
  //     {
  //       label: "Campaign Lab",
  //       icon: <LuFlaskConical size={16} />,
  //       identifier: "campaignLab",
  //     },
  //   ],
  // },
  {
    name: "Business",
    links: [
      // {
      //   label: "Business Dashboard",
      //   icon: <LiaHomeSolid size={16} />,
      //   identifier: "businessDashboard",
      // },
      {
        label: "Ad Accounts",
        icon: <BsFolder size={16} />,
        identifier: "adAccounts",
      },
      {
        label: "Members",
        icon: <RiTeamLine size={16} />,
        identifier: "members",
      },
      // {
      //   label: "Billing and Payments",
      //   icon: <MdPayment size={16} />,
      //   identifier: "billingAndPayments",
      // },
      // {
      //   label: "Public Profiles",
      //   icon: <IoIosStar size={16} />,
      //   identifier: "publicProfiles",
      // },
    ],
  },
];
console.log(window?.location?.pathname, "pathname");

const HeaderMenu = ({
  anchorEl,
  setAnchorEl,
  currentModule,
  setCurrentModule,
}) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();
  const { themeBgImg, currentMode } = useStateContext();
  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? "bg-dark text-white"
      : "bg-white text-black";
  };
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      PaperProps={{
        sx: {
          borderRadius: "12px", // Custom border radius
          marginTop: "20px", // Add padding to content
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.4)", // Custom shadow
        },
      }}
    >
      <div className="flex rounded-3xl">
        {menu?.map((col) => {
          return (
            <div
              className={`w-[200px] pl-4 py-4 border-r-[1px] border-gray-300 pr-4 ${getSummaryBgClass()}`}
            >
              <h3 className="text-[14px] pb-4 pt-2">{col?.name}</h3>
              <ul className="font-medium flex flex-col gap-4">
                {col?.links?.map((link) => {
                  const isCurrent =
                    window.location.pathname.split("/").pop() ==
                    link?.identifier;

                  return (
                    <li
                      onClick={() => {
                        setCurrentModule(link);
                        setAnchorEl(null);
                      }}
                    >
                      <Link
                        to={`/marketing/snapchat/${link?.identifier}`}
                        className={`flex  cursor-pointer ${
                          isCurrent && "border-[1px] border-[#F7D901]"
                        }  rounded-xl p-3 items-center gap-2 ${
                          currentMode === "dark"
                            ? "hover:bg-gray-900"
                            : "hover:bg-gray-50"
                        }`}
                      >
                        <div
                          className={`p-2 ${
                            isCurrent && "bg-[#f7d901]"
                          }  rounded-md`}
                        >
                          {link?.icon}
                        </div>{" "}
                        {link?.label}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </Popover>
  );
};

export default HeaderMenu;

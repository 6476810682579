import React, { useState } from "react";
import { button, Button } from "@material-tailwind/react";
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
  MenuItem,
  // Select,
} from "@mui/material";
import Select from "react-select";

import { IoMdClose } from "react-icons/io";
import { GoDuplicate } from "react-icons/go";
import GeneralSvg, { AwarenessSvg } from "./generalSvg.js";
import { useStateContext } from "../../context/ContextProvider";
import { MdOutlineCampaign } from "react-icons/md";
import { BsCart4 } from "react-icons/bs";
import { PiCursorFill } from "react-icons/pi";
import { IoFilterOutline } from "react-icons/io5";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import SalesSvg from "./salesSvg.js";
const style = {
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};

const objectives = [
  {
    label: "Awareness & Engagement",
    icon: <MdOutlineCampaign size={16} />,
    banner:
      "https://release-manager.sc-cdn.net/218e1a0c4b913ae4d17c6404d31ae915.svg",
    content: {
      para: "Reach Snapchatters and create interest in your brand",
      Outcomes: {
        label: "good for Awareness & Engagement objective.",
        points: [
          "Brand Awarenes",
          "Video Views",
          "Ad Engagement",
          "Calls & Texts",
          "Place Promotion",
        ],
      },
      AdFormats: {
        label: " available for Awareness & Engagement objective.",
        points: [
          "Signle Image or Video",
          "Story Ad",
          "Collection Ad",
          "AR Ads",
          "Commercial",
        ],
      },
    },
  },
  {
    label: "Traffic",
    icon: <PiCursorFill size={16} />,
    banner: "/assets/traffic.png",
    content: {
      para: "Send Snapchatters to a destination like a website or app",
      Outcomes: {
        label: "good for Traffic objective.",
        points: ["Clicks to Website or App", "Landing Page Views"],
      },
      AdFormats: {
        label: "available for Traffic objective.",
        points: [
          "Signle Image or Video",
          "Story Ad",
          "Collection Ad",
          "AR Ads",
        ],
      },
    },
  },
  {
    label: "Leads",
    icon: <IoFilterOutline size={16} />,
    banner: "/assets/leads.png",
    content: {
      para: "Get Snapchatters to share their contact details with your business.",
      Outcomes: {
        label: "good for Leads objective.",
        points: ["Clicks to Lead Form or Website", "Website Sign Up"],
      },
      AdFormats: {
        label: "available for Leads objective.",
        points: ["Signle Image or Video", "Story Ad", "Collection Ad"],
      },
    },
  },
  {
    label: "App promotion",
    icon: <HiOutlineDevicePhoneMobile size={16} />,
    banner: "/assets/app_promotion.png",
    content: {
      para: "Get Snapchatters to install and take actions in your app",
      Outcomes: {
        label: "good for App Promotion objective.",
        points: [
          "App installs",
          "App Installs + In-App Action",
          "App Re-Engagement",
        ],
      },
      AdFormats: {
        label: "available for App Promotion objective.",
        points: [
          "Signle Image or Video",
          "Story Ad",
          "Collection Ad",
          "AR Ads",
        ],
      },
    },
  },
  {
    label: "Sales",
    icon: <BsCart4 size={16} />,
    banner: "/assets/sales.png",
    content: {
      para: "Get Snapchatters to purchase your product or service.",
      Outcomes: {
        label: "good for Sales objective.",
        points: ["Catalog Sales", "Website Conversions", "App Re-Engagement"],
      },
      AdFormats: {
        label: "available for Sales objective.",
        points: [
          "Signle Image or Video",
          "Story Ad",
          "Collection Ad",
          "AR Ads",
        ],
      },
    },
  },
];

const CreateCampaignModal = ({
  setCreateCampaignModal,
  createCampaignModal,
  setIsSingleCampaign,
  oldObjectives,
  setCurrentCampaignDetails,
}) => {
  const { darkModeColors, currentMode, User, BACKEND_URL, t, primaryColor } =
    useStateContext();
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [hoveredObjective, setHoveredObjective] = useState(null);

  const currentDetails = hoveredObjective || selectedDetails;

  console.log(selectedDetails, "selected details");

  return (
    <Modal
      keepMounted
      open={createCampaignModal}
      onClose={() => setCreateCampaignModal(false)}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        zIndex: 1400,
      }}
    >
      <div
        style={style}
        className={`w-[calc(100%-20px)] md:w-[47%] min-h-[520px]   ${
          // currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
          currentMode === "dark"
            ? "bg-dark-neu text-white"
            : "bg-white text-black"
        } absolute top-1/2 left-1/2 p-5 rounded-md flex flex-col items-start`}
      >
        <h3 className="text-[20px] font-semibold">Choose Objective</h3>
        <p className="py-3">Objective</p>
        {/* 
        <div className="flex flex-1">
          <ul className="pl-4 pt-4 flex-1">
            {objectives?.map((objective) => {
              return (
                <li
                  className="flex gap-4 items-center mb-8 group cursor-pointer"
                  onMouseEnter={() => setHoveredObjective(objective)}
                  onMouseLeave={() => setHoveredObjective(null)}
                  onClick={() => setSelectedDetails(objective)}
                >
                  {" "}
                  <div
                    className={`w-[16px] h-[16px] rounded-full border border-gray-700 ${
                      selectedDetails?.label === objective?.label
                        ? "border-[5px]"
                        : "group-hover:border-[3px]"
                    }  `}
                  ></div>
                  <div className="rounded-xl bg-[#EDEEEF] p-3">
                    {objective.icon}
                  </div>
                  <span className="text-[14px]">{objective?.label}</span>
                </li>
              );
            })}
          </ul>
          <div className="flex-1">
            {currentDetails ? (
              <>
                <img
                  src={currentDetails?.banner}
                  alt={currentDetails?.banner}
                  className="w-[100px] object-cover mb-3"
                />
                <p className="text-[14px] font-medium mb-5">
                  {currentDetails?.content?.para}
                </p>
                <div className="flex items-center gap-3 mb-3">
                  <h4 className="text-[14px[ font-medium">Outcomes</h4>
                  <p>{currentDetails?.content?.Outcomes?.label}</p>
                </div>
                <div className="flex gap-3 flex-wrap mb-3">
                  {currentDetails?.content?.Outcomes?.points?.map((outcome) => (
                    <button className="rounded-xl bg-[#EDEEEF] p-1 px-2">
                      {outcome}
                    </button>
                  ))}
                </div>
                <div className="flex items-center gap-3 mb-3">
                  <h4 className="text-[14px[ font-medium">Ad Formats</h4>
                  <p>{currentDetails?.content?.AdFormats?.label}</p>
                </div>
                <div className="flex gap-3 flex-wrap mb-3">
                  {currentDetails?.content?.AdFormats?.points?.map((format) => (
                    <button className="rounded-xl bg-[#EDEEEF] p-1 px-2">
                      {format}
                    </button>
                  ))}
                </div>
              </>
            ) : (
              <>
                <img src="/assets/general.png" className="w-[100px] " />
                <p>
                  Your campaign objective is the business goal you hope to
                  achieve by running your ads. Hover over each one for more
                  information.
                </p>
              </>
            )}
          </div>
        </div> */}

        <div className="grid grid-cols-3 w-full gap-8">
          <div>
            <span className="font-medium">Awareness</span>
            <ul className="flex flex-col gap-3 mt-3">
              {oldObjectives?.awareness?.map((obj) => {
                return (
                  <li
                    onClick={() => setSelectedDetails(obj)}
                    className={`w-full rounded-xl py-5 cursor-pointer border-[1px]  font-medium px-5 hover:border-[#F7D901] ${
                      selectedDetails?.label === obj?.label
                        ? "!border-[#F7D901]"
                        : "border-gray-300"
                    }`}
                  >
                    {obj?.label}
                  </li>
                );
              })}
            </ul>
          </div>
          <div>
            <span className="font-medium">Consideration</span>
            <ul className="flex flex-col gap-3 mt-3">
              {oldObjectives?.consideration?.map((obj) => {
                return (
                  <li
                    onClick={() => setSelectedDetails(obj)}
                    className={`w-full rounded-xl py-5 cursor-pointer border-[1px]  font-medium px-5 hover:border-[#F7D901] ${
                      selectedDetails?.label === obj?.label
                        ? "border-[#F7D901]"
                        : "border-gray-300"
                    }`}
                  >
                    {obj?.label}
                  </li>
                );
              })}
            </ul>
          </div>
          <div>
            <span className="font-medium ">Conversions</span>
            <ul className="flex flex-col gap-3 mt-3">
              {oldObjectives?.conversions?.map((obj) => {
                return (
                  <li
                    onClick={() => setSelectedDetails(obj)}
                    className={`w-full rounded-xl py-5 cursor-pointer border-[1px]  font-medium px-5 hover:border-[#F7D901] ${
                      selectedDetails?.label === obj?.label
                        ? "border-[#F7D901]"
                        : "border-gray-300"
                    }`}
                  >
                    {obj?.label}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <div className="flex items-center justify-end gap-3 w-full">
          <button
            onClick={() => setCreateCampaignModal(false)}
            className={`p-3 px-5 hover:border-[#F7D901] rounded-[20px] border font-semibold ${
              currentMode == "dark" ? "bg-black text-white" : "bg-white"
            } `}
          >
            Close
          </button>
          <button
            onClick={() => {
              setIsSingleCampaign(selectedDetails);
              setCreateCampaignModal(false);
              setCurrentCampaignDetails(null);
            }}
            className="p-3 px-7 rounded-[20px] bg-black text-white  border hover:border-[#F7D901]"
            disabled={!selectedDetails}
          >
            Next
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateCampaignModal;

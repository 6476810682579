import React, { useEffect, useState } from "react";
import Loader from "../../Components/Loader";
import { useStateContext } from "../../context/ContextProvider";
import HeadingTitle from "../../Components/_elements/HeadingTitle";
import AdsManager from "../../Components/snapchatMarketing/AdsManager";
import Header from "../../Components/snapchatMarketing/Header";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../../axoisConfig";
import { PiCopyLight } from "react-icons/pi";
import { toast } from "react-toastify";
import SingleAdAccount from "../../Components/snapchatMarketing/singleAdAccount";
const SnapchatAdAccounts = () => {
  const [loading, setloading] = useState(false);
  const [adAccountDetail, setAdAccountDetail] = useState(null);
  const {
    currentMode,
    setopenBackDrop,
    BACKEND_URL,
    pageState,
    t,
    themeBgImg,
    snapchatCredentials,
    DataGridStyles,
    primaryColor,
  } = useStateContext();
  const columns = [
    {
      field: "name",
      headerName: "Account Name",
      headerAlign: "left",
      minWidth: 200, // Adjust width as needed
      flex: 1,
      renderCell: ({ value }) => {
        return <div className="w-full text-left pl-[10px]">{value}</div>;
      },
    },
    {
      field: "id",
      headerName: "Ad Account ID",
      minWidth: 300,
      flex: 1,
      renderCell: ({ value }) => {
        return (
          <p className="w-full !text-left flex items-center gap-2">
            {value?.trim()}{" "}
            <button
              onClick={() => {
                navigator.clipboard.writeText(value);
                toast.success("Id is successfully copied!", {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }}
            >
              <PiCopyLight size={16} />
            </button>
          </p>
        );
      },
    },
    {
      field: "type",
      headerName: "Account Type",
      minWidth: 280,
      flex: 1,
      renderCell: ({ value }) => {
        return <div className="w-full text-left">{value}</div>;
      },
    },
    {
      field: "status",
      headerName: "Account Status",
      minWidth: 300,
      flex: 1,
      renderCell: ({ value }) => {
        return <div className="w-full text-left">{value}</div>;
      },
    },
  ];

  console.log(snapchatCredentials, "credentials");
  const getSummaryBgClass = () => {
    return currentMode === "dark"
      ? themeBgImg
        ? "blur-bg-dark text-white"
        : "bg-dark text-white"
      : themeBgImg
      ? "blur-bg-light text-gray-800"
      : "bg-white text-gray-800";
  };
  return (
    <>
      <div className={`flex min-h-screen ${getSummaryBgClass()}`}>
        {loading ? (
          <Loader />
        ) : (
          <div
            className={`w-full mt-2 ${
              !themeBgImg &&
              (currentMode === "dark" ? "bg-dark" : "bg-[#F9F9FA]")
            }`}
          >
            {/* <Header /> */}
            {!adAccountDetail ? (
              <div className="px-[80px] py-10">
                <h2
                  className={`${
                    currentMode === "dark"
                      ? "text-white"
                      : "text-[rgb(68,69,70)]"
                  } font-bold text-[24px] font-sans mb-3`}
                >
                  Ad Accounts
                </h2>
                <div>
                  {snapchatCredentials?.organizations?.length > 0 && (
                    <Box
                      className={`closed-datatable ${currentMode}-mode-datatable`}
                      sx={{
                        ...DataGridStyles,
                        position: "relative",
                        marginBottom: "50px",
                        width: "100%",
                      }}
                    >
                      <DataGrid
                        rows={
                          snapchatCredentials?.organizations[0]?.ad_accounts
                        }
                        onRowClick={(cellValues) =>
                          setAdAccountDetail(cellValues?.id)
                        }
                        columns={columns}
                        pageSize={5}
                        disableSelectionOnClick
                        autoHeight
                        sx={{
                          boxShadow: 2,
                          "& .MuiDataGrid-virtualScrollerContent .MuiSvgIcon-root":
                            {
                              color:
                                currentMode === "dark" ? "#ffffff" : "#000000",
                            },

                          "& .MuiButtonBase-root .MuiSwitch-switchBase": {
                            color: `${primaryColor} !important`,
                          },

                          "& .MuiSwitch-root .MuiSwitch-track": {
                            backgroundColor: `${primaryColor} !important`,
                          },
                        }}
                        getRowClassName={(params) =>
                          params.indexRelativeToCurrentPage % 2 === 0
                            ? "even"
                            : "odd"
                        }
                        columnWidths={{
                          checkbox: "30px",
                        }}
                      />
                    </Box>
                  )}
                </div>
              </div>
            ) : (
              <SingleAdAccount
                adAccountId={adAccountDetail}
                setAdAccountId={setAdAccountDetail}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SnapchatAdAccounts;

import React, { useState, useEffect } from "react";
import { AiOutlineSend } from "react-icons/ai";
import { useStateContext } from "../../context/ContextProvider";
import { Button } from "@material-tailwind/react";
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
const style = {
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};
const FlowPreview = ({
  nodes,
  edges,
  exception,
  chatBotPreviewModalModal,
  setChatBotPreviewModal,
}) => {
  const [nodeDetails, setNodeDetails] = useState({
    question: "",
    answer: "",
    childNodes: [],
  });
  const [chatHistory, setChatHistory] = useState([]);
  const [typingLoading, setTypingLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { isLangRTL, currentMode, i18n, darkModeColors, t, themeBgImg } =
    useStateContext();
  const { question, answer, childNodes } = nodeDetails;
  // console.log(nodeDetails,"node details")
  //  console.log(question,answer,"this is question and answer")

  function getNodeDetails(detailsNodeId) {
    const isNode = nodes?.find((node) => node?.id == detailsNodeId);
    // console.log(nodes,"nodes")
    // console.log(detailsNodeId,"node id")
    console.log(isNode, "this is the node ");

    const childEdges = edges.filter((edge) => edge.target == detailsNodeId);

    // Get all target node IDs from the filtered edges
    const childNodeIds = childEdges.map((edge) => edge.source);
    console.log(edges, "edges");
    console.log(childEdges, "node ids");

    // Return the corresponding node objects from the nodes array
    const childNodes = nodes
      .filter((node) => childNodeIds.includes(node.id))
      .map((nd) => ({
        question: nd?.data?.question,
        answer: nd?.data?.answer,
        id: nd?.id,
      }));
    console.log(childNodes, "details");
    setTypingLoading(true);
    setTimeout(() => {
      setNodeDetails({
        question: isNode?.data?.question,
        childNodes: childNodes,
      });
      setChatHistory((pre) => [
        ...pre,
        {
          answer:
            detailsNodeId == "-1" && chatHistory?.length > 0
              ? "Please Select one of them"
              : isNode?.data?.answer,
        },
      ]);
      setTypingLoading(false);
    }, 1000);
  }

  useEffect(() => {
    //    getNodeDetails(-1);
    //    setChatHistory(pre=>([...pre,{question:"greeting"}]))
  }, []);

  function handleBtnClick(id, question, answer) {
    setTypingLoading(true);
    setChatHistory((pre) => [...pre, { question }]);
    setTimeout(() => {
      // setChatHistory((pre) => [...pre, { answer }]);
      console.log(id);
      console.log(answer, question, "details");
      getNodeDetails(id);
      setTypingLoading(false);
    }, 1000);
  }

  function sendMessage() {
    setTypingLoading(true);
    setTimeout(() => {
      if (chatHistory?.length == 0) {
        getNodeDetails(-1);
        setChatHistory((pre) => [...pre, { question: message }]);
      } else {
        setChatHistory((pre) => [
          ...pre,
          { question: message },
          { answer: exception },
        ]);
        setNodeDetails({ childNodes: [] });
      }

      setTypingLoading(false);
    }, 1000);
    setMessage("");
  }

  function handleMessageChange(e) {
    setMessage(e?.target?.value);
  }

  return (
    <Modal
      keepMounted
      open={chatBotPreviewModalModal}
      onClose={() => setChatBotPreviewModal(false)}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div
        style={style}
        className={`w-[calc(100%-20px)] md:w-[22%]  ${
          currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
        } absolute top-1/2 left-1/2 rounded-[20px]`}
      >
        <Box
          sx={{
            ...darkModeColors,
            "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
              {
                right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                transformOrigin: isLangRTL(i18n.language) ? "right" : "left",
              },
            "& legend": {
              textAlign: isLangRTL(i18n.language) ? "right" : "left",
            },
          }}
          className={`flex flex-col gap-[30px] w-full bg-transparent relative`}
        >
          <IconButton
            sx={{
              position: "absolute",
              right: 12,
              top: 10,
              zIndex: 99,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={() => setChatBotPreviewModal(false)}
          >
            <IoMdClose size={18} />
          </IconButton>
          {/* <div className="flex flex-col gap-3 bg-transparent">
            <h3
              className={`text-[16px] w-full text-center p-2 ${
                currentMode === "dark" ? "text-white" : "text-black"
              }`}
            >
              ChatBot Preview
            </h3>
          </div> */}
          {/* w-[300px]  h-[400px] */}
          <div className=" h-[490px] w-full flex flex-col">
            <div className="chatbox__header bg-primary flex items-center justify-center">
              <div className="chatbox__image--header w-[50px] h-[50px] ">
                <img
                  src="assets/short-logo.jpg"
                  alt="Chat logo"
                  className=" rounded-full w-full h-full  "
                />
              </div>
              <div className="chatbox__content--header">
                <h4 className="chatbox__heading--header">Preview of Chatbot</h4>
              </div>
            </div>
            <div className="chatbox__messages ">
              <div>
                {chatHistory?.map((chat) => {
                  return (
                    <>
                      {chat?.question && (
                        <div className="messages__item messages__item--operator text-white bg-[#0084FF]">
                          {chat?.question}
                        </div>
                      )}

                      {chat?.answer && (
                        <div
                          className={`messages__item messages__item--visitor w-fit text-black ${
                            currentMode == "dark"
                              ? "bg-[#303030] text-white"
                              : "bg-[#F0F0F0]"
                          }  `}
                        >
                          {chat?.answer}
                        </div>
                      )}
                    </>
                  );
                })}

                {/* <div className="messages__item messages__item--operator bg-orange-600">
                    {question}
                  </div> */}

                {/* {answer && (
                  <div className="messages__item messages__item--visitor text-black  bg-[#F0F0F0]">
                    {answer}
                  </div>
                )} */}
                {typingLoading ? (
                  <div className="messages__item messages__item--typing">
                    <span className="messages__dot"></span>
                    <span className="messages__dot"></span>
                    <span className="messages__dot"></span>
                  </div>
                ) : null}
                <div className="flex overflow-auto gap-2 py-3">
                  {nodeDetails?.childNodes?.length > 0
                    ? nodeDetails?.childNodes?.map((node) => {
                        return (
                          <button
                            className={`whitespace-nowrap rounded-xl ${
                              currentMode == "dark"
                                ? "bg-[#242526] text-white"
                                : "bg-white"
                            }  shadow-lg px-3 py-1`}
                            onClick={() =>
                              handleBtnClick(
                                node?.id,
                                node?.question,
                                node?.answer
                              )
                            }
                          >
                            {node?.question}
                          </button>
                        );
                      })
                    : chatHistory?.length > 1 && (
                        <button
                          className={`whitespace-nowrap rounded-xl ${
                            currentMode == "dark"
                              ? "bg-[#242526] text-white"
                              : "bg-white"
                          } shadow-lg px-3 py-1`}
                          onClick={() => {
                            handleBtnClick(-1);
                            setChatHistory((pre) => [
                              ...pre,
                              { question: "select menu" },
                            ]);
                          }}
                        >
                          Select Menu
                        </button>
                      )}
                </div>
              </div>
            </div>
            <div
              className={`chatbox__footer ${
                currentMode == "dark" ? "!bg-[#242526]" : "!bg-[#F0F2F5]"
              }  !static`}
            >
              <input
                type="text"
                placeholder={t("bot_chat")}
                value={message}
                onChange={handleMessageChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    sendMessage();
                  }
                }}
                className={`w-full ${
                  currentMode == "dark" ? "!bg-[#3A3B3C] !text-white" : ""
                }`}
              />
              <p
                className={`chatbox__send--footer ml-2 text-primary cursor-pointer `}
                onClick={sendMessage}
              >
                <AiOutlineSend size={20} />

                {/* {t("send")} */}
              </p>
            </div>
          </div>
        </Box>
      </div>
    </Modal>
  );
};

export default FlowPreview;

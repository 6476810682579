import { Box, Dialog, IconButton, Tab, Tabs, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useStateContext } from "../../context/ContextProvider";
import { toast } from "react-toastify";
import axios from "../../axoisConfig";
import Select from "react-select";
import { task_status, task_type } from "../_elements/SelectOptions";
import { renderStyles, renderStyles2 } from "../_elements/SelectStyles";
import SingleTaskModal from "./SingleTaskModal";
import { IoMdClose } from "react-icons/io";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TaskCountdown } from "../Tasks/CountDown";
import { TaskCountdownTable } from "../Tasks/TaskCountdownTable";
import usePermission from "../../utils/usePermission";
import { datetimeLong, datetimeAMPM } from "../_elements/formatDateTime";
import { Link } from "react-router-dom";
import { BsFillArrowUpRightCircleFill } from "react-icons/bs";
import {socket} from "../../Pages/App"
const TaskDatagrid = ({ filtersData }) => {
  const {
    currentMode,
    pageState,
    setpageState,
    DataGridStyles,
    BACKEND_URL,
    primaryColor,
    t,
    darkModeColors,
    themeBgImg,
    isLangRTL,
    i18n,
    fontFam,
    User,
    feedbackTheme,
  } = useStateContext();

  const token = localStorage.getItem("auth-token");
  const { hasPermission } = usePermission();
  const [value, setValue] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [status, setStatus] = useState("New");
  const [openSingleTask, setOpenSingleTask] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [extraData, setExtraData] = useState({
    accomplished_on: null,
    note: null,
  });
  const [Dialogue, setDialogue] = useState({
    isOpen: false,
    id: null,
    status: null,
  });

  const statuses = {
    0: "New",
    1: "Ongoing",
    2: "Paused",
    3: "Completed",
    4: "Cancelled",
    5: "Daily",
  };

  const handleChange = (event, newValue) => {
    setStatus(statuses[newValue]);
    setValue(newValue);
  };

  const handleRowClick = (params, e) => {
    console.log("row click event:: ", e);
    console.log("row data: ", params);
    if (!e.target.closest(".status_select")) {
      setOpenSingleTask(params?.row);
    }
  };

  const columns = [
    // TASK TYPE
    {
      field: "task_type",
      headerName: t("task_type"),
      headerAlign: "center",
      editable: false,
      minWidth: 120,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <div className="w-full pt-2">
            <p className="text-center capitalize">
              {cellValues?.formattedValue}
            </p>
          </div>
        );
      },
    },
    // DEADLINE
    {
      field: "deadline",
      headerName: t("date"),
      headerAlign: "center",
      editable: false,
      minWidth: 230,
      flex: 1,
      renderCell: (cellValues) => {
        const calculateTimeLeft = (deadline) => {
          const difference = +new Date(deadline) - +new Date();
          let timeLeft = {};

          if (difference > 0) {
            timeLeft = {
              days: Math.floor(difference / (1000 * 60 * 60 * 24)),
              hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
              minutes: Math.floor((difference / 1000 / 60) % 60),
              seconds: Math.floor((difference / 1000) % 60),
            };
          }

          return timeLeft;
        };
        const isDeadlineGreater =
          new Date(cellValues?.formattedValue) > new Date();
        const timeLeft = calculateTimeLeft(cellValues?.formattedValue);
        return (
          <div className="w-full p-2">
            <p className="text-center capitalize">
              {cellValues?.row?.status === "Completed" ? (
                <>
                  <div className="mx-2 p-1">
                    {t("deadline")}: {datetimeLong(cellValues?.row?.deadline)}
                  </div>
                  <div className="mx-2 p-1 bg-green-500 text-white">
                    {t("task_status_completed")}:{" "}
                    {datetimeLong(cellValues?.row?.accomplished_on)}
                  </div>
                </>
              ) : isDeadlineGreater ? (
                <TaskCountdownTable deadline={cellValues?.formattedValue} />
              ) : (
                // <TaskCountdownTable deadline={cellValues?.formattedValue} />
                <>
                  <div>
                    {t("start_date")}: {cellValues?.row?.start_date}
                  </div>
                  <div className="bg-red-500 text-white p-2">
                    {t("deadline")}: {cellValues?.formattedValue}
                  </div>
                </>
              )}
            </p>
          </div>
        );
      },
    },
    // DEPARTMENT CATEGORY
    {
      field: "category",
      headerName: t("department"),
      headerAlign: "center",
      editable: false,
      minWidth: 100,
      flex: 1,

      renderCell: (cellValues) => {
        return (
          <div className="w-full ">
            <p className="text-center capitalize">
              {cellValues?.formattedValue} | {cellValues?.row?.department}
            </p>
          </div>
        );
      },
    },
    // TASK TITLE
    {
      field: "task_title",
      headerName: t("menu_tasks"),
      headerAlign: "center",
      editable: false,
      minWidth: 120,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <div className="w-full ">
            <p className="text-center capitalize">
              {cellValues?.formattedValue}
            </p>
          </div>
        );
      },
    },
    // ASSIGNED TO NAME
    {
      field: "assigned_to_name",
      headerName: t("assigned_to"),
      headerAlign: "center",
      editable: false,
      minWidth: 100,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <div className="w-full ">
            <p className="text-center capitalize">
              {cellValues?.formattedValue}
            </p>
          </div>
        );
      },
    },
    // STATUS
    {
      field: "status",
      headerName: t("status"),
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <Box
            className={`status_select w-full h-full flex items-center justify-center `}
          >
            {feedbackTheme === "renderStyles" ? (
              <Select
                aria-label={t("status")}
                id="title"
                options={task_status(t)}
                value={task_status(t)?.find(
                  (curr) =>
                    curr.value?.toLowerCase() ===
                    cellValues?.formattedValue?.toLowerCase()
                )}
                onChange={(e) => {
                  const value = e.value;
                  if (
                    value === "Completed" ||
                    value === "Paused" ||
                    value === "Cancelled"
                  ) {
                    setDialogue({
                      isOpen: true,
                      id: cellValues?.row?.t_id,
                      status: value,
                    });
                  } else {
                    UpdateStatus(e.value, cellValues?.row?.t_id);
                  }
                }}
                placeholder={t("status")}
                className={`my-1 px-4 w-full`}
                menuPortalTarget={document.body}
                styles={renderStyles(currentMode, primaryColor)}
              />
            ) : (
              <Select
                id="title"
                options={task_status(t)}
                value={task_status(t)?.find(
                  (curr) =>
                    curr.value?.toLowerCase() ===
                    cellValues?.formattedValue?.toLowerCase()
                )}
                onChange={(e) => {
                  const value = e.value;
                  if (
                    value === "Completed" ||
                    value === "Paused" ||
                    value === "Cancelled"
                  ) {
                    setDialogue({
                      isOpen: true,
                      id: cellValues?.row?.t_id,
                      status: value,
                    });
                  } else {
                    UpdateStatus(e.value, cellValues?.row?.t_id);
                  }
                }}
                placeholder={t("status")}
                className={`my-1 px-4 w-full`}
                menuPortalTarget={document.body}
                styles={renderStyles2(currentMode, primaryColor)}
              />
            )}
          </Box>
        );
      },
    },
    // PRIORITY
    {
      field: "priority",
      headerName: t("label_priority"),
      headerAlign: "center",
      editable: false,
      minWidth: 80,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <div className="w-full ">
            <p className="text-center capitalize">
              {cellValues?.formattedValue}
            </p>
          </div>
        );
      },
    },
    // ASSIGNED BY
    {
      field: "assigned_by_name",
      headerName: t("assigned_by"),
      headerAlign: "center",
      editable: false,
      minWidth: 100,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <div className="w-full ">
            <p className="text-center capitalize">
              {cellValues?.formattedValue}
            </p>
          </div>
        );
      },
    },
  ];

  const UpdateStatus = async (e, id) => {
    setDialogue({ ...Dialogue, isOpen: false });
    setBtnLoading(true);
    const value = e;
    console.log("value: ", value);

    if (value === "Completed" && extraData?.accomplished_on == null) {
      setBtnLoading(false);
      toast.error(`Date is required to update the status to  ${value}.`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return;
    }

    let data = {};

    if (value === "Completed") {
      data = {
        status: value,
        accomplished_on: extraData?.accomplished_on,
      };
    } else if (value === "Cancelled" || value === "Paused") {
      data = {
        status: value,
        note: extraData?.note,
      };
    } else {
      data = {
        status: value,
      };
    }

    try {
      const response = await axios.post(`${BACKEND_URL}/tasks/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      console.log("Tasks status updated: ", response);
      toast.success(`Task Status updated to ${value}.`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setExtraData({
        accomplished_on: null,
        note: null,
      });
      socket.emit("new_task_added");

      setBtnLoading(false);

      fetchTasks();
    } catch (error) {
      setBtnLoading(false);

      console.error(error);
      toast.error("Unable to update task status.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const fetchTasks = async () => {
    setpageState((old) => ({
      ...old,
      isLoading: true,
    }));
    try {
      // Filter out empty values and construct query parameters
      const activeFilters = Object.entries(filtersData).reduce(
        (acc, [key, value]) => {
          if (value !== "" && value !== null) acc[key] = value;
          return acc;
        },
        {}
      );
      const queryParams =
        Object.keys(activeFilters).length > 0
          ? `&${new URLSearchParams(activeFilters).toString()}`
          : "";

      console.log("active filters::: ", activeFilters);
      console.log("queryParams::: ", queryParams);

      let url;
      if (hasPermission("allTasks")) {
        url = `${BACKEND_URL}/tasks?page=${pageState.page}&status=${status}${queryParams}`;
      } else if (hasPermission("teamTasks")) {
        url = `${BACKEND_URL}/tasks?page=${pageState.page}&status=${status}${queryParams}`;
      } else {
        url = `${BACKEND_URL}/tasks?page=${pageState.page}&status=${status}${queryParams}&assigned_to=${User?.id}`;
      }

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      console.log("Tasks: ", response);

      let rowsDataArray = "";
      if (response?.data?.data?.current_page > 1) {
        const theme_values = Object.values(response?.data?.data?.data);
        rowsDataArray = theme_values;
      } else {
        rowsDataArray = response?.data?.data?.data;
      }

      let rowsdata = rowsDataArray?.map((row, index) => ({
        id: index + 1,
        t_id: row?.id,
        task_type: row?.task_type || "-",
        deadline: row?.deadline || "-",
        priority: row?.priority || "-",
        category: row?.category || "-",
        task_title: row?.task_title || "-",
        assigned_to: row?.assigned_to || "-",
        assigned_to_name: row?.assigned_to_name || "-",
        created_at: row?.created_at,
        updated_at: row?.updated_at,

        description: row?.description || "-",
        status: row?.status || null,
        start_date: row?.start_date || "-",
        end_date: row?.end_date || "-",
        accomplished_on: row?.accomplished_on || "-",

        assigned_by: row?.assigned_by || "-",
        assigned_by_name: row?.assigned_by_name || "-",
        department: row?.department || "-",
        country: row?.country || "-",
        agency: row?.agency || "-",
        edit: "edit",
      }));

      console.log("Rows Data: ", rowsdata);

      setpageState((old) => ({
        ...old,
        isLoading: false,
        data: rowsdata,
        pageSize: response?.data?.data?.per_page,
        total: response?.data?.data?.total,
      }));
    } catch (error) {
      console.log(error);
      toast.error("Unable to fetch tasks.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  console.log("page state data::: ", pageState?.data);

  useEffect(() => {
    fetchTasks();
  }, [status, filtersData]);

  useEffect(()=>{
    socket.on("new_task_notification",(id)=>{
      if(id == User?.id || User?.role == 1){
        fetchTasks();
      }
    })
  },[])

  return (
    <>
      <Box
        sx={{
          ...darkModeColors,
          "& .MuiTabs-indicator": {
            height: "100%",
            borderRadius: "5px",
          },
          "& .Mui-selected": {
            color: "white !important",
            zIndex: "1",
          },
        }}
        className={`w-full rounded-lg overflow-hidden ${
          !themeBgImg
            ? currentMode === "dark"
              ? "bg-[#1c1c1c]"
              : "bg-[#EEEEEE]"
            : currentMode === "dark"
            ? "blur-bg-dark"
            : "blur-bg-light"
        } `}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="variant"
          className="w-full m-1"
        >
          <Tab label={t("feedback_new")?.toUpperCase()} />
          <Tab label={t("task_status_ongoing")?.toUpperCase()} />
          <Tab label={t("task_status_paused")?.toUpperCase()} />
          <Tab label={t("task_status_completed")?.toUpperCase()} />
          <Tab label={t("feedback_cancelled")?.toUpperCase()} />
          <Tab label={t("tast_type_daily")?.toUpperCase()} />
          <Link
            className="bg-primary w-[250px] text-white rounded-lg pl-2 py-3 font-semibold  flex items-center justify-center space-x-2"
            style={{ color: "#ffffff" }}
            to="/task-counter"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{t("task_counter")}</span>
            <BsFillArrowUpRightCircleFill />
          </Link>
        </Tabs>
      </Box>

      <div className="mt-3 pb-3">
        <TabPanel value={value} index={0}>
          <Box
            className={`${currentMode}-mode-datatable`}
            // width={"100%"}
            sx={{ ...DataGridStyles, marginBottom: "5%" }}
          >
            <DataGrid
              disableDensitySelector
              autoHeight
              disableSelectionOnClick
              onRowClick={handleRowClick}
              rows={pageState.data}
              columns={columns}
              rowCount={pageState.total}
              loading={pageState.isLoading}
              rowsPerPageOptions={[30, 50, 75, 100]}
              pagination
              getRowHeight={() => "auto"}
              paginationMode="server"
              page={pageState.page - 1}
              pageSize={pageState.pageSize}
              //   componentsProps={{
              //     toolbar: {
              //       printOptions: {
              //         disableToolbarButton: User?.role !== 1,
              //       },
              //       csvOptions: {
              //         disableToolbarButton: User?.role !== 1,
              //       },
              //       showQuickFilter: true,
              //     },
              //   }}
              onPageChange={(newPage) => {
                setpageState((old) => ({
                  ...old,
                  page: newPage + 1,
                }));
              }}
              onPageSizeChange={(newPageSize) =>
                setpageState((old) => ({
                  ...old,
                  pageSize: newPageSize,
                }))
              }
              sx={{
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  cursor: "pointer",
                },
                "& .MuiDataGrid-cell[data-field='edit'] svg": {
                  color:
                    currentMode === "dark"
                      ? "white !important"
                      : "black !important",
                },
              }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box
            className={`${currentMode}-mode-datatable`}
            // width={"100%"}
            sx={{ ...DataGridStyles, marginBottom: "5%" }}
          >
            <DataGrid
              disableDensitySelector
              autoHeight
              disableSelectionOnClick
              rows={pageState.data}
              columns={columns}
              rowCount={pageState.total}
              loading={pageState.isLoading}
              rowsPerPageOptions={[30, 50, 75, 100]}
              pagination
              onRowClick={handleRowClick}
              getRowHeight={() => "auto"}
              paginationMode="server"
              page={pageState.page - 1}
              pageSize={pageState.pageSize}
              //   componentsProps={{
              //     toolbar: {
              //       printOptions: {
              //         disableToolbarButton: User?.role !== 1,
              //       },
              //       csvOptions: {
              //         disableToolbarButton: User?.role !== 1,
              //       },
              //       showQuickFilter: true,
              //     },
              //   }}
              onPageChange={(newPage) => {
                setpageState((old) => ({
                  ...old,
                  page: newPage + 1,
                }));
              }}
              onPageSizeChange={(newPageSize) =>
                setpageState((old) => ({
                  ...old,
                  pageSize: newPageSize,
                }))
              }
              sx={{
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  cursor: "pointer",
                },
                "& .MuiDataGrid-cell[data-field='edit'] svg": {
                  color:
                    currentMode === "dark"
                      ? "white !important"
                      : "black !important",
                },
              }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
            />
          </Box>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <Box
            className={`${currentMode}-mode-datatable`}
            // width={"100%"}
            sx={{ ...DataGridStyles, marginBottom: "5%" }}
          >
            <DataGrid
              disableDensitySelector
              autoHeight
              disableSelectionOnClick
              onRowClick={handleRowClick}
              rows={pageState.data || []}
              columns={columns}
              rowCount={pageState.total}
              loading={pageState.isLoading}
              rowsPerPageOptions={[30, 50, 75, 100]}
              pagination
              getRowHeight={() => "auto"}
              paginationMode="server"
              page={pageState.page - 1}
              pageSize={pageState.pageSize}
              //   componentsProps={{
              //     toolbar: {
              //       printOptions: {
              //         disableToolbarButton: User?.role !== 1,
              //       },
              //       csvOptions: {
              //         disableToolbarButton: User?.role !== 1,
              //       },
              //       showQuickFilter: true,
              //     },
              //   }}
              onPageChange={(newPage) => {
                setpageState((old) => ({
                  ...old,
                  page: newPage + 1,
                }));
              }}
              onPageSizeChange={(newPageSize) =>
                setpageState((old) => ({
                  ...old,
                  pageSize: newPageSize,
                }))
              }
              sx={{
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  cursor: "pointer",
                },
                "& .MuiDataGrid-cell[data-field='edit'] svg": {
                  color:
                    currentMode === "dark"
                      ? "white !important"
                      : "black !important",
                },
              }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box
            className={`${currentMode}-mode-datatable`}
            // width={"100%"}
            sx={{ ...DataGridStyles, marginBottom: "5%" }}
          >
            <DataGrid
              disableDensitySelector
              autoHeight
              disableSelectionOnClick
              onRowClick={handleRowClick}
              rows={pageState.data || []}
              columns={columns}
              rowCount={pageState.total}
              loading={pageState.isLoading}
              rowsPerPageOptions={[30, 50, 75, 100]}
              pagination
              getRowHeight={() => "auto"}
              paginationMode="server"
              page={pageState.page - 1}
              pageSize={pageState.pageSize}
              //   componentsProps={{
              //     toolbar: {
              //       printOptions: {
              //         disableToolbarButton: User?.role !== 1,
              //       },
              //       csvOptions: {
              //         disableToolbarButton: User?.role !== 1,
              //       },
              //       showQuickFilter: true,
              //     },
              //   }}
              onPageChange={(newPage) => {
                setpageState((old) => ({
                  ...old,
                  page: newPage + 1,
                }));
              }}
              onPageSizeChange={(newPageSize) =>
                setpageState((old) => ({
                  ...old,
                  pageSize: newPageSize,
                }))
              }
              sx={{
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  cursor: "pointer",
                },
                "& .MuiDataGrid-cell[data-field='edit'] svg": {
                  color:
                    currentMode === "dark"
                      ? "white !important"
                      : "black !important",
                },
              }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Box
            className={`${currentMode}-mode-datatable`}
            // width={"100%"}
            sx={{ ...DataGridStyles, marginBottom: "5%" }}
          >
            <DataGrid
              disableDensitySelector
              autoHeight
              //   disableSelectionOnClick
              rows={pageState.data || []}
              columns={columns}
              rowCount={pageState.total}
              loading={pageState.isLoading}
              rowsPerPageOptions={[30, 50, 75, 100]}
              pagination
              getRowHeight={() => "auto"}
              paginationMode="server"
              page={pageState.page - 1}
              pageSize={pageState.pageSize}
              onRowClick={handleRowClick}
              onPageChange={(newPage) => {
                setpageState((old) => ({
                  ...old,
                  page: newPage + 1,
                }));
              }}
              onPageSizeChange={(newPageSize) =>
                setpageState((old) => ({
                  ...old,
                  pageSize: newPageSize,
                }))
              }
              sx={{
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  cursor: "pointer",
                },
                "& .MuiDataGrid-cell[data-field='edit'] svg": {
                  color:
                    currentMode === "dark"
                      ? "white !important"
                      : "black !important",
                },
              }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Box
            className={`${currentMode}-mode-datatable`}
            // width={"100%"}
            sx={{ ...DataGridStyles, marginBottom: "5%" }}
          >
            <DataGrid
              disableDensitySelector
              autoHeight
              //   disableSelectionOnClick
              rows={pageState.data || []}
              columns={columns}
              rowCount={pageState.total}
              loading={pageState.isLoading}
              rowsPerPageOptions={[30, 50, 75, 100]}
              pagination
              getRowHeight={() => "auto"}
              paginationMode="server"
              page={pageState.page - 1}
              pageSize={pageState.pageSize}
              onRowClick={handleRowClick}
              onPageChange={(newPage) => {
                setpageState((old) => ({
                  ...old,
                  page: newPage + 1,
                }));
              }}
              onPageSizeChange={(newPageSize) =>
                setpageState((old) => ({
                  ...old,
                  pageSize: newPageSize,
                }))
              }
              sx={{
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  cursor: "pointer",
                },
                "& .MuiDataGrid-cell[data-field='edit'] svg": {
                  color:
                    currentMode === "dark"
                      ? "white !important"
                      : "black !important",
                },
              }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
            />
          </Box>
        </TabPanel>
        {Dialogue?.isOpen && (
          <>
            <Dialog
              sx={{
                "& .MuiPaper-root": {
                  boxShadow: "none !important",
                },
                "& .MuiBackdrop-root, & .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop":
                  {
                    // backgroundColor: "rgba(0, 0, 0, 0.6) !important",
                  },
              }}
              open={Dialogue?.isOpen}
              onClose={(e) => setDialogue({ ...Dialogue, isOpen: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <IconButton
                sx={{
                  position: "absolute",
                  right: 12,
                  top: 10,
                  color: (theme) => theme.palette.grey[500],
                }}
                onClick={() => setDialogue({ ...Dialogue, isOpen: false })}
              >
                <IoMdClose size={18} />
              </IconButton>
              <div
                className={`px-10 py-5 ${
                  currentMode === "dark"
                    ? "bg-[#1C1C1C] text-white"
                    : "bg-white text-black"
                }`}
              >
                <div className="flex flex-col justify-center items-center">
                  <h1 className="font-semibold pt-3 text-lg text-center">
                    {Dialogue?.status === "Completed"
                      ? t("add_date")
                      : t("ticket_add_note_label")}
                  </h1>
                </div>
                <div className="action buttons mt-5 flex items-center justify-center space-x-2">
                  <Box
                    sx={{
                      ...darkModeColors,
                      "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                        {
                          right: isLangRTL(i18n.language)
                            ? "2.5rem"
                            : "inherit",
                          transformOrigin: isLangRTL(i18n.language)
                            ? "right"
                            : "left",
                        },
                      "& legend": {
                        textAlign: isLangRTL(i18n.language) ? "right" : "left",
                      },
                      "& .css-10drtbx-MuiButtonBase-root-MuiCheckbox-root": {
                        color: currentMode === "dark" ? "#EEEEEE" : "#2B2830",
                      },
                    }}
                    className={`${
                      currentMode === "dark" ? "bg-dark-neu" : "bg-light-neu"
                    }
                  p-5`}
                  >
                    <div className="flex items-center space-x-2 ">
                      {Dialogue?.status !== "Completed" ? (
                        <TextField
                          id="note"
                          type={"text"}
                          label={t("notes")}
                          className="w-full col-span-2 mb-5"
                          sx={{
                            "&": {
                              //   marginBottom: "1.25rem !important",
                              zIndex: 1,
                            },
                          }}
                          variant="outlined"
                          size="small"
                          value={extraData?.note}
                          onChange={(e) =>
                            setExtraData({ ...extraData, note: e.target.value })
                          }
                          required
                        />
                      ) : (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            value={extraData?.accomplished_on}
                            label={t("add_date")}
                            onChange={(newValue) => {
                              const formattedDateTime = moment(
                                newValue?.$d
                              ).format("YYYY-MM-DD HH:mm:ss");

                              setExtraData((prev) => ({
                                ...prev,
                                accomplished_on: formattedDateTime,
                              }));
                            }}
                            // format="DD-MM-YYYY HH:mm:ss"
                            renderInput={(params) => (
                              <TextField
                                sx={{
                                  "& input": {
                                    color:
                                      currentMode === "dark"
                                        ? "white"
                                        : "black",
                                  },
                                  "& .MuiSvgIcon-root": {
                                    color:
                                      currentMode === "dark"
                                        ? "white"
                                        : "black",
                                  },
                                  marginBottom: "15px",
                                }}
                                fullWidth
                                size="small"
                                {...params}
                                onKeyDown={(e) => e.preventDefault()}
                                readOnly={true}
                              />
                            )}
                            minDate={dayjs().startOf("day").toDate()}
                          />
                        </LocalizationProvider>
                      )}
                      <button
                        style={{
                          color: "white",
                          fontFamily: fontFam,
                        }}
                        className={`${
                          currentMode === "dark"
                            ? "bg-primary-dark-neu"
                            : "bg-primary-light-neu"
                        } w-max text-white p-3 font-semibold  `}
                        onClick={() => {
                          UpdateStatus(Dialogue?.status, Dialogue?.id);
                        }}
                        // disabled={btnLoading ? true : false}
                      >
                        <span>
                          {Dialogue?.status === "Completed"
                            ? t("add_date")
                            : t("ticket_add_note_label")}
                        </span>
                      </button>
                    </div>
                  </Box>
                </div>
              </div>
            </Dialog>
          </>
        )}
      </div>
      {openSingleTask && (
        <SingleTaskModal
          openSingleTask={openSingleTask}
          setOpenSingleTask={setOpenSingleTask}
          fetchTasks={fetchTasks}
        />
      )}
    </>
  );

  function TabPanel(props) {
    const { children, value, index } = props;
    return <div>{value === index && <div>{children}</div>}</div>;
  }
};

export default TaskDatagrid;

import React, { useEffect, useState } from "react";
import { useStateContext } from "../../context/ContextProvider";
import { BsArrowsFullscreen } from "react-icons/bs";
import { IconButton, Pagination, Stack } from "@mui/material";
import Loader from "../Loader";
import moment from "moment";
import axios from "../../axoisConfig";
import { toast } from "react-toastify";
import { TaskCountdown } from "./CountDown";
import { socket } from "../../Pages/App";
const TaskCounter = () => {
  const {
    pageState,
    setpageState,
    DataGridStyles,
    BACKEND_URL,
    primaryColor,
    t,
    darkModeColors,
    themeBgImg,
  } = useStateContext();
  const currentMode = "dark";
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const [maxPage, setMaxPage] = useState(0);
  const [page, setPage] = useState(1);
  const [tasks, setTasks] = useState([]);
  const token = localStorage.getItem("auth-token");

  const fetchTasks = async () => {
    setLoading(true);
    try {
      let url;
      url = `${BACKEND_URL}/tasks?page=${page}`;

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      console.log("Tasks: ", response);

      const tasksList = response.data?.data?.data;

      if (tasksList) {
        setNoData(false);
      } else {
        setNoData(true);
        setLoading(false);
        return;
      }

      const sortedTasks = tasksList?.sort((taskA, taskB) => {
        if (taskA.status === "completed" && taskB.status !== "completed") {
          return 1; // taskA should come after taskB
        } else if (
          taskA.status !== "completed" &&
          taskB.status === "completed"
        ) {
          return -1; // taskA should come before taskB
        }
        return 0; // No change if both have the same status
      });

      setTasks(sortedTasks);
      setMaxPage(response.data?.data?.last_page);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error("Unable to fetch tasks.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    fetchTasks();
    socket.on("new_task_notification", () => {
      fetchTasks();
      // alert("it is called")
      console.log("it is called");
    });
  }, [page]);

  //   full screen function
  function requestFullScreen(element) {
    var requestMethod =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      element.msRequestFullScreen;
    if (requestMethod) {
      // Native full screen.
      requestMethod.call(element);
    } else if (typeof window.ActiveXObject !== "undefined") {
      // Older IE.
      var wscript = new window.ActiveXObject("WScript.Shell");
      if (wscript !== null) {
        wscript.SendKeys("{F11}");
      }
    }
  }

  const formatName = (name) => {
    console.log("name: ", name);
    if (!name) return;
    return name
      ?.split(",")
      ?.map((n) => {
        const parts = n.trim().split(" ");
        if (parts[0].length >= 4) {
          return parts[0];
        }
        return parts.length > 1 ? `${parts[0]} ${parts[1]}` : parts[0];
      })
      .join(", ");
  };
  //

  return (
    <div
      className="p-5"
      style={{
        width: "100vw",
        minHeight: "100vh",
        // background: "#05060f linear-gradient(0deg, rgba(216, 236, 248, 0.06), rgba(152, 192, 239, 0.06))",
        background: "#EEE",
        color: "#000000",
      }}
    >
      <div className="flex items-center justify-between gap-4 px-5 pb-3 rounded-md">
        <h1 className={`text-black font-bold text-xl`}>TASKS COUNTDOWN</h1>
        <IconButton onClick={() => requestFullScreen(document.body)}>
          <BsArrowsFullscreen size={16} color={"#777777"} />
        </IconButton>
      </div>

      {/* tasks */}
      <div className="carousel">
        <div className="w-full h-full my-4">
          <>
            {loading ? (
              <Loader />
            ) : (
              <>
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5 ">
                  {noData === false &&
                    tasks?.length > 0 &&
                    tasks?.map((task, index) => {
                      return (
                        <div
                          className={`rounded-md flex justify-between items-between h-full`}
                          style={{
                            background: "white",
                            flexDirection: "column",
                            boxShadow: "0 0 10px rgba(38, 38, 38, 0.3)",
                            border: "1px solid rgba(38, 38, 38, 0.3)",
                          }}
                        >
                          <div className="text-center text-sm flex items-center justify-between gap-2 capitalize">
                            <p className="p-2">{task?.category}</p>
                            <p
                              className={`p-2 ${
                                task?.status === "completed"
                                  ? "text-green-600"
                                  : moment(task?.deadline).isSameOrBefore(
                                      moment()
                                    )
                                  ? "text-red-600"
                                  : "text-black"
                              }`}
                            >
                              {formatName(task?.assigned_to_name)}
                            </p>
                          </div>

                          <div className={`w-full p-2`}>
                            <h6 className="text-center capitalize font-semibold">
                              {task?.task_title}
                            </h6>
                          </div>
                          <hr className="my-2" />
                          <div
                            className={`text-sm pb-2 px-2 ${
                              task?.status === "completed" ||
                              moment(task?.deadline).isSameOrBefore(moment())
                                ? "flex items-center justify-between"
                                : null
                            } bottom-0`}
                          >
                            {task?.status?.toLowerCase() === "completed" ? (
                              <>
                                <div className="text-center">
                                  <h1>Deadline&nbsp;</h1>
                                  <h4 className="font-normal">
                                    {moment(task?.deadline).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </h4>
                                </div>
                                <div className="text-center">
                                  <h1>Accomplished&nbsp;</h1>
                                  <h4 className="text-green-600">
                                    {moment(task?.accomplished_on).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </h4>
                                </div>
                              </>
                            ) : moment(task?.deadline).isSameOrBefore(
                                moment()
                              ) ? (
                              <div className="text-center">
                                <h1>Deadline&nbsp;</h1>
                                <h4 className="font-normal text-red-600">
                                  {moment(task?.deadline).format("YYYY-MM-DD")}
                                </h4>
                              </div>
                            ) : (
                              <TaskCountdown deadline={task?.deadline} />
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
                {noData === false ? (
                  <Stack spacing={2} marginTop={2}>
                    <Pagination
                      count={maxPage}
                      onChange={(e, value) => setPage(value)}
                      style={{ margin: "auto" }}
                      page={page}
                      sx={{
                        "& .Mui-selected": {
                          // color: "white !important",
                          backgroundColor: `#fafafa !important`,
                          border: "1px solid rgba(234,234,234,0.3)",
                          boxShadow: "0 0 10px rgba(234,234,234,0.3)",
                          "&:hover": {
                            backgroundColor: "#777777",
                          },
                        },
                        "& .MuiPaginationItem-root": {
                          // color: currentMode === "dark" ? "white" : "black",
                          color: "black",
                        },
                      }}
                    />
                  </Stack>
                ) : null}
                {noData === true && (
                  <div className="flex flex-col items-center justify-center h-[80vh] ">
                    <img
                      src="./no_data.png"
                      alt="No data Illustration"
                      className="w-[500px] h-[500px] object-cover"
                    />
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default TaskCounter;
